import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/Account.css";
import "./../../styles/main.css";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getAccountsData,
  getLicenseData,
} from "../../redux/Account/AccountAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
import { TroubleshootTwoTone } from "@mui/icons-material";
import moment from "moment";

const Account = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [licenseDetailsData, setLicenseDetailsData] = useState([]);
  const [allLiscenseData, setAllLiscenseData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    setLoading(true);
    userData?.school_id !== undefined &&
      dispatch(getAccountsData(userData?.school_id)).then(async (res) => {
        if (res?.statusCode === 200) {
          setSchoolData(res?.schoolData);
          setData(res?.schoolAdministrators);
          setAllData(res);
          setLoading(false);
          // if (res.ext_school_id !== null) {
          //   setIsDisabled(true);
          // }
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
            navigate("/login", { replace: true });
            dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
          });
        }
      });

    userData?.school_id !== undefined &&
      dispatch(getLicenseData(userData?.school_id)).then(async (res) => {
        if (res?.statusCode === 200) {
          setLicenseData(res);
          setLicenseDetailsData(res?.orderList);
          if (res.ext_school_id !== null) {
            setIsDisabled(true);
          }
          //   setAllLiscenseData(res);
          setLoading(false);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
            navigate("/login", { replace: true });
            dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
          });
        }
      });
  }, [userData]);

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading lineAny">{schoolData?.name}</div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        Account
      </div>

      <div className="accountMain">
        <div className="primaryContent">
          <div className="accountTitle">School details</div>
          <div className="table table-borderless">
            <div>
              <div className="cmpGameMain">
                <div className="leftTitle">Name</div>
                <div className="rightData lineAny">{schoolData?.name}</div>
              </div>
              <div className="cmpGameMain">
                <div className="leftTitle">School code</div>
                <div className="rightData">{schoolData?.code}</div>
              </div>
              <div className="cmpGameMain">
                <div className="leftTitle">School Administrators</div>
                <div className="ulList rightData">
                  <ul>
                    {data?.map((row, index) => {
                      return (
                        <>
                          <li>
                            {row.first_name} {row.last_name}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="accountTitle">Address</div>
          <div className="table table-borderless">
            <div>
              <div>
                <span className="leftTitle">Street</span>
                <span>
                  {schoolData?.address} {schoolData?.address2}
                </span>
              </div>
              <div>
                <span className="leftTitle">City</span>
                <span>{schoolData?.city}</span>
              </div>
              <div>
                <span className="leftTitle">State</span>
                <span>{schoolData?.state}</span>
              </div>
              <div>
                <span className="leftTitle">Zip</span>
                <span>{schoolData?.zip}</span>
              </div>
              <div>
                <span className="leftTitle">Phone</span>
                <span>{schoolData?.phone}</span>
              </div>
            </div>
          </div>

          <Link to="/dashboard/schools/edit">
            <Button
              className={isDisabled ? "hide" : "btn gradientButtonBlue mr-2"}
            >
              Update Account
            </Button>
          </Link>
          <div className={isDisabled ? "hide" : "accountTitle mt-2"}>
            Licenses
          </div>
          <div className={isDisabled ? "hide" : "table table-borderless"}>
            <div>
              <div>
                <span className="leftTitle">Total Licenses</span>
                <span>{licenseData?.totalLicenses}</span>
              </div>
              <div>
                <span className="leftTitle">Total cost of Licenses</span>
                <span>${licenseData?.totalValue}</span>
              </div>
              <div>
                <span className="leftTitle">Delivery Date</span>
                <span>
                  {moment(licenseData?.deliveryDate).format("MM/DD/YYYY ")}
                </span>
              </div>
              <div>
                <span className="leftTitle">Expiration Date</span>
                <span>
                  {moment(licenseData?.expirationDate).format("MM/DD/YYYY ")}
                </span>
              </div>
            </div>
          </div>

          <Link to="/dashboard/schools/orders">
            <Button
              className={isDisabled ? "hide" : "btn gradientButtonBlue"}
              // onClick={handleCancel}
            >
              Purchase Licenses
            </Button>
          </Link>
        </div>
      </div>

      <CustomToastify />
    </Card>
  );
};

export default Account;
