import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "./components/Dashboard";
import Login from "./Layouts/Login/login";
import Teachers from "./components/MyTeachers";
import Home from "./components/Home";
import AddEditTeacher from "./components/MyTeachers/AddEditTeacher";
import MyClasses from "./components/MyClasses";
import StudentReport from "./components/MyStudentsAndReports/StudentReport";
import EditStudent from "./components/MyStudentsAndReports/EditStudent";
import EnrollStudent from "./components/MyStudentsAndReports/EnrollStudent";
import AddEditClass from "./components/MyClasses/AddEditClass";
import StudentList from "./components/MyClasses/StudentList";
import Students from "./components/MyStudentsAndReports/Students";
import PrintStudents from "./components/MyStudentsAndReports/PrintStudents";
import ImportStudents from "./components/MyStudentsAndReports/ImportStudents";
import ForgottenPassword from "./Layouts/Login/ForgottenPassword";
import ResetPassword from "./Layouts/Login/ResetPassword";
import LessonGroups from "./components/MyLessonsAndResources/LessonGroups";
import AssignStudents from "./components/MyLessonsAndResources/AssignStu";
import Resources from "./components/MyLessonsAndResources/Resources";
import Account from "./components/Account/Account";
import PurchaseLicenses from "./components/Account/PurchaseLicenses";
import UpdateAccount from "./components/Account/UpdateAccount";
import MyLessonsResources from "./components/MyLessonsAndResources";
import Adventure from "./components/FreeTimeActivities/Adventure";
import Games from "./components/FreeTimeActivities/games";
import Preview from "./components/MyLessonsAndResources/Preview";
import StudentLessons from "./components/Lessons/StudentLessons";
import HelpHub from "./components/HelpHub";
import ReportProgress from "./components/MyStudentsAndReports/ReportProgress";
import ViewReportProgress from "./components/MyStudentsAndReports/ViewReportProgress";
import ChangePassword from "./Layouts/Login/ChangePassword";
import AssessYourClass from "./components/MyClasses/AssessYourClass";

import PrePostReport from "./components/MyClasses/PrePostReport";
import LearnosityAssessment from "./components/Learnosity/LearnosityAssessment";
import Assessment from "./components/Learnosity/Assessment";
import PrePostClassReport from "./components/MyClasses/PrePostClassReport";

export default function Router(props) {
  const userData = useSelector((state) => state.loginReducer?.userData);

  const isLoggedIn = () => {
    const data = localStorage.getItem("AuthToken");
    // const data = 1;
    if (data != null) return true;
    else return false;
  };
  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn ? (
            userData.userType == "student" ? <Navigate to="/dashboard/lessons" />: <Navigate to="/dashboard/home" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login/app_code/:id" element={<Login />} />
      <Route path="/forgottenpassword" element={<ForgottenPassword />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/lessons/:id/scenes" element={<Preview />} />
      <Route exact path="/lessons/:id/index2.html" component={() => null} />
      <Route path="dashboard" element={<DashboardLayout />}>
        <Route path="home" element={<Home />} />
        <Route path="teachers" element={<Teachers />} />
        <Route path="schools/:id/teachers/new" element={<AddEditTeacher />} />
        <Route
          path="schools/:id/teachers/:id/edit"
          element={<AddEditTeacher />}
        />
        <Route path="classes" element={<MyClasses />} />
        <Route path="schools/:id/students/:id" element={<StudentReport />} />

        <Route path="schools/:id/students/:id/edit" element={<EditStudent />} />
        <Route
          path="classrooms/:id/students_enrollments/new"
          element={<EnrollStudent />}
        />
        <Route path="schools/:id/students" element={<EnrollStudent />} />
        <Route
          path="schools/:id/classrooms/:id/edit"
          element={<AddEditClass />}
        />
        <Route path="schools/:id/classrooms/new" element={<AddEditClass />} />
        <Route
          path="schools/:id/classrooms/:id/printable/c"
          element={<PrintStudents />}
        />
        <Route
          path="schools/:id/classrooms/:id/printable/s"
          element={<PrintStudents />}
        />
        <Route path="schools/:id/classrooms/:id" element={<StudentList />} />
        <Route path="myStudentsReports" element={<Students />} />
        <Route
          path="schools/:id/student_import/new"
          element={<ImportStudents />}
        />
        <Route
          path="courses/:id/lesson_groups/:id"
          element={<LessonGroups />}
        />
        <Route
          path="lesson_groups/:id/bulk_lesson_group_assignments/new"
          element={<AssignStudents />}
        />
        <Route
          path="courses/:id/lesson_groups/:id/episode/:id"
          element={<Resources />}
        />
        <Route path="schools" element={<Account />} />
        <Route path="schools/orders" element={<PurchaseLicenses />} />
        <Route path="schools/edit" element={<UpdateAccount />} />
        <Route path="adventures" element={<Adventure />} />
        <Route path="lessons" element={<StudentLessons />} />

        <Route path="helpHub" element={<HelpHub />} />
        <Route path="students/:id/lessonreports" element={<ReportProgress />} />
        <Route
          path="students/:id/lessonreports/:id"
          element={<ViewReportProgress />}
        />
        <Route path="users/password_update/new" element={<ChangePassword />} />

        <Route path="progress" element={<StudentReport />} />

        <Route
          path="schools/:id/classrooms/:id/assessyourclass"
          element={<AssessYourClass />}
        />
        <Route
          path="schools/:id/classrooms/:id/assess_report"
          element={<PrePostReport />}
        />
        <Route
          path="schools/:id/classrooms/:id/students/:id/assess_report"
          element={<PrePostReport />}
        />
        <Route
          path="schools/:id/classrooms/:id/class_assess_report"
          element={<PrePostClassReport />}
        />

        <Route
          path="schools/:id/classrooms/:id/assessyourclass"
          element={<AssessYourClass />}
        />
        <Route
          path="schools/:id/classrooms/:id/assess_report"
          element={<PrePostReport />}
        />
        <Route
          path="schools/:id/classrooms/:id/students/:id/assess_report"
          element={<PrePostReport />}
        />
        <Route
          path="schools/:id/classrooms/:id/students/:id/assesss_student"
          element={<AssessYourClass />}
        />

        <Route path="assessment" element={<Assessment />} />
        <Route path="learnosityassessment" element={<LearnosityAssessment />} />

        <Route path="brainGames" element={<Games />} />
        <Route path="myLessonsResources" element={<MyLessonsResources />} />
      </Route>
    </Routes>
  );
}
