import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "../styles/layouts/customDialog.css";

const CustomDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  learnosity,
}) => {
  return (
    <Dialog className="dialogContainer" open={open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <DialogTitle className="dialogTitle">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="dialogContent">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!learnosity ? (
          <>
            <Button className="cancelButton" onClick={onClose}>
              Cancel
            </Button>
            <Button className="deleteButton" onClick={onConfirm}>
              Delete
            </Button>
          </>
        ) : (
          <Button className="cancelButton" onClick={onClose}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
