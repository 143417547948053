import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/Resources.css";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";

const Resources = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [episodeId, setEpisodeId] = useState();
  const [lessonId, setLessonId] = useState([]);
  const [courseTypeId, setCourseTypeId] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const resourceData = useSelector(
    (state) => state.lessonsResourcesReducer?.resourceData
  );

  useEffect(() => {
    const regex = /\/courses\/(\d+)\/lesson_groups\/(\d+)\/episode\/(\d+)/;
    // courses/${courseTypeId}/lesson_groups/${lessonId}/episode/${rowData.episodeId}

    const match = window.location.href.match(regex);
    setCourseTypeId(match[1]);
    setLessonId(match[2]);
    setEpisodeId(match[3]);
    setLoading(true);

    {
      resourceData?.episodesDataList.map((episode, index) => {
        if (episode.episodeId == match[3]) {
          setData(episode);
          setLoading(false);
        }
      });
    }
  }, [userData]);

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading"> {data?.episodeName} Resources</div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/myLessonsResources">
          My Lessons & Resources
        </Link>{" "}
        {" > "}
        <Link
          to={`/dashboard/courses/${courseTypeId}/lesson_groups/${lessonId}`}
        >
          Lesson Groups
        </Link>{" "}
        {" > "}
        Resources
      </div>

      {resourceData?.episodesDataList.map((item, index) => {
        if (item.episodeId == episodeId)
          return (
            <>
              <div className="cardRow">
                {data?.resource_url?.map((row, index) => {
                  return (
                    <>
                      <div className="cardRowh mb-3  w-45">
                        <div className="card h-100">
                          <div className="cardHeader">{row.title}</div>

                          <div className="cardBody">
                            <div className="">
                              <img
                                src={row.imgUrl}
                                alt="Lesson Logo"
                                className="imgHeight"
                              />
                            </div>
                          </div>
                          <div className="cardFooter p-2 text-center cardFooterHeight">
                          {row.pdfUrl !== null ?
                            <a href={row.pdfUrl} target="_blank">
                              Preview Attachment - {row.filename}
                            </a> 
                            : ""}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          );
      })}

      {/* {resourceData?.episodesDataList.map((item, index) => {
                if( item.episodeId == episodeId)
                return (
                    <>
                      <div className="imgBoard">
                        <img src={row.imgUrl} alt="Student Logo" className="" />
                      </div>

                      <div className="imgLink">
                        <a href={row.pdfUrl}>
                          Download Attachment - {row.filename}
                        </a>
                      </div>
                    </>
                )
            }
            )}
 */}

      <CustomToastify />
    </Card>
  );
};

export default Resources;
