import React, { useState, useEffect, useRef } from "react";
// import {
//   Autocomplete,
//   Button,
//   Checkbox,
//   TextField,
//   Card,
//   CardContent,
//   IconButton
// } from "@mui/material";
// import { makeStyles } from "@material-ui/core";
import { useNavigate, Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../../styles/components/addEditTeacher.css";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import {
  getClassDataById,
  getTeacherList,
  setSaveClass,
  setUpdateClass,
  deleteClassData,
} from "../../redux/Classes/ClassesAction";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import CustomDialog from "../../utils/CustomDialog";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";

const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
const data = ["Ayushi", "Trupthi"];
const AddEditClass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [className, setClassName] = useState("");
  const [grade, setGrade] = useState("");
  const [schoolId, setSchoolId] = useState(0);
  const [classroomId, setClassroomId] = useState(0);
  const [teacherList, setTeacherList] = useState([]);
  const [errors, setErrors] = React.useState({});
  const [isEditChange, setIsEditChange] = useState(false);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = React.useState(teacherList);
  //teacherList change
  const [right, setRight] = React.useState([]);  
  const nameRef = useRef(null);
  const gradeRef = useRef(null);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const userData = useSelector((state) => state.loginReducer?.userData);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setIsEditChange(true);
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setIsEditChange(true);
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    scrollToError();
  }, [errors]);

  const scrollToError = (event) => {
    if (errors.className) {
      nameRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.grade) {
      gradeRef.current.scrollIntoView({ behavior: "smooth" });
    } 
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isEditMode) {
      if (validateForm()) return;
    } else {
      if (!isEditChange) {
        dispatch(showToastMessage(201, "No fields have been changed."));
        return;
      } else if (validateForm()) return;
    }

    setIsLoading(true);
    var dataModel = {
      classroomId,
      className,
      right,
      grade,
      schoolId,
    };
    isEditMode
      ? dispatch(setUpdateClass(dataModel)).then(async (res) => {
          dispatch(showToastMessage(res?.statusCode, res?.message));
          if (res?.statusCode === 200) {
            navigate(
              `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`
            );
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
              navigate("/login", { replace: true });
              dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
            });
          }

          setIsLoading(false);
          setClassName("");
          setGrade("");
          // setRight([]);
          // }
        })
      : dispatch(setSaveClass(dataModel)).then(async (res) => {
          if (res?.statusCode === 200) {
            navigate(
              `/dashboard/schools/${userData?.school_id}/classrooms/${res?.class_Id}`
            );
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
              navigate("/login", { replace: true });
              dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
            });
          }
          dispatch(showToastMessage(res?.statusCode, res?.message));
          setIsLoading(false);
          setClassName("");
          setGrade("");
          setRight([]);
          setLeft(teacherList)
          // }
        });
    // // Submit form logic
  };

  useEffect(() => {
    const containsEditClass = window.location.href.includes("edit");
    containsEditClass ? setIsEditMode(true) : setIsEditMode(false);
    const regex = containsEditClass
      ? /\/schools\/(\d+)\/classrooms\/(\d+)/
      : /\/schools\/(\d+)\/classrooms\//;
    const match = window.location.href.match(regex);
    if (containsEditClass) {
      setSchoolId(match[1]);
      setClassroomId(match[2]);
    } else {
      setSchoolId(match[1]);
    }
    if (!containsEditClass) {
      dispatch(getTeacherList(match[1])).then(async (res) => {
        // if (res?.statusCode === 200) {
        var teacherOptions = [];
        res?.teachersList?.map((value) => {
          teacherOptions.push(value);
        });
        setTeacherList(teacherOptions);
        setLeft(teacherOptions);
      });
    } else {
      setIsLoading(true);
      dispatch(getClassDataById(match[1], match[2])).then(async (res) => {
        // if (res?.statusCode === 200) {
        if (res.ext_class_id !== null) {
          setIsDisabled(true);
        }
        setClassName(res.className);
        setGrade(res.grade);
        var selectclasses = [];
        var availableclasses = [];
        res?.availableTeachersList?.map((value) => {
          selectclasses.push(value);
        });
        setLeft(selectclasses);
        res?.selectedTeachersList?.map((value) => {
          availableclasses.push(value);
        });
        setRight(availableclasses);
        setIsLoading(false);
      });
    }
  }, []);

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [className, grade];
    requireFields = ["className", "grade"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      // && field !== " " && field !== "  " && field !== "   "
      // if (!(field !== null && field.length !== 0)) {

      if (!(field !== null && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }

      if (requireFields[index] == "className" && field.length > 50) {
        errors[requireFields[index]] =
          "Class Name is too long (maximum is 50 characters)";
      }
      if (requireFields[index] == "grade" && field.length > 15) {
        errors[requireFields[index]] =
          "Grade is too long (maximum is 15 characters)";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const CardSelector = (title, items, side) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card
        style={{ display: "flex", flexDirection: "column", height: "25rem" }}
        component={Paper}
      >
        <CardHeader
          className={"classes.cardHeader stickyHeader"}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
          action={
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        />
        <Collapse in={expanded} className={classes.collapse}>
          <List className={classes.list} dense component="div" role="list">
            {items?.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.username}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Collapse>
      </Card>
    );
  };

  const handleChange = (e) => {
    if (isEditMode) {
      setIsEditChange(true);
    }
    const { name, value } = e.target;
    e.target.value.trim();
    var data =
      name === "className"
        ? setClassName(value)
        : name === "grade"
        ? setGrade(value)
        : "";
  };
  // const handleDelete = () => {
  //   const answer = window.confirm(
  //     "Are you sure you want to delete " + className + " ?"
  //   );

  //   // setIsLoading(true);
  //   if (answer) {
  //     dispatch(deleteClassData(userData?.school_id, classroomId)).then(async (res) => {
  //       if (res?.statusCode === 200) {
  //         navigate(`/dashboard/classes`);
  //       }

  //       dispatch(showToastMessage(res?.statusCode, res?.message))
  //       setIsLoading(false);
  //     });
  //   }
  //   // Handle delete logic here
  // };

  const handleDelete = () => {
    setMessage("Are you sure you want to delete " + className + " ?");
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    dispatch(deleteClassData(userData?.school_id, classroomId)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          navigate(`/dashboard/classes`);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
            navigate("/login", { replace: true });
            dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
          });
        }

        dispatch(showToastMessage(res?.statusCode, res?.message));
        setIsLoading(false);
      }
    );
    setIsDialogOpen(false);
  };

  return (
    <Card sx={{ m: 1, p: 4 }}>
      {isLoading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="pageHeading">
        {isEditMode ? "Edit Classroom" : "New Classroom"}
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/classes">My Classes</Link> {" > "}
        {isEditMode ? "Edit Classroom" : " New Classroom"}
      </div>
      <form className="form" onSubmit={handleFormSubmit}>
        <div className="formContents">
          <div className="detailSection m0">
            <div className="textInputResponsive" style={{ display: "flex" }}>
              <div className="inputBox f1" ref={nameRef}>
                <label className="inputLabel">
                  Name<span className="labelReqField">*</span>
                </label>
                <input
                  type="text"
                  name="className"
                  className={`input ${errors.className ? "errorInput" : ""}`}
                  id="className"
                  placeholder="Name"
                  value={className}
                  onChange={handleChange}
                  disabled={isDisabled}
                ></input>
                {errors.className && (
                  <div className="error">{errors.className}</div>
                )}
              </div>
              <div className="inputBox f1" ref={gradeRef}>
                <label className="inputLabel">
                  Grade<span className="labelReqField">*</span>
                </label>
                <input
                  type="text"
                  name="grade"
                  className={`input ${errors.grade ? "errorInput" : ""}`}
                  id="grade"
                  placeholder="Grade"
                  value={grade}
                  onChange={handleChange}
                  disabled={isDisabled}
                ></input>
                {errors.grade && <div className="error">{errors.grade}</div>}
              </div>
            </div>

            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item className="leftGridCard">
                <Paper elevation={3} className="classesList">
                  {CardSelector("Teachers Available", left, "left")}
                </Paper>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className="arrowButton"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>

              <Grid item className="rightGridCard">
                <Paper elevation={3} className="classesList">
                  {CardSelector("Selected Teachers", right, "right")}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="itemSpace">
          <div>
            {isEditMode && (
              <Button
                className={
                  isDisabled
                    ? "hide"
                    : "btn gradientButtonBlue mr-1 ml-2 btnFitResponsive ml2Responsive"
                }
                onClick={handleDelete}
              >
                Delete Class
              </Button>
            )}
          </div>
          <div className="itemEnd ">
            <Button
              className="btn gradientButtonBlue mr-1 btnFitResponsive"
              type="submit"
            >
              {isEditMode ? "Update Class" : "Save Class"}
            </Button>
            <Link to={`/dashboard/classes`}>
              <Button className="btn gradientButtonOrange btnFitResponsive">
                Cancel
              </Button>
            </Link>
          </div>
        </div>
      </form>

      <CustomToastify />
      <CustomDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Delete Class"
        content={message}
        learnosity={false}
      />
    </Card>
  );
};

export default AddEditClass;
