import {
  GET_STUDENT_DASHBOARD_TEACHER,
  GET_SOUND_CLASS,
  GET_STUDENT_DATA_BY_CLASS,
  GET_STUDENT_REPORT,
  GET_STUDENT_NOT_IN_CLASS,
  GET_LESSON_GROUP,
  DELETE_STUDENT_DATA_FROM_CLASS,
  SET_SAVE_STUDENT,
  SET_UPDATE_STUDENT,
  GET_STUDENT_DASHBOARD,
  IMPORT_STUDENT_DATA,
  SET_SAVE_STUDENT_IN_SCHOOL,
  SET_CLASSROOM_NAME,
  DELETE_STUDENT,
  GET_STUDENT_DATA_BY_ID,
  SAVE_UPDATE_STUDENT_DATA,
  GET_STUDENT_PROGRESS_REPORT_BY_ID,
  GET_STUDENT_STANDARD_REPORT,
  GET_STUDENT_GAMES_REPORT,
  SET_CATEGORY_NAME,
  GET_PRE_POST_REPORT,
  GET_COMPREHENSHION_REPORT,
  GET_REVIEW_REPORT,
  SET_EPISODE_NAME,
  SET_LEARNOSITY_SIGNATURE,
  GET_ASSESS_STUDENT_DATA,
  SET_LEARNOSITY_TEST_PROGRESS,
  SET_LEARNOSITY_UPDATE_PROGRESS,
  SET_ASSESS_STUDENT,
  SET_ASSESS_CLASS,
  SET_ASSESS_REPORT,
} from "./StudentAction";

const initialState = {
  data: "",
  classroomName: "",
  progressReport: "",
  standardReport: "",
  gamesReport: "",
  categoryName: "",
  prePostReport: "",
  compReport: "",
  reviewReport: "",
  episodeName: "",
  learnositySignature: "",
  assessStudentData: "",
  testProgress: "",
  updateProgress: "",
  assessClass: "",
  assessStudent: "",
  assessReport: null,
  studentData: "",
  studentReport: "",
  stuId: 0,
};

function studentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_DASHBOARD_TEACHER:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_SOUND_CLASS:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_STUDENT_DATA_BY_CLASS:
      return (state = {
        ...state,
        studentData: action.data,
      });
    case GET_STUDENT_REPORT:
      return (state = {
        ...state,
        studentReport: action.data,
      });
    case DELETE_STUDENT_DATA_FROM_CLASS:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_STUDENT_NOT_IN_CLASS:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_LESSON_GROUP:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_STUDENT_DASHBOARD:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_SAVE_STUDENT:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_UPDATE_STUDENT:
      return (state = {
        ...state,
        data: action.data,
      });
    case IMPORT_STUDENT_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_SAVE_STUDENT_IN_SCHOOL:
      return (state = {
        ...state,
        data: action.data,
      });
    case DELETE_STUDENT:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_STUDENT_DATA_BY_ID:
      return (state = {
        ...state,
        data: action.data,
      });
    case SAVE_UPDATE_STUDENT_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_CLASSROOM_NAME:
      return (state = {
        ...state,
        classroomName: action.data,
      });
    case GET_STUDENT_PROGRESS_REPORT_BY_ID:
      return (state = {
        ...state,
        progressReport: action.data,
      });
    case GET_STUDENT_STANDARD_REPORT:
      return (state = {
        ...state,
        standardReport: action.data,
      });
    case GET_STUDENT_GAMES_REPORT:
      return (state = {
        ...state,
        gamesReport: action.data,
      });

    case SET_CATEGORY_NAME:
      return (state = {
        ...state,
        categoryName: action.data,
      });

    case GET_REVIEW_REPORT:
      return (state = {
        ...state,
        reviewReport: action.data,
      });
    case GET_COMPREHENSHION_REPORT:
      return (state = {
        ...state,
        compReport: action.data,
      });

    case GET_PRE_POST_REPORT:
      return (state = {
        ...state,
        prePostReport: action.data,
      });

    case SET_EPISODE_NAME:
      return (state = {
        ...state,
        episodeName: action.data,
      });
    case SET_LEARNOSITY_SIGNATURE:
      return (state = {
        ...state,
        learnositySignature: action.data,
      });

    case GET_ASSESS_STUDENT_DATA:
      return (state = {
        ...state,
        assessStudentData: action.data,
      });
    case SET_LEARNOSITY_TEST_PROGRESS:
      return (state = {
        ...state,
        testProgress: action.data,
      });
    case SET_LEARNOSITY_UPDATE_PROGRESS:
      return (state = {
        ...state,
        updateProgress: action.data,
      });

    case SET_ASSESS_CLASS:
      return (state = {
        ...state,
        assessClass: action.data,
      });

    case SET_ASSESS_STUDENT:
      return (state = {
        ...state,
        assessStudent: action.data,
      });
    case SET_ASSESS_REPORT:
      return (state = {
        ...state,
        assessReport: action?.data1,
        stuId: action?.data2,
      });

    default:
      return state;
  }
}

export default studentReducer;
