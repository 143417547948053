import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/LessonGroups.css";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { tableIcons, options } from "../../utils/TableMui";
import {
  FaPrint,
  FaEdit,
  FaEye,
  FaVolumeUp,
  FaVolumeOff,
  FaClipboardList,
  FaClone,
} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { getAllClassesData } from "../../redux/Classes/ClassesAction";
import {
  getSceneDetails,
  getPosition,
  setStudentScene,
  getGamesData,
  setGameScore,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import Slideshow from "./SlideShow";
// import Games from "../MyStudentsAndReports/Games";
// import GameComponent from "./Games/app";
import { setStudentPlay } from "../../redux/Lessons/LessonsAction";
import Games from "./Games";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const Preview = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [gameIndex, setGameIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [responseGame, setResponseGameData] = useState([]);
  const [data, setData] = useState([]);
  const [lessonId, setLessonId] = useState([]);
  const [scoreSet, setScoreSet] = useState(true);
  const [scoreSetFinal, setScoreSetFinal] = useState(true);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );
  const studentPlay = useSelector((state) => state.lessonReducer?.studentPlay);

  useEffect(() => {

   

    const regex = /\/lessons\/(\d+)\//;
    const match = window.location.href.match(regex);
    setLessonId(match[1]);
    dispatch(getPosition(match[1])).then(async (res) => {
      if (res?.statusCode === 200) {
        setData(res?.episodeScenesList);
        setLoading(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if(userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true))
          }
          navigate("/login", { replace: true });
          dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
        });
      }
    });

    const urlParam = new URLSearchParams(window.location.search);
    const extractedIndex = parseInt(urlParam.get("currentIndex", 10));

    if (!isNaN(extractedIndex)) {
      setCurrentIndex(extractedIndex);
    }
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };
  const handleNextItem = () => {
    const nextIndex = currentIndex + 1;

    setCurrentIndex((prevIndex) => prevIndex + 1);
    if (currentIndex === data.length - 1) {
      userData?.userType !== "student"
        ? navigate(
            `/dashboard/courses/${courseId}/lesson_groups/${lessonGroupId}`
          )
        : navigate(`/dashboard/lessons`);
      dispatch(setStudentPlay(false));
    } else {
      navigate(`${window.location.pathname}?currentIndex=${nextIndex}`);
      window.location.reload();
    }
    // if (
    //   currentIndex !== data.length - 1 &&
    //   data[currentIndex + 1]?.kind === "game"
    // ) {
    //   dispatch(getGamesData(data[currentIndex + 1]?.resource_id)).then(
    //     async (res) => {
    //       if (res?.statusCode === 200) {
    //         setResponseGameData(res);
    //       }
    //     }
    //   );
    // }
  };

  // const handleGameCompletion = () => {
  //   const nextIndex = currentIndex + 1;
  //    userData?.userType === "student" &&
  //     dispatch(setStudentScene(userData?.id, lessonId, data[currentIndex]?.id)).then(
  //       async (res) => {}
  //     );
  //   handleNextItem();
  //   navigate(`${window.location.pathname}?currentIndex=${nextIndex}`);
  //   window.location.reload();
  // };
  // const handleGameScore = (dataModel) => {
  //   var gameData = {
  //     timeSpent: dataModel.time,
  //     score: dataModel.score,
  //     level: dataModel.level,
  //     progress: dataModel.progress,
  //     studentId: userData.id,
  //     gameId: data[currentIndex]?.resource_id,
  //     mistakes: dataModel.score !== "100" ? 1 : 0,
  //   };

  //   if(userData.id !==null && data[currentIndex]?.resource_id !=null && scoreSet){
  //     setScoreSet(false)
  //   dispatch(setGameScore(gameData)).then(async (res) => {
  //     if (res?.statusCode === 200) {
  //       // setResponseGameData(res);
  //     }
  //   });}
  // };

  const currentData = data[currentIndex];

  // const handleIframeLoad = (event) => {
  //   const iframe = event.target;
  //   dispatch(getGamesData(data[currentIndex]?.resource_id)).then(
  //     async (res) => {
  //       if (res?.statusCode === 200) {
  //         setResponseGameData(res);
  //         let gameData;
  //         let imageData;
  //         gameData = {
  //           gameDescription: res.gameDescription,
  //           instructionConfig: res.instructionConfig,
  //           levels: res.levels,
  //           maximumScore: res.maximumScore,
  //           roundsPerLevel: res.roundsPerLevel,
  //           tries: res.tries,
  //         };
  //         imageData = {
  //           spritesheet: res.images.spritesheet,
  //           backgroundImage: res.images.backgroundImage,
  //         };
  //         const message = {
  //           type: "init",
  //           data: {
  //             gameData,
  //             imageData,
  //             onComplete: "handleGameCompletion",
  //           },
  //         };

  //         iframe.contentWindow.postMessage(message, "*");
  //       }
  //     }
  //   );
  // };

  // window.addEventListener("message", (event) => {
  //   if (event.data === "gameCompleted") {
  //     handleGameCompletion();
  //   }
  //   // Check if event.data is an object
  //   if (
  //     event.data &&
  //     typeof event.data === "object" &&
  //     event.data.hasOwnProperty("time") &&
  //     event.data.hasOwnProperty("score") &&
  //     event.data.hasOwnProperty("progress") &&
  //     event.data.hasOwnProperty("level")
  //   ) {
  //     const { time, score, level, progress } = event.data;

  //     // Validate that required properties exist
  //     if (
  //       time !== undefined &&
  //       score !== undefined &&
  //       level !== undefined &&
  //       progress !== undefined
  //     ) {
  //       // Transform progress into an array of objects
  //       const output = Object.values(progress).map(
  //         (item) => Object.values(item)[0]
  //       );

  //       // Call handleGameScore only if userType is "student" and scoreSetFinal is true
  //       if (userData.userType === "student" && scoreSetFinal) {
  //         const dataModel = {
  //           time: time,
  //           score: score,
  //           level: level,
  //           progress: output,
  //         };

  //         setScoreSetFinal(false);
  //         handleGameScore(dataModel);
  //       }
  //     }
  //   }
  // });

  return (
    <>
      {data.map((episode, index) => {
        if (currentIndex === index && episode?.kind === "slides") {
          return (
            <Slideshow
              key={index}
              sceneId={episode?.id}
              onNextItem={() => handleNextItem(episode?.id)} 
              episode_id={episode?.episode_id}
            />
          );
        } else if (currentIndex === index && episode?.kind === "game") {
          // return 1;
          // <Link t0="../../../../games"></Link>
          // return <GameComponent  key={index} onNextItem={handleNextItem} gameId={episode?.resource_id}/>
          return (
            <Games
              key={index}
              onNextItem={handleNextItem}
              sceneId={episode?.id}
              gameId={episode?.resource_id}
            />
          );
          // setGameIndex(episode?.resource_id)
          // return (
          //   <iframe
          //     key={index}
          //     title={`PhaserGame-${index}`}
          //     // src="http://127.0.0.1:5500/src/index1.html"

          //     src="/srcGames/index1.html"
          //     // src="http://127.0.0.1:5501/src/srcGames/index1.html"
          //     width="100%"
          //     height="650px"
          //     // width="1920px"
          //     // height="1485px"
          //     frameBorder="0"
          //     onLoad={handleIframeLoad}
          //   ></iframe>
          // );
        }
      })}
    </>
  );
};

export default Preview;
