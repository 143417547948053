import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/Store/Store";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
// import theme from "./theme";
import { BrowserRouter, Routes } from "react-router-dom";
import rootReducer from "./redux/rootReducer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"
import { PersistGate } from "redux-persist/integration/react";
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { GoogleOAuthProvider } from '@react-oauth/google';


const persistConfig = {
  key:'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer,  compose(applyMiddleware(thunk), compose))
const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading ={null} persistor={persistor}>
      <div>
      {/* clientId="842758489535-t2h5d6o9j4a1jpg3hjdgd0g0rup5jesd.apps.googleusercontent.com" */}
      
      <GoogleOAuthProvider 
      clientId="1011356561722-5groahseinjt0mome2lf5gualhfot3g2.apps.googleusercontent.com"
      
      >
        <App />
        </GoogleOAuthProvider>
        <ToastContainer/>
      </div>
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
