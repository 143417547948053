import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { FaWindowClose } from "react-icons/fa";
import "../styles/layouts/Snackbar.css";

export default function CustomSnackBar(props) {
  
  return (
   
    <div>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={props.open}
        snackState={props.snackState}
        autoHideDuration={5000}
        message={props.message}
        onClose={props.onClose}
        className="snackBar"
        // style={{ backgroundColor: props.snackState ? "#aacb5f" : "red", borderRadius: "8px" }}
        action={
          <React.Fragment>
            <IconButton className="closeIcon"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={props.onClose}
              style={{ margin: "0!important" }}
            >
              <FaWindowClose fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
