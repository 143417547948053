import {
    GET_TEACHER_DATA,
    SAVE_UPDATE_TEACHER_DATA,
    DELETE_TEACHER_DATA,
    GET_TEACHER_BYID_DATA,
    SAVE_ACTIVE_INACTIVE_TEACHER
  } from "./TeachersAction";
  
  const initialState = {
    teacherData: null,
    data: null,
    dataById: null
  };
  
  function loginReducer(state = initialState, action) {
    switch (action.type) {
      case GET_TEACHER_DATA:
        return (state = {
          ...state,
          data: action.data,
        });
      case GET_TEACHER_BYID_DATA:
        return (state = {
          ...state,
          dataById: action.data,
        });
      case SAVE_UPDATE_TEACHER_DATA:
        return (state = {
          ...state,
          data: action.data,
        });
      case DELETE_TEACHER_DATA:
        return (state = {
          ...state,
          data: action.data,
        });
        case SAVE_ACTIVE_INACTIVE_TEACHER:
          return (state = {
            ...state,
            data: action.data,
          });
      default:
        return state;
    }
  }
  
  export default loginReducer;
  