import { Card } from "@material-ui/core";
import React, { useState } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import "../../styles/main.css";
import "../../styles/components/home.css";

const Home = (props) => {
  return (
    <Card sx={{ m: 1, p: 4 }}>
      <div className="pageHeading">Take a quick tour of KneoWorld learning</div>
      <div className="primary-content">
        <div className="container-fluid">
          <div className="row">
            <div className="lead">
              We have so much to offer teachers, parents, and (most importantly)
              students! KneoWorld's curriculum is standards-based and designed
              to accelerate reading and math. Hope you enjoy this short
              explanation of who we are, what we do, and how to access
              KneoWorld. If you’re short of time all videos are available in the
              HELP HUB as well as assistance 24/7 to ensure you get the most out
              of your KneoWorld experience.
            </div>
            
            <div className="video-container">
              <iframe
                src="https://player.vimeo.com/video/890883560?h=7e54ac7da1"
                width="640" 
                height="564"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Home;
