import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/LessonGroups.css";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import MaterialTable from "material-table";
import { tableIcons, options } from "../../utils/TableMui";
import {
  FaPrint,
  FaEdit,
  FaEye,
  FaVolumeUp,
  FaVolumeOff,
  FaClipboardList,
  FaClone,
} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { getAllClassesData } from "../../redux/Classes/ClassesAction";
import { getLessonsData } from "../../redux/LessonsResources/LessonsResourcesAction";
import {
  setCourseName,
  setLessonName,
  setLessonDescription,
  setCourseId,
  setLessonGroupId,
  getTeachersGuide,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);
const LessonGroups = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [lessonId, setLessonId] = useState([]);
  const [courseTypeId, setCourseTypeId] = useState([]);
  const [lessonCourseName, setLessonCourseName] = useState([]);
  const [teachersGuideUrl, setTeachersGuideUrl] = useState();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const courseName = useSelector(
    (state) => state.lessonsResourcesReducer?.courseName
  );
  const lessonName = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonName
  );
  const lessonDescription = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonDescription
  );
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );
  const resourceData = useSelector(
    (state) => state.lessonsResourcesReducer?.resourceData
  );

  useEffect(() => {

    const pageTitle = document.querySelector("title")
    if(pageTitle) {
      pageTitle.textContent = "KneoWorld";
    }

    var detailName = courseName + " : " + lessonName;
    setLessonCourseName(detailName);
    const regex = /\/courses\/(\d+)\/lesson_groups\/(\d+)/;
    const match = window.location.href.match(regex);
    setCourseTypeId(match[1]);
    setLessonId(match[2]);
    if (userData?.school_id !== undefined) {
      dispatch(getLessonsData(userData?.school_id, match[1], match[2])).then(
        async (res) => {
          if (res?.statusCode === 200) {
            // setAllData(res);
            setData(res?.episodesDataList);
            setLoading(false);
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            dispatch(getLogout()).then(async (res) => {
              if(userData?.roster_name === "edlink") {
                dispatch(setSSOLogout(true))
              }
              navigate("/login", { replace: true });
              dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
            });
          }
        }
      );
    }

    dispatch(getTeachersGuide(match[2])).then(async (res) => {
      if (res?.statusCode === 200) {
        // setAllData(res);
        setTeachersGuideUrl(res?.guideUrl);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if(userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true))
          }
          navigate("/login", { replace: true });
          dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
        });
      }
    });
  }, []);

  const handleAssignStudents = () => {
    dispatch(setCourseId(courseTypeId));
    dispatch(setLessonGroupId(lessonId));

    navigate(
      `/dashboard/lesson_groups/${lessonId}/bulk_lesson_group_assignments/new`
    );
  };

  const tableTitle = (
    <div className="TableTitle">No. of lessons: {data.length}</div>
  );

  const customFilterAndSearch = (term, rowData) => {
    // Split the search term into words
    let termArray = [];
    const terms = term.toLowerCase();
    termArray.push(terms);

    // Check if any of the words in the term matches lessonGroup or description
    return termArray.some(
      (t) =>
        rowData.episodeName.toLowerCase().includes(t) ||
        rowData.description.toLowerCase().includes(t)
    );
  };

  const columns = [
    {
      title: "Lesson",
      field: "episodeName",
      render: (rowData) => (
        <div className="lessonMain">
          <div className="lessonLeft mr-1">
            <img src={rowData.url} alt="Teacher Logo" className="lessonLogo" />
          </div>

          <div className="lessonRight">
            <div style={{ marginLeft: 5, fontSize: "1.2rem", paddingBottom: "1rem" }}>
              <span>
                <b>{rowData?.episodeName}</b>
              </span>
            </div>

            <div style={{ marginLeft: 5 }}>
              <span>{rowData?.description}</span>
            </div>
          </div>
        </div>
      ),
      headerStyle: { width: "80%", paddingLeft: "15rem"},
      cellStyle: { width: "80%", height: "14rem" },
      sorting: false,
      filtering: false,
      //  sorting: data?.length > 0,
      customFilterAndSearch: (term, rowData) =>
        customFilterAndSearch(term, rowData),
    },

    {
      title: "Actions",
      render: (rowData) => (
        <div>
          {/* <Tooltip title={"Preview"}> */}
          {/* <Link
            to={`/lessons/${rowData?.episodeId}/scenes/${rowData?.scene_id}`}
          > */}
          <Button
            className="btn gradientButtonBlue mr-1"
            onClick={() => handlePreview(rowData?.episodeId, rowData?.scene_id)}
          >
            Preview
          </Button>
          {/* <div className="btn-action">               
                  <FaClone className="btn-icon btn-orange-casablanca" />                
                </div> */}
          {/* </Link> */}
          {/* </Tooltip> */}

          {/* <Tooltip title={"Resources"}> */}

          {rowData.resource_url.length > 0 && (
            <Link
              to={`/dashboard/courses/${courseTypeId}/lesson_groups/${lessonId}/episode/${rowData.episodeId}`}
            >
              <Button
                className="btn gradientButtonBlue"
                // onClick={handleAddTeacher}
              >
                Resources
              </Button>
            </Link>
          )}
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      width: 100,
      sorting: false,
    },
  ];

  const handleTeachersGuide = async () => {
    try {
      const guideUrl = teachersGuideUrl;
      const response = await fetch(guideUrl);
      const blob = await response.blob();
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(blob);
      anchor.download = "teachers_guide.pdf";
      anchor.click();
      URL.revokeObjectURL(anchor.href);
    } catch (error) {
      console.error("Error while downloading the file:", error);
    }
  };

  const onButtonClick = () => {
    fetch(teachersGuideUrl).then((response) => {
      // dispatch(getTeachersGuide(lessonId).then(response => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "TeacherPDF.pdf";
        alink.click();
      });
    });
  };

  // to={`/lessons/${rowData?.episodeId}/scenes/${rowData?.scene_id}`}
  const handlePreview = (episodeId, sceneId) => {
    dispatch(setCourseId(courseTypeId));
    dispatch(setLessonGroupId(lessonId));
    userData.userType == "student"
      ? navigate(`/lessons/${episodeId}/scenes`)
      : window.open(`/lessons/${episodeId}/scenes`, "_blank");
  };

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div ref={tableRef}></div>
      <div className="itemSpace">
        <div className="pageHeading">
          {courseName === " " ? lessonName : lessonCourseName}
        </div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/myLessonsResources">
          My Lessons & Resources
        </Link>{" "}
        {" > "}
        Lesson Groups
      </div>

      <div className="itemSpace ">
        <div className="LessonHead ml-3 mb-1 pt-1"> Information </div>
        <div className="mb-1">
          <div className="itemEnd ">
            {/* <Link to={`/dashboard/schools/classrooms/new`}> */}
            {teachersGuideUrl !== "" &&
              teachersGuideUrl !== undefined &&
              teachersGuideUrl !== null && (
                <div className="mr-1">
                  <a href={teachersGuideUrl} target="_blank">
                    <Button
                      className="btn gradientButtonPink"
                      // onClick={handleTeachersGuide}
                    >
                      Teacher's Guide
                    </Button>
                  </a>
                </div>
              )}
            {/* </Link> */}

            <div className="mr-1">
              {/* <Link
                to={`/dashboard/lesson_groups/${lessonId}/bulk_lesson_group_assignments/new`}
              > */}
              <Button
                className="btn gradientButtonPink"
                onClick={handleAssignStudents}
              >
                Assign Students
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="LessonSubHead ml-3 mb-1">{lessonDescription}</div>

      
      <div className="MuiTable">
        <MaterialTable
          title={tableTitle}
          className="myTable"
          icons={tableIcons}
          data={data}
          columns={columns}
          options={{...options, 
            filtering: false}}
          // isLoading={loading}
          onChangePage={handlePageChange}
        />
      </div>

      <CustomToastify />
    </Card>
  );
};

export default LessonGroups;
