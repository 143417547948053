import React, { useState } from "react";
import "../../styles/layouts/header.css";
import { useNavigate, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "../../styles/layouts/customDialog.css";

import { useDispatch, useSelector } from "react-redux";
import { FaRegQuestionCircle } from "react-icons/fa";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  sendEmailForgotPassword,
  setIsEducator,
} from "../../redux/Login/LoginAction";
// import { withCookies, Cookies } from 'react-cookie';
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
// import NameForm from './NameForm';
import {  getLogout, setSSOLogout} from "../../redux/Login/LoginAction";


const PopUpCustomDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  learnosity,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [responseCode, setResponseCode] = React.useState("");
  const [cookie, removeCookie] = useCookies(["https://accounts.google.com"]);

  const userData = useSelector((state) => state.loginReducer?.userData);

  const handleChangePassword= () =>{
    onClose();
 navigate("/dashboard/users/password_update/new");
  }
  const handleLogout = () => {
    
    // localStorage.removeItem("AuthToken");
    // localStorage.removeItem("UserData");
    Cookies.remove("https://accounts.google.com", { path: "/" });
    // const cookie  = useCookies(['https://accounts.google.com']);
    removeCookie(cookie);
    // sessionStorage.clear();
    // Cookies.remove('name', { path: 'https://accounts.google.com' })
    // var Cookies2 = Cookies.noConflict();
    // Cookies2.remove('name', { path: '', domain: 'https://accounts.google.com' })
    //  Cookies.remove('https://accounts.google.com', {path: '/', domain: 'http://localhost:3000'});
    //  Cookies.remove('https://accounts.google.com', { domain: 'localhost', path: '/' });
    // navigate("/login");
    if(userData?.roster_name === "edlink") {
      dispatch(setSSOLogout(true))
    }
    dispatch(getLogout()).then(async (res) => {
      navigate("/login", { replace: true });
    });



  };

  return (
    <Dialog className="headerDialog" open={open} onClose={onClose} >
     
      <DialogContent>
        <DialogContentText className="dialogContent">
         
        <div >
        <div className="popupDetails">
          <div>
            {userData.userType === "school_admin"
              ? "School Admin Name : "
              : userData.userType === "teacher"
              ? "Teacher Name : "
              : userData.userType === "student"
              ? "Student Name : "
              : "Name : "}
          </div>
          <div className="lineAny f1">&nbsp;{userData.username}</div>
          </div>
        <div className=" popupDetails">
              <div>{"School Name : "}</div>
              <div className="lineAny f1">&nbsp;{userData.school_name}</div>
        </div>
        <div className=" popupDetails">
              <div>{"School Code : "}</div>
              <div className="lineAny f1">&nbsp;{userData.school_code} </div>
        </div>
        
        {userData?.userType !== "student" && userData?.roster_name !== "edlink" && (
          // <Link to="/dashboard/users/password_update/new">
            <div className="resetLink">
              <Button className="btn gradientButtonPink changePasswordBtn" onClick={handleChangePassword}>
                <div className="contactTitle">Change Password</div>
              </Button>
            </div>
          // </Link>
        )}
       
        <div className={userData?.userType !== "student" ? "btnLogout" :"btnLogout mt-3"}>
          <Button className="btn btnLightBlue" onClick={handleLogout}>Logout</Button>
        </div>

        <div className="contactDialog">
          <a
            href="https://kneoworld.zendesk.com/hc/en-us/requests/new"
            target="_blank"
            // rel="noopener noreferrer"
            // onClick={handleContactSupportClick}
          >
            <div className="contactTitle">
              <FaRegQuestionCircle className="iconAlign" /> Contact Support
            </div>
          </a>
        </div>
        <CustomToastify />
        </div>
          
          </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        {!learnosity ? (
          <>
            <Button className="cancelButton" onClick={onClose}>
              Cancel
            </Button>
            <Button className="deleteButton" onClick={onConfirm}>
              Delete
            </Button>
          </>
        ) : (
          <Button className="cancelButton" onClick={onClose}>
            Yes
          </Button>
        )}
      </DialogActions> */}
    </Dialog>
  );
};

export default PopUpCustomDialog;
