import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getSceneDetails,
  setStudentScene,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import { useDispatch, useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import Arrow from "../../assests/images/next-arrow.png";
import {
  faBars,
  faVolumeOff,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import { getStudentReport } from "../../redux/Student/StudentAction";
import { updateSound } from "../../redux/Student/StudentAction";
import {
  getGamesData,
  setGameScore,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
import { BASE_URL } from "../../redux/baseUrl/ApiConstant";

const Games = ({ key, onNextItem, sceneId, gameId }) => {
  // const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [showMute, setShowMute] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [lessonId, setLessonId] = useState("");
  const [scale, setScale] = useState(1);
  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [scoreSet, setScoreSet] = useState(true);
  const [scoreSetFinal, setScoreSetFinal] = useState(true);

  const userData = useSelector((state) => state.loginReducer?.userData);
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );
  const studentPlay = useSelector((state) => state.lessonReducer?.studentPlay);
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const currentData = data[currentIndex];
  const navigate = useNavigate();
  useEffect(() => {
    const regex = /\/lessons\/(\d+)\//;
    const match = window.location.href.match(regex);
    setLessonId(match[1]);
  }, []);

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async function resizeImageWithTransparency(blob, width, height) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(resolve, "image/png");
      };
      img.src = URL.createObjectURL(blob);
    });
  }

  const handleIframeLoad = (event) => {
    const iframe = event.target;
    dispatch(getGamesData(gameId, userData?.id)).then(async (res) => {
      if (res?.statusCode === 200) {
        const pageTitle = document.querySelector("title");
        if (pageTitle) {
          pageTitle.textContent = res.gameDescription.title;
        }

        let audioFiles = [];
        audioFiles.push(...res?.answersAudioFIleResponse);
        audioFiles.push(...res?.audioFileResponses);
        audioFiles.push(...res?.instructionAudioFileResponses);
        audioFiles.push(...res?.staticAudioFileResponses);

        const convertedAudioFiles = audioFiles.map((response) => {
          const audioBlob = new Blob(
            [base64ToArrayBuffer(response.audioFile)],
            { type: "audio/wav" }
          );
          const audioUrl = URL.createObjectURL(audioBlob);
          return { audio: audioUrl, title: response.title };
        });

        let blobs = {};
        const loadImages = async (imageUrls) => {
          await Promise.all(
            Object.entries(imageUrls).map(async ([elementName, urls]) => {
              const resizedBlobs = await Promise.all(
                urls.map(async (url) => {
                  const response = await fetch(url);
                  const blob = await response.blob();
                  const resizedBlob = await resizeImageWithTransparency(
                    blob,
                    384,
                    356
                  );
                  return URL.createObjectURL(resizedBlob);
                })
              );
              blobs[elementName] = resizedBlobs; // Assigning the blob directly, assuming it's a single blob.
              return;
            })
          );
        };
        await loadImages(res.images.spritesheet);
        let gameData;
        let imageData;
        gameData = {
          gameDescription: res.gameDescription,
          muted: res?.muted,
          instructionConfig: res.instructionsConfig,
          levels: res.levels,
          maximumScore: res.maximumScore,
          roundsPerLevel: res.roundsPerLevel,
          tries: res.tries,
        };
        imageData = {
          spritesheet: blobs,
          backgroundImage: res.images.backgroundImage,
        };
        const message = {
          type: "init",
          data: {
            gameData,
            imageData,
            onComplete: "handleGameCompletion",
            studentId: userData.id,
            gameid: gameId,
            lessonId: lessonId,
            sceneId: sceneId,
            save: userData?.userType == "student",
            baseUrl: BASE_URL,
            audioData: convertedAudioFiles,
          },
        };

        iframe.contentWindow.postMessage(message, "*");
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });
  };
  
  const handleGameCompletion = () => {
    const nextIndex = currentIndex + 1;
    onNextItem();
  };
  let counter = 0;
  window.addEventListener("message", (event) => {
    if (event.data === "gameCompleted") {
      handleGameCompletion();
    }
  });

 

  return (
    <div className="gameContainer">
      <iframe
        key={key}
        title={`PhaserGame-${key}`}
        src="/srcGames/index1.html"
        width="100%"
        height="650px"
        frameBorder="0"
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
};

export default Games;