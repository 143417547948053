import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "../Layouts/Header";
import { Navigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Navbar from "../Layouts/Navbar";
// ----------------------------------------------------------------------

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  [theme.breakpoints.up("lg")]: {
    // paddingTop: APP_BAR_DESKTOP + 32,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const isLoggedIn = () => {
    const data = localStorage.getItem("AuthToken");
    // const data = 1;
    if (data != null) return true;
    else return false;
  };
  return (
    <StyledRoot className="mainDashboard">
      <Main>
        {isLoggedIn() ? (
          <div>
            <Header />
            <div style={{ display: "flex" }}>
              <Navbar />

              <Outlet />
            </div>
          </div>
        ) : (
          <Navigate to={"/login"} />
        )}
      </Main>
    </StyledRoot>
  );
}
