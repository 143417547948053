import { SET_TOASTER } from "./ToasterAction";

const initialState = {
  message: "",
  responseCode: "",
};

function toasterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOASTER:
      return (state = {
        ...state,
        responseCode: action.responseCode,
        message: action.message,
      });
    default:
      return state;
  }
}

export default toasterReducer;
