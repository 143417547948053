import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/addEditTeacher.css";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import Tooltip from "@material-ui/core/Tooltip";
import { getAllClassesData } from "../../redux/Classes/ClassesAction";
import { tableIcons, options } from "../../utils/TableMui";
import "../../styles/components/Account.css";
import "./../../styles/main.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { purchaseLicense, getLicenseData } from "../../redux/Account/AccountAction";
import moment from "moment";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";

const PurchaseLicenses = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [newLicenseData, setNewLicenseData] = useState([]);
  const [licenseNumber, setLicenseNumber] = useState("");
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState({});
  const [licenseData, setLicenseDetailsData] = useState([]);

  const userData = useSelector((state) => state.loginReducer?.userData);
  // const licenseData = useSelector((state) => state.accountReducer?.licenseData);

  useEffect(() => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let newArray = [];

    // licenseData?.orderList?.forEach((course) => {
    //   const { id, school_id, request_date, licenses, order_value, status, created_by, delivery_date, expiry_date, created_at, updated_at } = course;
    //   let orderedDate = monthNames[(created_at).getMonth()]

    //   newArray.push({
    //     id,
    //     school_id,
    //     request_date,
    //     licenses,
    //     order_value,
    //     status,
    //     created_by,
    //     delivery_date,
    //     expiry_date,
    //     created_at: orderedDate,
    //     updated_at,

    //   });

    // });

    // setNewLicenseData(newArray);
    //    licenseData?.orderList?.map((mon) => (
    // monthNames[(row?.created_at).getMonth()]
    //   ))
    // const d = new Date();
    // document.write("The current month is " + monthNames[d.getMonth()]);
    dispatch(getLicenseData(userData?.school_id)).then(async (res) => {
      if (res?.statusCode === 200) {
        // setLicenseData(res);
        setLicenseDetailsData(res?.orderList);
        //   setAllLiscenseData(res);
        setLoading(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if(userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true))
          }
          navigate("/login", { replace: true });
          dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
        });
      }
    });
  }, []);

  const handleChange = (event) => {
    
    var licVal=(event.target.value.trim());
    if (licVal > 0) {
      setLicenseNumber(licVal);
    } else if (licVal == "" && licenseNumber.length == 1) {
      setLicenseNumber("");
    } else {
      setLicenseNumber(licenseNumber);
    }

  };

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [licenseNumber];
    requireFields = ["licenseNumber"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      if (field.length > 10) {
        errors[requireFields[index]] = "License Number is too long (maximum is 10 digits)";
      }
    });


    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleOrderClick = (event) => {
    event.preventDefault();

    if (validateForm()) return;

    setLoading(true);
    // if (!validateForm()) {
    // }
    //   var dataModel = {
    //     licenseNumber: licenseNumber.toString(),
    //   };

    if (validateForm()) return;

    //  const stringVal =  licenseNumber.toString();
    // const string = '' +licenseNumber;
    // let qty = licenseNumber.toString();

    dispatch(
      purchaseLicense(licenseNumber, userData?.school_id, userData?.id)
    ).then(async (res) => {
      //   setErrorOpen(true);
      setLoading(false);
      if (res?.statusCode === 200) {
        dispatch(showToastMessage(res?.statusCode, res?.message));
        dispatch(getLicenseData(userData?.school_id)).then(async (res) => {
          if (res?.statusCode === 200) {
            // setLicenseData(res);
            setLicenseDetailsData(res?.orderList);
            //   setAllLiscenseData(res);
            setLoading(false);
          }
        });
        // navigate(`/dashboard/schools`)
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if(userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true))
          }
          navigate("/login", { replace: true });
          dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
        });
      }else {
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }

      setLicenseNumber("");

      // }
    });
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading">Licenses</div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/schools">Account</Link> {" > "}
        Licenses
      </div>

      <div className="licensceMain">
        <div className="inputBox f1 ">
          <label className="inputLabel">Enter no. of additional licenses  <span className="labelReqField">*</span></label>
          <br />
          <input
            type="text"
            //   min="0"
            // step="1"
            name="licenseNumber"
            className={`input inputWidth mb0 ${
              errors.licenseNumber ? "errorInput" : ""
            }`}
            id="licenseNumber"
            placeholder="License Number"
            value={licenseNumber}
            onChange={handleChange}
          ></input>
          {errors.licenseNumber && (
            <div className="error" style ={{ margin: "0rem 1rem 0rem" }}>{errors.licenseNumber}</div>
          )}
        </div>

        <Button
          className="btn gradientButtonBlue ml-1 mt-1 mb-1"
          onClick={handleOrderClick}
        >
          Order Now
        </Button>
      </div>

      <div className="orderdiv">
        <TableContainer component={Paper}>
          <div className="printTableHeading">Previous Orders</div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableFont" align="left">
                  Order Date
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Quantity
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenseData?.length > 0 ? (
                licenseData?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {moment(row?.created_at).format("MM/DD/YYYY ")}
                    </TableCell>
                    <TableCell align="left">{row?.licenses}</TableCell>
                    <TableCell align="left">
                      {row?.status === 0 ? "Pending" : "Completed"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    colspan={3}
                    style={{ height: "10rem" }}
                  >
                    {"There are no previous orders."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <CustomToastify />
    </Card>
  );
};

export default PurchaseLicenses;
