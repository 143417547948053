import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import Icon from "@mui/material/Icon";
import "../../styles/main.css";
import "../../styles/components/myTeachers.css";
import { FaTrash, FaEdit, FaEye, FaClipboardList } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
// import AddEditTeacher from "./AddEditTeacher";
import { useNavigate, Link } from "react-router-dom";
import {
  getTeacherData,
  deleteTeacherData,
} from "../../redux/Teachers/TeachersAction";
import { tableIcons, options, localization } from "../../utils/TableMui";
import {
  getStudentDataByClass,
  deleteStudentDataFromClass,
  setClassroomName,
  setAssessClass,
  setAssessStudent,
  setAssessReport,
} from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  setLastVisited,
  setClassroomId,
  setSecondLastVisited,
} from "../../redux/Header/HeaderAction";
import moment from "moment";
import CustomDialog from "../../utils/CustomDialog";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const StudentList = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const navigate = useNavigate();
  const [responseCode, setResponseCode] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const tableRef = useRef();

  const userData = useSelector((state) => state.loginReducer?.userData);
  const classroomId = useSelector((state) => state.headerReducer?.classroomId);
  const assessStudent = useSelector(
    (state) => state.studentReducer?.assessStudent
  );
  const assessClass = useSelector((state) => state.studentReducer?.assessClass);

  useEffect(() => {
    userData?.school_id !== undefined && getStudentData();
    dispatch(setLastVisited("StudentList"));
    dispatch(setSecondLastVisited(""));
  }, [userData]);

  const getStudentData = () => {
    const regex = /\/schools\/(\d+)\/classrooms\/(\d+)/;
    const match = window.location.href.match(regex);
    dispatch(setClassroomId(match[2]));
    dispatch(getStudentDataByClass(userData?.school_id, match[2])).then(
      async (res) => {
        if (res?.statusCode === 200) {
          const updatedStudentDetails = res?.studentsPrintableDetails?.map(
            (user) => ({
              ...user,
              updatedCurrentSubject:
                user?.subject === null ? "None" : user.subject,
              updatedUnit: user?.unit === null ? "None" : user.unit,
              updatedStatus:
                user.activityStatus === false ? "Inactive" : "Active",
              updatedLesson:
                user.lessonName === null ? "None" : user.lessonName,
              updatedCreatedDate:
                user.createdDate === null
                  ? "N/A"
                  : moment(user.createdDate).format("MM/DD/YYYY HH:mm A") ===
                    "Invalid date"
                  ? ""
                  : moment(user.createdDate).format("MM/DD/YYYY HH:mm A"),
            })
          );

          setAllData(res);
          // setData(res?.studentsPrintableDetails);
          setData(updatedStudentDetails);
          setLoading(false);
          dispatch(setClassroomName(res?.classRoomName));
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
      }
    );
  };
  const customFilterAndSearch = (term, rowData) => {
    let termArray = [];
    const terms = term.toLowerCase();
    termArray.push(terms);
    // Check if any of the words in the term matches lessonGroup or description
    return termArray.some((t) => rowData.updatedStatus.toLowerCase() == t);
  };
  const columns = [
    {
      title: "Name",
      field: "studentName",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.studentName}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },

    {
      title: "Status",
      field: "updatedStatus",
      render: (rowData) => (
        <div>
          <span
            className={
              rowData.activityStatus === false
                ? "statusInactive"
                : "statusActive"
            }
          ></span>
          <span style={{ marginLeft: 5 }}>
            {rowData.activityStatus === false ? "Inactive" : "Active"}
          </span>
        </div>
      ),
      sorting: data?.length > 0,
      customFilterAndSearch: (term, rowData) =>
        customFilterAndSearch(term, rowData),
      // generateFlagText(rowData.updatedStatus),
    },
    {
      title: "Active Lesson",
      field: "updatedLesson",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            {/* {rowData.lessonName === null ? "None" : rowData.lessonName} */}
            {rowData?.updatedLesson}
          </span>
        </div>
      ),
      sorting: data?.length > 0,
    },
    {
      title: "Last Visited",
      field: "updatedCreatedDate",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            {rowData.createdDate === null
              ? "N/A"
              : moment(rowData.createdDate).format("MM/DD/YYYY HH:mm A") ===
                "Invalid date"
              ? ""
              : moment(rowData.createdDate).format("MM/DD/YYYY HH:mm A")}
          </span>
        </div>
      ),
      sorting: data?.length > 0,
    },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          {/* <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData.id}/mute?muted=true`}
              > */}
          <Tooltip title={"Remove from Class"}>
            <div
              className="btn-action"
              onClick={() =>
                handleDelete(
                  rowData.studentId,
                  rowData.studentName,
                  allData.classRoomName
                )
              }
            >
              <FaTrash className="btn-icon btn-orange-casablanca" />
            </div>
          </Tooltip>
          {/* </Link> */}
          <Tooltip title={"Edit"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${rowData.studentId}/edit`}
            >
              <div className="btn-action">
                <FaEdit className="btn-icon btn-purple-wisteriaa" />
              </div>
            </Link>
          </Tooltip>

          <Tooltip title={"View Student"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${rowData.studentId}`}
            >
              <div className="btn-action">
                <FaEye className="btn-icon   btn-purple-wisteria" />
              </div>
            </Link>
          </Tooltip>
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      width: 100,
      sorting: false,
      // maxWidth: 100,
    },
  ];

  const teacherColumns = [
    {
      title: "Name",
      field: "studentName",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.studentName}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },

    {
      title: "Current Subject",
      field: "updatedCurrentSubject",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.updatedCurrentSubject}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },
    {
      title: "Current unit",
      field: "updatedUnit",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.updatedUnit}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },

    {
      title: "Status",
      field: "updatedStatus",
      render: (rowData) => (
        <div>
          <span
            className={
              rowData.activityStatus === false
                ? "statusInactive"
                : "statusActive"
            }
          ></span>
          <span style={{ marginLeft: 5 }}>
            {rowData.activityStatus === false ? "Inactive" : "Active"}
          </span>
        </div>
      ),
      sorting: data?.length > 0,
      customFilterAndSearch: (term, rowData) =>
        customFilterAndSearch(term, rowData),
    },
    {
      title: "Active Lesson",
      field: "updatedLesson",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            {rowData.lessonName === null ? "None" : rowData.lessonName}
          </span>
        </div>
      ),
      sorting: data?.length > 0,
    },
    {
      title: "Last Visited",
      field: "updatedCreatedDate",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            {rowData.createdDate === null
              ? "N/A"
              : moment(rowData.createdDate).format("MM/DD/YYYY HH:mm A") ===
                "Invalid date"
              ? ""
              : moment(rowData.createdDate).format("MM/DD/YYYY HH:mm A")}
          </span>
        </div>
      ),
      sorting: data?.length > 0,
    },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          {rowData?.roster_name !== "edlink" && (
            <Tooltip title={"Remove from Class"}>
              <div
                className="btn-action"
                onClick={() =>
                  handleDelete(
                    rowData.studentId,
                    rowData.studentName,
                    allData.classRoomName
                  )
                }
              >
                <FaTrash className="btn-icon btn-orange-casablanca" />
              </div>
            </Tooltip>
          )}
          <Tooltip title={"Edit"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${rowData.studentId}/edit`}
            >
              <div className="btn-action">
                <FaEdit className="btn-icon btn-purple-wisteriaa" />
              </div>
            </Link>
          </Tooltip>

          <Tooltip title={"View Student"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${rowData.studentId}`}
            >
              <div className="btn-action">
                <FaEye className="btn-icon   btn-purple-wisteria" />
              </div>
            </Link>
          </Tooltip>

          {userData?.userType !== "student" && (
            <Tooltip title={"Assess"}>
              {/* <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}/students/${rowData.studentId}/assesss_student`}
              > */}
              <div
                className="btn-action"
                onClick={() => {
                  handleAssessStudent(rowData.studentId);
                }}
              >
                <FaClipboardList className="btn-icon   btn-assess-wisteria" />
              </div>
              {/* </Link> */}
            </Tooltip>
          )}
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      width: 100,
      sorting: false,
      // maxWidth: 100,
    },
  ];

  const handleAssessStudent = (id) => {
    // alert(id);
    dispatch(setClassroomName(allData?.classRoomName));
    dispatch(setAssessStudent(true));
    dispatch(setAssessClass(false));
    dispatch(setAssessReport(null));
    navigate(
      `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}/students/${id}/assesss_student`
    );
  };

  const handleAssessClass = () => {
    dispatch(setClassroomName(allData?.classRoomName));
    dispatch(setAssessClass(true));
    dispatch(setAssessStudent(false));
    navigate(
      `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}/assessyourclass`
    );
  };

  // const handleDelete = (id, name, classRoomName) => {
  //   setIsLoading(true);
  //   const answer = window.confirm(
  //     "Are you sure you want to remove " +
  //       name +
  //       " from " +
  //       classRoomName +
  //       " ?"
  //     // "Do you want to delete " + name + " ?"
  //   );
  //   if (answer) {
  //     dispatch(deleteStudentDataFromClass(classroomId, id)).then(
  //       async (res) => {
  //         if (res?.statusCode === 200) {
  //           dispatch(
  //             getStudentDataByClass(userData?.school_id, classroomId)
  //           ).then(async (res) => {
  //             if (res?.statusCode === 200) {
  //               setAllData(res);
  //               setData(res?.studentsPrintableDetails);
  //               setLoading(false);
  //             }
  //           });
  //         }
  //         dispatch(showToastMessage(res?.statusCode, res?.message));
  //       }
  //     );
  //   }
  //   // Handle delete logic here
  // };
  const handleDelete = (id, name, classRoomName) => {
    setDeleteId(id);
    setMessage(
      "Are you sure you want to remove " +
        name +
        " from " +
        classRoomName +
        " ?"
    );
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    dispatch(deleteStudentDataFromClass(classroomId, deleteId)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          dispatch(
            getStudentDataByClass(userData?.school_id, classroomId)
          ).then(async (res) => {
            if (res?.statusCode === 200) {
              setAllData(res);
              setData(res?.studentsPrintableDetails);
              setLoading(false);
            }
          });
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    );
    setIsDialogOpen(false);
  };

  const tableTitle = <div className="TableTitle">Students</div>;

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleViewClassReport = () => {
    dispatch(setAssessReport("class"));
    navigate(
      `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}/class_assess_report`
    );
    dispatch(setAssessClass(true));
    dispatch(setAssessStudent(false));
  };

  return (
    <>
      {/* <DashboardLayout /> */}
      <Card sx={{ m: 1, p: 4 }}>
        {loading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}

        <div ref={tableRef}></div>
        <div className="itemSpace">
          <div className="pageHeading">{allData?.classRoomName}</div>
          <div className="itemEnd ">
            <div className="itemEnd ">
              <div className=" m1">
                <Button
                  className={
                    !(allData?.lessonDataList?.length > 0)
                      ? "btn gradientButtonGray"
                      : "btn gradientButtonPink"
                  }
                  onClick={handleViewClassReport}
                  disabled={!(allData?.lessonDataList?.length > 0)}
                >
                  View class Report
                </Button>
              </div>
            </div>
            {userData?.userType !== "student" && (
              <div className=" m1">
                {/* <Link
                  to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}/assessyourclass`}
                > */}
                <Button
                  className="btn gradientButtonPink"
                  onClick={handleAssessClass}
                >
                  Assess your Class
                </Button>
                {/* </Link> */}
              </div>
            )}

            <div className=" m1">
              <Link
                to={`/dashboard/classrooms/${classroomId}/students_enrollments/new`}
              >
                <Button className="btn gradientButtonPink">Add Student</Button>
              </Link>
            </div>
            <div className=" m1">
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}/printable/s`}
              >
                <Button className="btn gradientButtonPink">
                  Printable List
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="pageBreadCrumb">
          <Link to="/dashboard/home">Home</Link> {" > "}
          <Link to="/dashboard/classes">My Classes</Link> {" > "}
          Student List
        </div>

        <div
          className="mt-2 mt-md-0 d-md-block collapse"
          id="classroom-metrics"
        >
          <dl className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col mb-4">
              <div className="card">
                <dt className="card-body text-center h2 mb-0">
                  {userData?.school_code}
                </dt>
                <dd className="card-footer text-center mb-0">School Code</dd>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card">
                <dt className="card-body text-center h2 mb-0">
                  {data?.length === undefined ? 0 : data?.length}
                </dt>
                <dd className="card-footer text-center mb-0">Students</dd>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card">
                <dt className="card-body text-center h2 mb-0">
                  {data?.length === undefined ? 0 : allData?.activeCount}
                </dt>
                <dd className="card-footer text-center mb-0">
                  Active Students
                </dd>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card">
                <dt className="card-body text-center h2 mb-0">
                  {data?.length === undefined
                    ? 0
                    : data?.length - allData?.activeCount}
                </dt>
                <dd className="card-footer text-center mb-0">
                  Inactive Students
                </dd>
              </div>
            </div>
          </dl>
        </div>

        <div className="MuiTable">
          <MaterialTable
            title={tableTitle}
            className="myTable"
            icons={tableIcons}
            data={data}
            //  columns={columns}
            columns={
              userData?.userType !== "student" ? teacherColumns : columns
            }
            options={{
              ...options,
              pageSizeOptions: data?.length > 0 ? [5, 10, 20, 50] : [10],
            }}
            onChangePage={handlePageChange}
            // isLoading={loading}
            localization={localization}
          />
        </div>

        <CustomToastify />
        <CustomDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleDialogConfirm}
          title="Delete Student"
          content={message}
          learnosity={false}
        />
      </Card>
    </>
  );
};

export default StudentList;
