import React, { useState, useEffect } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import "../../styles/main.css";
import "../../styles/components/home.css";
import "../../styles/components/studentreport.css";
import Card from "@material-ui/core/Card";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  getStudentReport,
  updateSound,
  setCategoryName,
  setEpisodeName,
} from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { setSecondLastVisited } from "../../redux/Header/HeaderAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#ededed",
    },
  })
);

const ReportProgress = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [muted, setMuted] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const lastVisited = useSelector((state) => state.headerReducer?.lastVisited);
  const classroomId = useSelector((state) => state.headerReducer?.classroomId);
  const categoryName = useSelector(
    (state) => state.studentReducer?.categoryName
  );
  const standardReport = useSelector(
    (state) => state.studentReducer?.standardReport
  );
  const episodeName = useSelector((state) => state.studentReducer?.episodeName);

  useEffect(() => {
    const url = window.location.href;

    const regex = /\/students\/(\d+)\/lessonreports/;

    const match = url.match(regex);
    // setSchoolId(match[1]);
    setStudentId(match[1]);

   
  }, []);

  const handleView = (lessonname, episodeid) => {
    dispatch(setEpisodeName(lessonname));
    navigate(`/dashboard/students/${studentId}/lessonreports/${episodeid}`);
  };

  const handleMathView = (lessonname, episodeid) => {
    dispatch(setEpisodeName(lessonname));
    navigate(`/dashboard/students/${studentId}/lessonreports/${episodeid}`);
  };

  return (
    <Card>
      <div className="pageHeading">{categoryName} Progress</div>
      {userData?.userType == "student" ? (
        <div className="pageBreadCrumb">
          <Link to="/dashboard/lessons">Lessons</Link> {" > "}
          <Link to="/dashboard/progress">Progress</Link> {" > "}
          {categoryName} Progress
        </div>
      ) : (
        <>
          <div
            className="pageBreadCrumb"
            style={{
              display: lastVisited === "StudentList" ? "block" : "none",
            }}
          >
            <Link to="/dashboard/home">Home</Link> {" > "}
            <Link to="/dashboard/classes">My Classes</Link> {" > "}
            <Link
              to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`}
            >
              Student List
            </Link>{" "}
            {" > "}
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${studentId}`}
            >
              Student Activity Report by Lesson{" "}
            </Link>
            {" > "}
            {categoryName} Progress
          </div>
          <div
            className="pageBreadCrumb"
            style={{
              display: lastVisited === "StudentDashBoard" ? "block" : "none",
            }}
          >
            <Link to="/dashboard/home">Home</Link> {" > "}
            <Link to="/dashboard/myStudentsReports">My Students</Link> {" > "}
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${studentId}`}
            >
              Student Activity Report by Lesson{" "}
            </Link>{" "}
            {" > "}
            {categoryName} Progress
          </div>
        </>
      )}

      <div className="ProgressMain">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableFont" align="left">
                  Name
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Completion Percentage
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Learning Outcome
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            {categoryName === "Literacy" ? (
              <TableBody>
                {standardReport?.categoryEpisodeMap?.Literacy?.length > 0 ? (
                  standardReport?.categoryEpisodeMap?.Literacy.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        <div
                          className="colMain"
                          style={{ alignItems: "center" }}
                        >
                          <div className="colLeft">
                            <img
                              src={row.imgUrl}
                              alt="Teacher Logo"
                              className="reportLogo"
                            />
                          </div>

                          <div className="colRight">{row.name}</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {parseInt(row.percentage)}%
                      </TableCell>
                      <TableCell align="left">{row.learningOutcome}</TableCell>
                      <TableCell align="left">
                        {/* <Link
                to={`/dashboard/students/${studentId}/lessonreports/${row.episodeId}`}
              > */}
                        <Button
                          className="gradientButtonBlue"
                          onClick={() => handleView(row?.name, row?.episodeId)}
                        >
                          {" "}
                          view{" "}
                        </Button>
                        {/* </Link> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      colspan={3}
                      style={{ height: "10rem" }}
                    >
                      {"Student has not attempted any Standards Based " +
                        { categoryName } +
                        " lessons yet."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                {standardReport?.categoryEpisodeMap?.Math?.length > 0 ? (
                  standardReport?.categoryEpisodeMap?.Math.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        <div
                          className="colMain"
                          style={{ alignItems: "center" }}
                        >
                          <div className="colLeft">
                            <img
                              src={row.imgUrl}
                              alt="Teacher Logo"
                              className="reportLogo"
                            />
                          </div>

                          <div className="colRight">{row.name}</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">{row.percentage}%</TableCell>
                      <TableCell align="left">{row.learningOutcome}</TableCell>
                      <TableCell align="left">
                        {/* <Link
                                                    to={`/dashboard/students/${studentId}/lessonreports/${row.episodeId}`}
                                                > */}
                        <Button
                          className="gradientButtonBlue"
                          onClick={() =>
                            handleMathView(row?.name, row?.episodeId)
                          }
                        >
                          view{" "}
                        </Button>
                        {/* </Link> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      colspan={3}
                      style={{ height: "10rem" }}
                    >
                      {"Student has not attempted any Standards Based" +
                        { categoryName } +
                        " lessons yet."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>

      <CustomToastify />
    </Card>
  );
};

export default ReportProgress;
