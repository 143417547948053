import {
  IS_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  GET_USER_DATA,
  SET_WELCOME_PAGE_DATA,
  EMAIL_FORGOT_PASSWORD,
  SET_IS_EDUCATOR,
  TOKEN_STATUS_FOR_RESET_PASSWORD,
  SET_RESET_PASSWORD,
  SIGN_IN_WITH_GOOGLE,
  SET_CHANGE_PASSWORD,
  GET_LOGOUT_SUCCESS,
  GET_SSO_LOGIN,
  SET_SSO_LOGOUT
} from "./LoginAction";

const initialState = {
  isLogin: false,
  error: null,
  token: "",
  loading: false,
  userData: "",
  data: null,
  isEducator: null,
  changePassword: "",
  logout: "",
  addLinkLogout: false
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOGIN_SUCCESS:
      return (state = {
        ...state,
        error: null,
        loading: action.loading,
        token: action.token,
      });
    case GET_LOGIN_FAILURE:
      return (state = {
        ...state,
        error: action.error,
        loading: action.loading,
      });
    case GET_USER_DATA:
      return (state = {
        ...state,
        userData: action.data,
      });
    case IS_LOGIN:
      return (state = {
        ...state,
        isLogin: action.data,
      });
    case SET_WELCOME_PAGE_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case EMAIL_FORGOT_PASSWORD:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_IS_EDUCATOR:
      return (state = {
        ...state,
        isEducator: action.data,
      });
    case TOKEN_STATUS_FOR_RESET_PASSWORD:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_RESET_PASSWORD:
      return (state = {
        ...state,
        data: action.data,
      });
    case SIGN_IN_WITH_GOOGLE:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_CHANGE_PASSWORD:
      return (state = {
        ...state,
        changePassword: action.data,
      });
    case GET_LOGOUT_SUCCESS:
      return (state = {
        ...state,
        logout: action.data,
      });
    case GET_SSO_LOGIN:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_SSO_LOGOUT:
      return (state = {
        ...state,
        addLinkLogout: action.data,
      });

    default:
      return state;
  }
}

export default loginReducer;
