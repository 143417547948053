import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import "../../styles/main.css";
import "../../styles/components/PrintStudent.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { getStudentDataByClass } from "../../redux/Student/StudentAction";
import Grid from "@material-ui/core/Grid";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const PrintStudents = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [noOfTeachers, setNoOfTeachers] = useState(0);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [classroomId, setClassroomId] = useState(0);
  const [isStudent, setIsStudent] = useState(0);
  const [currentDate, setCurrentDate] = useState('');
  const userData = useSelector((state) => state.loginReducer?.userData);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page {
        size: auto;
        margin: 11mm 17mm 17mm 17mm;

        @top-right-corner {
            content: "Page " counter(page);
          }
}`,
    documentTitle: "KneoWorld"  

  });

  useEffect(() => {

    // var date = new Date().getDate(); //Current Date
    // var month = new Date().getMonth() + 1; //Current Month
    // var year = new Date().getFullYear(); //Current Year
    // var hours = new Date().getHours(); //Current Hours
    // var min = new Date().getMinutes(); //Current Minutes
    // var sec = new Date().getSeconds(); //Current Seconds
    // var ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // min = min < 10 ? '0'+min : min;
    // var strTime = hours + ':' + min + ' ' + ampm;
    // setCurrentDate(
    //   month + '/' + date + '/' + year 
    //   + ' ' + strTime
    // );

    const url = window.location.pathname;
    setIsStudent(url.endsWith("/s"));
    const regex = /\/schools\/(\d+)\/classrooms\/(\d+)/;
    const match = window.location.href.match(regex);
    setClassroomId(match[2]);
    dispatch(getStudentDataByClass(userData?.school_id, match[2])).then(
      async (res) => {
        if (res?.statusCode === 200) {
          setData(res?.studentsPrintableDetails);
          setAllData(res);
          setLoading(false);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
            navigate("/login", { replace: true });
            dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
          });
        }
      }
    );
  }, [userData]);

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading">Print List</div>

        {/* <div className="itemEnd "> */}

        <div className="m1">
          <Button onClick={handlePrint} className="btn gradientButtonPink">
            PRINT LIST
          </Button>
        </div>
        {/* </div> */}
      </div>

      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/classes">My Classes</Link> {" > "}
        {isStudent && (
          <Link
            to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`}
          >
            Student List
          </Link>
        )}{" "}
        {isStudent && " > "}
        Print Student List
      </div>

      <div className="printMain" ref={componentRef}>
      <div className="print-only">
     
      

      {/* <div className="printHeader"   ref={componentRef}>

      <Grid container spacing={10}  >
      <Grid item  className="">
      {currentDate}
      </Grid>
      
      <Grid item className="printTitle">
      KneoWorld
      </Grid>
      </Grid>

        </div> */}
 
        <TableContainer component={Paper}>
          <div className="printTableHeading">Students of {allData?.classRoomName}</div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableFont" align="left">
                  Name
                </TableCell>
                <TableCell className="tableFont" align="left">
                  School Code
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Login Code
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {row?.studentName}
                    </TableCell>
                    <TableCell align="left">{row?.schoolCode}</TableCell>
                    <TableCell align="left">{row?.loginCode}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    colspan={3}
                    style={{ height: "10rem" }}
                  >
                    {"There are no students enrolled in this classroom."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </div>
      <CustomToastify />
    </Card>
  );
};

export default PrintStudents;
