import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import Icon from "@mui/material/Icon";
import "../../styles/main.css";
import "../../styles/components/home.css";
import CustomSnackBar from "../../utils/Snackbar";
import { tableIcons, options } from "../../utils/TableMui";
import "../../styles/components/myClasses.css";
import {
  FaTrash,
  FaFile,
  FaEdit,
  FaEye,
  FaVolumeUp,
  FaVolumeOff,
} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentDashboard,
  deleteStudent,
  getStudentDashboardTeacher,
} from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  setLastVisited,
  setSecondLastVisited,
} from "../../redux/Header/HeaderAction";
import CustomDialog from "../../utils/CustomDialog";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const Students = (props) => {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [message, setMessage] = useState("");
  const tableRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    dispatch(setLastVisited("StudentDashBoard"));
    dispatch(setSecondLastVisited(""));
    if (userData?.school_id !== undefined) {
      userData?.userType === "school_admin"
        ? dispatch(getStudentDashboard(userData?.school_id)).then(
            async (res) => {
              if (res?.statusCode === 200) {
                const updatedStudentDetails = res?.studentDashboardDTO?.map(
                  (user) => ({
                    studentId: user.studentId,
                    studentName: user.studentName,
                    schoolCode: user.schoolCode,
                    loginCode: user.loginCode,
                    lessonGroupName: user.lessonGroupName === null ? "None" : user.lessonGroupName,
                    courseName: user.courseName,
                    studentUpdatedAt: user.studentUpdatedAt,
                    roster_name: user.roster_name
                  })
                );
                setAllData(res);
                setData(updatedStudentDetails);
                setLoading(false);
              } else if (res?.statusCode === 403 || res?.statusCode === 401) {
                dispatch(getLogout()).then(async (res) => {
                  if(userData?.roster_name === "edlink") {
                    dispatch(setSSOLogout(true))
                  }
                  navigate("/login", { replace: true });
                  dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
                });
              }
            }
          )
        : dispatch(
            getStudentDashboardTeacher(userData?.school_id, userData?.id)
          ).then(async (res) => {
            if (res?.statusCode === 200) {
              const updatedStudentDetails = res?.studentDashboardDTO?.map(
                (user) => ({
                  studentId: user.studentId,
                  studentName: user.studentName,
                  schoolCode: user.schoolCode,
                  loginCode: user.loginCode,
                  lessonGroupName: user.lessonGroupName === null ? "None" : user.lessonGroupName,
                  courseName: user.courseName,
                  studentUpdatedAt: user.studentUpdatedAt,
                  roster_name: user.roster_name
                })
              );
              setAllData(res);
              setData(updatedStudentDetails);
              setLoading(false);
            } else if (res?.statusCode === 403 || res?.statusCode === 401) {
              dispatch(getLogout()).then(async (res) => {
                if(userData?.roster_name === "edlink") {
                  dispatch(setSSOLogout(true))
                }
                navigate("/login", { replace: true });
                dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
              });
            }
          });
    }
  }, [userData]);

  const tableTitle = (
    <div className="TableTitle">No. of students: {data?.length}</div>
  );
  const columns = [
    {
      title: "Name",
      field: "studentName",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData?.studentName}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },
    // {
    //   title: "School Code",
    //   field: "schoolCode",
    //   render: (rowData) => (
    //     <div>
    //       <span style={{ marginLeft: 5 }}>{rowData?.schoolCode}</span>
    //     </div>
    //   ),
    //   // sorting: data?.length > 0,
    //   sorting: false,
    //   filtering: false,
    // },
    // {
    //   title: "Student Id",
    //   field: "loginCode",
    //   render: (rowData) => (
    //     <div>
    //       <span style={{ marginLeft: 5 }}>{rowData?.loginCode}</span>
    //     </div>
    //   ),
    //   //   filtering: false,
    // },
    {
      title: "Lesson",
      field: "lessonGroupName",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData?.lessonGroupName}</span>
        </div>
      ),
      sorting: data?.length > 0,
      //   filtering: false,
    },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          {/* {userData?.userType === "teacher" && (
            <>
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData?.id}/mute?muted=true`}
              >
                <div className="btn-action">
                  <FaVolumeOff className="btn-icon   btn-purple-wisteria" />
                  <span>Episode sound off</span>
                </div>
              </Link>
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData?.id}/mute?muted=false`}
              >
                <div className="btn-action">
                  <FaVolumeUp className="btn-icon btn-blue-java" />
                  <span>Episode sound on</span>
                </div>
              </Link>
            </>
          )} */}
          <Tooltip title={"Report"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${rowData?.studentId}`}
            >
              <div className="btn-action">
                <FaFile className="btn-icon btn-orange-casablanca" />
              </div>
            </Link>
          </Tooltip>

          <Tooltip title={"Edit"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${rowData?.studentId}/edit`}
            >
              <div className="btn-action">
                <FaEdit className="btn-icon btn-purple-wisteria" />
              </div>
            </Link>
          </Tooltip>

          {userData?.userType === "school_admin" && 
          rowData?.roster_name !== "edlink" &&
          (
            <Tooltip title={"Delete"}>
              <div className="btn-action">
                <FaTrash
                  className="btn-icon .btn-blue-wisteria"
                  onClick={() =>
                    handleDelete(rowData?.studentId, rowData?.studentName)
                  }
                />
              </div>
            </Tooltip>
          )}
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      width: 100,
      sorting: false,
    },
  ];

  // const handleDelete = (id, name) => {
  //   const answer = window.confirm(
  //     // "Do you want to delete " + name + " ?"
  //     "Deleting this student will close the account and erase all their data. Are you sure?"
  //   );
  //   if (answer) {
  //     dispatch(deleteStudent(id)).then(async (res) => {
  //       if (res?.statusCode === 200) {
  //         dispatch(getStudentDashboard(userData?.school_id)).then(
  //           async (res) => {
  //             if (res?.statusCode === 200) {
  //               setAllData(res);
  //               setData(res?.studentDashboardDTO);
  //               setLoading(false);
  //             }
  //           }
  //         );
  //         setErrorOpen(true);
  //         dispatch(showToastMessage(res?.statusCode, res?.message));
  //       }
  //     });
  //   }
  //   // Handle delete logic here
  // };
  const handleDelete = (id, name) => {
    setDeleteId(id);
    setMessage(
      "Deleting " +
        name +
        " will close the account and erase all their data. Are you sure?"
    );
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    dispatch(deleteStudent(deleteId)).then(async (res) => {
      if (res?.statusCode === 200) {
        dispatch(getStudentDashboard(userData?.school_id)).then(async (res) => {
          if (res?.statusCode === 200) {
            setAllData(res);
            setData(res?.studentDashboardDTO);
            setLoading(false);
          }
        });
        setErrorOpen(true);
        dispatch(showToastMessage(res?.statusCode, res?.message));
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if(userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true))
          }
          navigate("/login", { replace: true });
          dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
        });
      }
    });
    setIsDialogOpen(false);
  };

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div ref={tableRef}></div>
      <div className="itemSpace">
        <div className="pageHeading">Students</div>

        <div className="itemEnd ">
          <div className=" m1">
            {userData?.userType !== "teacher" && (
              <Link to={`/dashboard/schools/${userData?.school_id}/students`}>
                <Button className="btn gradientButtonPink btnResponsive">
                  ADD STUDENT
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        My Students
      </div>
      <div className="MuiTable">
        <MaterialTable
          title={tableTitle}
          className="myTable"
          icons={tableIcons}
          data={data}
          columns={columns}
          options={options}
          onChangePage={handlePageChange}
          // isLoading={loading}
        />
      </div>
      <CustomToastify />
      <CustomDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Delete Student"
        content={message}
        learnosity={false}
      />
    </Card>
  );
};

export default Students;
