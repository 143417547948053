import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import Icon from "@mui/material/Icon";
import "../../styles/main.css";
import "../../styles/components/myTeachers.css";
import { FaTrash, FaEdit, FaEye, FaClipboardList } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
// import AddEditTeacher from "./AddEditTeacher";
import { useNavigate, Link } from "react-router-dom";
import {
  getTeacherData,
  deleteTeacherData,
} from "../../redux/Teachers/TeachersAction";
import { tableIcons, options, localization } from "../../utils/TableMui";
import {
  getAssessYourClass,
  getAccessReportForStudents,
  setAccessReportForStudentsApiSuccess,
  setLessonGroupID,
} from "../../redux/Classes/ClassesAction";
import {
  learnosityAssessment,
  getAssessStudent,
  learnosityTestProgress,
  learnosityUpdatePrePostStatus,
  setAssessReport,
} from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  setLastVisited,
  setClassroomId,
  setSecondLastVisited,
} from "../../redux/Header/HeaderAction";
import moment from "moment";
import { set } from "date-fns";
import axios from "axios";
import { BASE_URL } from "../../redux/baseUrl/ApiConstant";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
import { FE_URL } from "../../redux/baseUrl/ApiConstant";

const AccessYourClass = (props) => {
  const [loading, setLoading] = useState(true);
  const [learnosityLaunchBtn, setLearnosityLaunchBtn] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [learnosityPreData, setLearnosityPreData] = useState([]);
  const [learnosityPreAssementData, setLearnosityPreAssementData] = useState(
    []
  );
  const [learnosityPostAssementData, setLearnosityPostAssementData] = useState(
    []
  );
  const [learnosityPostData, setLearnosityPostData] = useState([]);
  const [preClickBtn, setPreClickBtn] = useState(false);
  const [postClickBtn, setPostClickBtn] = useState(false);
  const [preClickName, setPreClickName] = useState("");
  const navigate = useNavigate();
  // const [classroomId, setClassroomId] = useState(0);

  const [responseCode, setResponseCode] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allBtnClick, setAllBtnClick] = useState(true);
  const [onlyBtnClick, setOnlyBtnClick] = useState(false);
  const [startBtnClick, setStartBtnClick] = useState(false);
  const [flag, setFlag] = useState(0);
  const [schoolId, setSchoolId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [onlyClickData, setOnlyClickData] = useState([]);
  const [allClickData, setAllClickData] = useState([]);
  const [studentId, setStudentId] = useState([]);
  const [studentOnlyClickData, setStudentOnlyClickData] = useState([]);
  const [studentAllClickData, setStudentAllClickData] = useState([]);
  const [stuData, setStuData] = useState([]);
  const [postId, setPostId] = useState([]);
  const [allClassData, setAllClassData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const tableRef = useRef();

  const userData = useSelector((state) => state.loginReducer?.userData);
  const classroomId = useSelector((state) => state.headerReducer?.classroomId);
  const testProgress = useSelector(
    (state) => state.studentReducer?.testProgress
  );

  const assessStudent = useSelector(
    (state) => state.studentReducer?.assessStudent
  );
  const assessClass = useSelector((state) => state.studentReducer?.assessClass);
  const classroomName = useSelector(
    (state) => state.studentReducer?.classroomName
  );
  const studentReport = useSelector(
    (state) => state.studentReducer?.studentReport
  );

  useEffect(() => {
    setAccessReportForStudentsApiSuccess("1");
    const assessmyClass = window.location.href;

    const containsassess_student =
      window.location.href.includes("assesss_student");
    // containsassess_student ? setIsEditMode(true) : setIsEditMode(false);
    const regex = props.reportStudent
      ? /\/schools\/(\d+)\/students\/(\d+)/
      : containsassess_student
      ? /\/schools\/(\d+)\/classrooms\/(\d+)\/students\/(\d+)\/assesss_student/
      : /\/schools\/(\d+)\/classrooms\/(\d+)\/assessyourclass/;
    const match = window.location.href.match(regex);
    if (!props.reportStudent) {
      if (containsassess_student) {
        setSchoolId(match[1]);
        setClassId(match[2]);
        setStudentId(match[3]);
      } else {
        setSchoolId(match[1]);
        setClassId(match[2]);
      }
    } else {
      if (userData.userType !== "student") {
        setSchoolId(match[1]);
        setStudentId(match[2]);
        setClassId(props?.classId);
      } else {
        setSchoolId(userData.school_id);
        setStudentId(userData.id);
        setClassId(props?.classId);
      }
    }
    if (localStorage.getItem("LearnosityPre")) {
      doAjaxPre(
        JSON.parse(localStorage.getItem("LearnosityPre")),
        assessClass,
        match[1],
        assessClass ? match[2] : match[3]
      );
      localStorage.removeItem("LearnosityPre");
    } else if (localStorage.getItem("LearnosityPost")) {
      doAjaxPre(
        JSON.parse(localStorage.getItem("LearnosityPost")),
        assessClass,
        match[1],
        assessClass ? match[2] : match[3]
      );
      localStorage.removeItem("LearnosityPost");
    } else {
      props.reportStudent
        ? userData.userType !== "student"
          ? StudentApiCall(match[1], match[2])
          : StudentApiCall(userData.school_id, userData.id)
        : assessClass
        ? classApiCall(match[1], match[2])
        : StudentApiCall(match[1], match[3]);
    }
  }, [userData]);

  useEffect(() => {
    if (props.reportStudent) {
      setClassId(studentReport?.classId);
    }
  }, [studentReport]);

  const classApiCall = (idSchool, idClass) => {
    setLoading(true);
    dispatch(getAssessYourClass(idSchool, idClass, 0)).then(async (res) => {
      if (res?.statusCode === 200) {
        setAllData(res);
        // setAllClickData(res?.studentDataList);
        let classAccessArray = [];
        res?.studentDataList.forEach((value) => {
          classAccessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            post_status_value:
              value.lrn_pre_session_id === null &&
              value.pre_status === 0 &&
              value.lrn_post_session_id === null &&
              value.post_status === 2
                ? "Completed"
                : value.pre_status === 2
                ? value.lrn_reference_id === null
                  ? "N/A"
                  : value.post_status === 0
                  ? "Start"
                  : value.post_status === 1
                  ? "In Progress"
                  : "Completed"
                : "N/A",
            pre_status_value:
              value.lrn_reference_id === null ||
              (value.lrn_pre_session_id === null &&
                value.pre_status === 0 &&
                value.lrn_post_session_id === null &&
                value.post_status === 2)
                ? "N/A"
                : value.pre_status === 0
                ? "Start"
                : value.pre_status === 1
                ? "In Progress"
                : "Completed",
            progress:
              value.lrn_reference_id === null
                ? "N/A"
                : value.pre_status === 0
                ? "Start the pre test. " + value.progress
                : value.pre_status === 1
                ? "Pre test is in progress. " + value.progress
                : value.pre_status === 2 && value.post_status === 0
                ? "Start the post test. " + value.progress
                : value.pre_status === 2 && value.post_status === 1
                ? "Post test is in progress. " + value.progress
                : value.progress,
            subject: value.subject,
            teacher_id: value.teacher_id,
            unit: value.unit,
          });
        });
        setAllClickData(classAccessArray);
        // setLoading(false);
        let accessArray = [];
        res?.studentDataList.forEach((value) => {
          accessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            progress: value.progress,
            subject: value.subject,
            teacher_id: parseInt(value.teacher_id, 10),
            unit: value.unit,
          });
        });

        setData(accessArray);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });

    dispatch(getAssessYourClass(idSchool, idClass, 1)).then(async (res) => {
      if (res?.statusCode === 200) {
        setAllData(res);
        // setOnlyClickData(res?.studentDataList);
        let classOnlyAccessArray = [];
        res?.studentDataList.forEach((value) => {
          classOnlyAccessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            post_status_value:
              value.lrn_pre_session_id === null &&
              value.pre_status === 0 &&
              value.lrn_post_session_id === null &&
              value.post_status === 2
                ? "Completed"
                : value.pre_status === 2
                ? value.lrn_reference_id === null
                  ? "N/A"
                  : value.post_status === 0
                  ? "Start"
                  : value.post_status === 1
                  ? "In Progress"
                  : "Completed"
                : "N/A",
            pre_status_value:
              value.lrn_reference_id === null ||
              (value.lrn_pre_session_id === null &&
                value.pre_status === 0 &&
                value.lrn_post_session_id === null &&
                value.post_status === 2)
                ? "N/A"
                : value.pre_status === 0
                ? "Start"
                : value.pre_status === 1
                ? "In Progress"
                : "Completed",
            progress:
              value.lrn_reference_id === null
                ? "N/A"
                : value.pre_status === 0
                ? "Start the pre test. " + value.progress
                : value.pre_status === 1
                ? "Pre test is in progress. " + value.progress
                : value.pre_status === 2 && value.post_status === 0
                ? "Start the post test. " + value.progress
                : value.pre_status === 2 && value.post_status === 1
                ? "Post test is in progress. " + value.progress
                : value.progress,
            subject: value.subject,
            teacher_id: value.teacher_id,
            unit: value.unit,
          });
        });
        setOnlyClickData(classOnlyAccessArray);
        setLoading(false);
        let accessArray = [];
        res?.studentDataList.forEach((value) => {
          accessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            progress: value.progress,
            subject: value.subject,
            teacher_id: parseInt(value.teacher_id, 10),
            unit: value.unit,
          });
        });

        setData(accessArray);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });
  };

  const StudentApiCall = (idSchool, idStudent) => {
    setLoading(true);
    dispatch(getAssessStudent(idSchool, idStudent, 1)).then(async (res) => {
      if (res?.statusCode === 200) {
        setAllData(res);
        // setStudentAllClickData(res?.studentDataList);
        let studentAllAccessArray = [];
        res?.studentDataList.forEach((value) => {
          studentAllAccessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            post_status_value:
              value.lrn_pre_session_id === null &&
              value.pre_status === 0 &&
              value.lrn_post_session_id === null &&
              value.post_status === 2
                ? "Completed"
                : value.pre_status === 2
                ? value.lrn_reference_id === null
                  ? "N/A"
                  : value.post_status === 0
                  ? "Start"
                  : value.post_status === 1
                  ? "In Progress"
                  : "Completed"
                : "N/A",
            pre_status_value:
              value.lrn_reference_id === null ||
              (value.lrn_pre_session_id === null &&
                value.pre_status === 0 &&
                value.lrn_post_session_id === null &&
                value.post_status === 2)
                ? "N/A"
                : value.pre_status === 0
                ? "Start"
                : value.pre_status === 1
                ? "In Progress"
                : "Completed",
            progress:
              value.lrn_reference_id === null
                ? "N/A"
                : value.pre_status === 0
                ? "Start the pre test. " + value.progress
                : value.pre_status === 1
                ? "Pre test is in progress. " + value.progress
                : value.pre_status === 2 && value.post_status === 0
                ? "Start the post test. " + value.progress
                : value.pre_status === 2 && value.post_status === 1
                ? "Post test is in progress. " + value.progress
                : value.progress,
            subject: value.subject,
            teacher_id: value.teacher_id,
            unit: value.unit,
          });
        });
        setStudentAllClickData(studentAllAccessArray);
        setLoading(false);

        let accessArray = [];
        res?.studentDataList.forEach((value) => {
          accessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            progress: value.progress,
            subject: value.subject,
            teacher_id: parseInt(value.teacher_id, 10),
            unit: value.unit,
          });
        });

        setStuData(accessArray);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });

    setLoading(true);
    dispatch(getAssessStudent(idSchool, idStudent, 0)).then(async (res) => {
      if (res?.statusCode === 200) {
        setAllData(res);
        // setStudentOnlyClickData(res?.studentDataList);
        let studentOnlyAccessArray = [];
        res?.studentDataList.forEach((value) => {
          studentOnlyAccessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            post_status_value:
              value.lrn_pre_session_id === null &&
              value.pre_status === 0 &&
              value.lrn_post_session_id === null &&
              value.post_status === 2
                ? "Completed"
                : value.pre_status === 2
                ? value.lrn_reference_id === null
                  ? "N/A"
                  : value.post_status === 0
                  ? "Start"
                  : value.post_status === 1
                  ? "In Progress"
                  : "Completed"
                : "N/A",
            pre_status_value:
              value.lrn_reference_id === null ||
              (value.lrn_pre_session_id === null &&
                value.pre_status === 0 &&
                value.lrn_post_session_id === null &&
                value.post_status === 2)
                ? "N/A"
                : value.pre_status === 0
                ? "Start"
                : value.pre_status === 1
                ? "In Progress"
                : "Completed",
            progress:
              value.lrn_reference_id === null
                ? "N/A"
                : value.pre_status === 0
                ? "Start the pre test. " + value.progress
                : value.pre_status === 1
                ? "Pre test is in progress. " + value.progress
                : value.pre_status === 2 && value.post_status === 0
                ? "Start the post test. " + value.progress
                : value.pre_status === 2 && value.post_status === 1
                ? "Post test is in progress. " + value.progress
                : value.progress,
            subject: value.subject,
            teacher_id: value.teacher_id,
            unit: value.unit,
          });
        });
        setStudentOnlyClickData(studentOnlyAccessArray);

        // setLoading(false);

        let accessArray = [];
        res?.studentDataList.forEach((value) => {
          accessArray.push({
            id: value.id,
            lesson_group_id: value.lesson_group_id,
            lrn_activity_id: value.lrn_activity_id,
            lrn_post_session_id: value.lrn_post_session_id,
            lrn_pre_session_id: value.lrn_pre_session_id,
            lrn_reference_id: value.lrn_reference_id,
            name: value.name,
            post_status: value.post_status,
            pre_status: value.pre_status,
            progress: value.progress,
            subject: value.subject,
            teacher_id: parseInt(value.teacher_id, 10),
            unit: value.unit,
          });
        });
        // };

        setStuData(accessArray);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });
  };
  const reportDataStudent = useSelector(
    (state) => state.classesReducer?.studentReport
  );
  const columns = [
    {
      title: "Student Name",
      field: "name",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 0 }}>{rowData.name}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: allBtnClick
        ? allClickData?.length > 0
        : onlyClickData?.length > 0,
    },
    {
      title: "Subject",
      field: "subject",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 0 }}>{rowData.subject}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },
      sorting: allBtnClick
        ? allClickData?.length > 0
        : onlyClickData?.length > 0,
    },
    {
      title: "Unit",
      field: "unit",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 0 }}>{rowData.unit}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },
      sorting: allBtnClick
        ? allClickData?.length > 0
        : onlyClickData?.length > 0,
    },
    {
      title: "Progress",
      field: "progress",
      render: (rowData) => (
        <div>
          <span> {rowData?.progress}</span>
        </div>
      ),
      filtering: false,
      sorting: allBtnClick
        ? allClickData?.length > 0
        : onlyClickData?.length > 0,
    },
    {
      title: "Pre Test",
      field: "pre_status_value",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            {rowData.lrn_reference_id === null ? (
              "N/A"
            ) : (
              <>
                {rowData.pre_status === 0 &&
                  (rowData?.pre_status_value !== "N/A" ? (
                    <Button
                      disabled={userData?.userType !== "teacher"}
                      onClick={() =>
                        handlePreClick(
                          rowData?.id,
                          rowData?.lesson_group_id,
                          rowData?.lrn_pre_session_id,
                          rowData?.lrn_post_session_id,
                          rowData?.lrn_reference_id,
                          rowData?.name,
                          rowData?.pre_status
                        )
                      }
                    >
                      {" "}
                      {rowData?.pre_status_value + " "}
                    </Button>
                  ) : (
                    <span> {rowData?.pre_status_value} </span>
                  ))}

                {rowData?.lrn_pre_session_id !== null && (
                  <>
                    {rowData?.pre_status === 1 && (
                      <Button
                        disabled={
                          parseInt(rowData.teacher_id) !== userData.id ||
                          userData?.userType !== "teacher"
                        }
                        onClick={() =>
                          handlePreClick(
                            rowData?.id,
                            rowData?.lesson_group_id,
                            rowData?.lrn_pre_session_id,
                            rowData?.lrn_post_session_id,
                            rowData?.lrn_reference_id,
                            rowData?.name,
                            rowData?.pre_status
                          )
                        }
                      >
                        {" "}
                        {rowData?.pre_status_value + " "}
                      </Button>
                    )}
                  </>
                )}

                {rowData.pre_status === 2 && (
                  <span> {rowData?.pre_status_value}</span>
                )}
              </>
            )}
          </span>
        </div>
      ),
      filtering: false,
      sorting: allBtnClick
        ? allClickData?.length > 0
        : onlyClickData?.length > 0,
    },
    {
      title: "Post Test",
      field: "post_status_value",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            <>
              {rowData.lrn_reference_id === null ? (
                "N/A"
              ) : (
                <>
                  {rowData.post_status === 0 &&
                    (rowData?.post_status_value !== "N/A" ? (
                      <Button
                        disabled={userData?.userType !== "teacher"}
                        onClick={() =>
                          handlePostClick(
                            rowData?.id,
                            rowData?.lesson_group_id,
                            rowData?.lrn_pre_session_id,
                            rowData?.lrn_post_session_id,
                            rowData?.lrn_reference_id,
                            rowData?.name,
                            rowData?.pre_status
                          )
                        }
                      >
                        {rowData?.post_status_value + " "}
                      </Button>
                    ) : (
                      <span> {rowData?.post_status_value} </span>
                    ))}

                  {rowData?.lrn_post_session_id !== null && (
                    <>
                      {rowData?.post_status === 1 && (
                        <Button
                          disabled={
                            parseInt(rowData.teacher_id) !== userData.id ||
                            userData?.userType !== "teacher"
                          }
                          onClick={() =>
                            handlePostClick(
                              rowData?.id,
                              rowData?.lesson_group_id,
                              rowData?.lrn_pre_session_id,
                              rowData?.lrn_post_session_id,
                              rowData?.lrn_reference_id,
                              rowData?.name,
                              rowData?.pre_status
                            )
                          }
                        >
                          {rowData?.post_status_value + " "}
                        </Button>
                      )}
                    </>
                  )}

                  {rowData.post_status === 2 && (
                    <span> {rowData?.post_status_value}</span>
                  )}
                </>
              )}
            </>
          </span>
        </div>
      ),
      filtering: false,
      sorting: allBtnClick
        ? allClickData?.length > 0
        : onlyClickData?.length > 0,
    },
    {
      title: "Report",
      field: "Report",
      render: (rowData) => (
        <div>
          <Tooltip title={"View Report"}>
            {/* <Link
                to={`/dashboard/schools/${schoolId}/classrooms/${classId}/assess_report`}
              >  */}
            <Button
              className={`btn   ${
                rowData.lrn_reference_id === null ||
                (rowData.pre_status !== 2 && rowData.post_status !== 2)
                  ? "gradientButtonGray"
                  : "gradientButtonBlue"
              }`}
              disabled={
                rowData.lrn_reference_id === null ||
                (rowData.pre_status !== 2 && rowData.post_status !== 2)
              }
              onClick={() => handleReport(rowData)}
            >
              View
            </Button>
            {/* </Link>  */}
          </Tooltip>
        </div>
      ),
      filtering: false,
      sorting: false,
    },
  ];
  const handleReport = (rowData) => {
    dispatch(setLessonGroupID(rowData?.lesson_group_id));
    setTimeout(() => {
      setAccessReportForStudentsApiSuccess("1");
    }, 200);
    props.reportStudent
      ? dispatch(setAssessReport("report", studentId))
      : dispatch(setAssessReport("student"));
    var dataModel = {
      lessonId: rowData.lesson_group_id,
      postSessionId: rowData.lrn_post_session_id,
      preSessionId: rowData.lrn_pre_session_id,
      referenceId: rowData.lrn_reference_id,
      studentId: rowData.id,
      teacherId: rowData.teacher_id,
      status:
        rowData.lrn_pre_session_id === null
          ? rowData.post_status
          : rowData.post_status == 2
          ? 2
          : 0,
      type:
        rowData.lrn_pre_session_id === null && rowData.pre_status == 2
          ? "post"
          : null,
    };
    dispatch(getAccessReportForStudents(dataModel)).then(async (res) => {
      if (res?.statusCode === 200) {
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });

    assessStudent
      ? navigate(
          `/dashboard/schools/${schoolId}/classrooms/${classId}/students/${studentId}/assess_report`
        )
      : navigate(
          `/dashboard/schools/${schoolId}/classrooms/${classId}/assess_report`
        );
  };

  const handleAllClick = () => {
    setAllBtnClick(true);
    setOnlyBtnClick(false);
  };

  const handleOnlyClick = () => {
    setAllBtnClick(false);
    setOnlyBtnClick(true);
  };

  var doAjaxPre = async function (
    dataModel,
    assessClass,
    schoolId,
    classId,
    type
  ) {
    type === "pre" &&
      localStorage.setItem("LearnosityPre", JSON.stringify(dataModel));
    type === "post" &&
      localStorage.setItem("LearnosityPost", JSON.stringify(dataModel));
    const regex = /\/schools\/(\d+)\/students\/(\d+)/;
    const match = window.location.href.match(regex);
    await axios
      .post(
        `${BASE_URL}/api/v1/users/learnosity/LearnosityTestProgress`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        setLearnosityPreAssementData(res);
        props.reportStudent
          ? StudentApiCall(schoolId, match[2])
          : assessClass
          ? classApiCall(schoolId, classId)
          : StudentApiCall(schoolId, classId);
      })
      .catch((err) => {});
  };

  const url = props.reportStudent
    ? `${FE_URL}/dashboard/schools/${schoolId}/students/${studentId}`
    : assessClass
    ? `${FE_URL}/dashboard/schools/${schoolId}/classrooms/${classId}/assessyourclass`
    : `${FE_URL}/dashboard/schools/${schoolId}/classrooms/${classId}/students/${studentId}/assesss_student`;

  const handlePreClick = (
    stuId,
    lesId,
    preId,
    postId,
    refId,
    name,
    preStatus
  ) => {
    props?.reportStudent && props?.onHideItem();
    setPreClickName(name);
    setPreClickBtn(true);
    setStartBtnClick(true);

    var domainName = window.location.hostname;

    var dataModel = {
      lessonId: lesId,
      postSessionId: postId,
      preSessionId: preId,
      referenceId: refId,
      status: 0,
      studentId: stuId,
      teacherId: userData?.id,
      type: "pre",
    };

    dispatch(learnosityAssessment(dataModel, url)).then(async (res) => {
      setLearnosityPreData(res);
      var preDataModel = {
        lessonId: lesId,
        postSessionId: postId,
        preSessionId: res?.request?.session_id,
        referenceId: refId,
        status: 0,
        studentId: stuId,
        teacherId: userData?.id,
        type: "pre",
      };

      var AuthToken = localStorage.getItem("AuthToken");

      var callbacks = {
        errorListener: function (e) {},

        readyListener: function () {
          itemsApp.on("test:finished:save", async function () {
            localStorage.setItem("AuthToken", AuthToken);
            doAjaxPre(preDataModel, true, schoolId, classId, "pre");
          });

          itemsApp.on("test:submit:success", async function () {
            localStorage.setItem("AuthToken", AuthToken);
            // setTimeout(() => {
            doAjaxPre(preDataModel, true, schoolId, classId, "pre");
            // }, 5000);
          });
        },
      };
      setLearnosityLaunchBtn(true);

      var itemsApp = window.LearnosityItems.init(res, callbacks);
    });
  };

  const handlePostClick = (
    stuId,
    lesId,
    preId,
    postId,
    refId,
    name,
    preStatus
  ) => {
    props?.reportStudent && props?.onHideItem();
    setPreClickName(name);
    setPostClickBtn(true);
    setStartBtnClick(true);
    var domainName = window.location.hostname;

    var dataModel = {
      lessonId: lesId,
      postSessionId: postId,
      preSessionId: preId,
      referenceId: refId,
      status: 0,
      studentId: stuId,
      teacherId: userData?.id,
      type: "post",
    };

    dispatch(learnosityAssessment(dataModel, url)).then(async (res) => {
      setLearnosityPostData(res);

      var AuthToken = localStorage.getItem("AuthToken");

      var callbacks = {
        errorListener: function (e) {},

        readyListener: function () {
          var postDataModel = {
            lessonId: lesId,
            postSessionId: res?.request?.session_id,
            preSessionId: preId,
            referenceId: refId,
            status: 0,
            studentId: stuId,
            teacherId: userData?.id,
            type: "post",
          };
          itemsApp.on("test:finished:save", async function () {
            localStorage.setItem("AuthToken", AuthToken);

            doAjaxPre(postDataModel, true, schoolId, classId, "post");
          });

          itemsApp.on("test:submit:success", async function () {
            localStorage.setItem("AuthToken", AuthToken);

            doAjaxPre(postDataModel, true, schoolId, classId, "post");
          });
        },
      };
      setLearnosityLaunchBtn(true);
      var itemsApp = window.LearnosityItems.init(res, callbacks);
    });
  };

  const stucolumns = [
    {
      title: "Subject",
      field: "subject",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData?.subject}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },
      sorting: allBtnClick
        ? studentAllClickData?.length > 0
        : studentOnlyClickData?.length > 0,
    },
    {
      title: "Unit",
      field: "unit",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData?.unit}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },
      sorting: allBtnClick
        ? studentAllClickData?.length > 0
        : studentOnlyClickData?.length > 0,
    },
    {
      title: "Progress",
      field: "progress",
      render: (rowData) => (
        <div>
          <span> {rowData?.progress}</span>
        </div>
      ),
      sorting: allBtnClick
        ? studentAllClickData?.length > 0
        : studentOnlyClickData?.length > 0,
    },
    {
      title: "Pre Test",
      field: "pre_status_value",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            {rowData?.lrn_reference_id === null ? (
              "N/A"
            ) : (
              <>
                {rowData?.pre_status === 0 &&
                  (rowData?.pre_status_value !== "N/A" ? (
                    <Button
                      disabled={userData?.userType !== "teacher"}
                      // onClick={handlePreClick}
                      onClick={() =>
                        handlePreClick(
                          rowData?.id,
                          rowData?.lesson_group_id,
                          rowData?.lrn_pre_session_id,
                          rowData?.lrn_post_session_id,
                          rowData?.lrn_reference_id,
                          rowData?.name,
                          rowData?.pre_status
                        )
                      }
                    >
                      {" "}
                      {rowData?.pre_status_value + " "}
                    </Button>
                  ) : (
                    <span> {rowData?.pre_status_value} </span>
                  ))}

                {rowData?.lrn_pre_session_id !== null && (
                  <>
                    {rowData?.pre_status === 1 && (
                      <Button
                        disabled={
                          parseInt(rowData.teacher_id) !== userData.id ||
                          userData?.userType !== "teacher"
                        }
                        onClick={() =>
                          handlePreClick(
                            rowData?.id,
                            rowData?.lesson_group_id,
                            rowData?.lrn_pre_session_id,
                            rowData?.lrn_post_session_id,
                            rowData?.lrn_reference_id,
                            rowData?.name,
                            rowData?.pre_status
                          )
                        }
                      >
                        {" "}
                        {rowData?.pre_status_value + " "}
                      </Button>
                    )}
                  </>
                )}

                {rowData?.pre_status === 2 && (
                  <span> {rowData?.pre_status_value} </span>
                )}
              </>
            )}
          </span>
        </div>
      ),
      filtering: false,
      sorting: allBtnClick
        ? studentAllClickData?.length > 0
        : studentOnlyClickData?.length > 0,
    },
    {
      title: "Post Test",
      field: "post_status_value",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>
            <>
              {rowData.lrn_reference_id === null ? (
                "N/A"
              ) : (
                <>
                  {rowData.post_status === 0 &&
                    (rowData?.post_status_value !== "N/A" ? (
                      <Button
                        disabled={userData?.userType !== "teacher"}
                        onClick={() =>
                          handlePostClick(
                            rowData?.id,
                            rowData?.lesson_group_id,
                            rowData?.lrn_pre_session_id,
                            rowData?.lrn_post_session_id,
                            rowData?.lrn_reference_id,
                            rowData?.name,
                            rowData?.pre_status
                          )
                        }
                      >
                        {rowData?.post_status_value + " "}
                      </Button>
                    ) : (
                      <span> {rowData?.post_status_value} </span>
                    ))}

                  {rowData?.lrn_post_session_id !== null && (
                    <>
                      {rowData?.post_status === 1 && (
                        <Button
                          disabled={
                            parseInt(rowData.teacher_id) !== userData.id ||
                            userData?.userType !== "teacher"
                          }
                          onClick={() =>
                            handlePostClick(
                              rowData?.id,
                              rowData?.lesson_group_id,
                              rowData?.lrn_pre_session_id,
                              rowData?.lrn_post_session_id,
                              rowData?.lrn_reference_id,
                              rowData?.name,
                              rowData?.pre_status
                            )
                          }
                        >
                          {rowData?.post_status_value + " "}
                        </Button>
                      )}
                    </>
                  )}

                  {rowData?.post_status === 2 && (
                    <span> {rowData?.post_status_value} </span>
                  )}
                </>
              )}
            </>
          </span>
        </div>
      ),
      filtering: false,
      sorting: allBtnClick
        ? studentAllClickData?.length > 0
        : studentOnlyClickData?.length > 0,
    },
    {
      title: "Report",
      render: (rowData) => (
        <div>
          <Tooltip title={"View Report"}>
            {/* <Link
                to={`/dashboard/schools/${userData?.school_id}/students/${rowData.studentId}`}
              > */}
            <Button
              className={`btn  ${
                rowData.lrn_reference_id === null ||
                (rowData.pre_status !== 2 && rowData.post_status !== 2)
                  ? "gradientButtonGray"
                  : "gradientButtonBlue"
              }`}
              disabled={
                rowData.lrn_reference_id === null ||
                (rowData.pre_status !== 2 && rowData.post_status !== 2)
              }
              onClick={() => handleReport(rowData)}
            >
              View
            </Button>
            {/* </Link> */}
          </Tooltip>
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      width: 100,
      sorting: false,
      // maxWidth: 100,
    },
  ];

  // student table code
  const tableTitle = (
    <div className="TableTitle">
      {assessClass ? "Students" : stuData[0]?.name}
    </div>
  );

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleViewClassReport = () => {
    dispatch(setAssessReport("class"));
    navigate(
      `/dashboard/schools/${schoolId}/classrooms/${classId}/class_assess_report`
    );
  };
  return (
    <>
      {/* <DashboardLayout /> */}
      <Card
        sx={{ m: 1, p: 4 }}
        className={props.reportStudent && "cardAlternate"}
      >
        {loading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}

        <div ref={tableRef}></div>
        <div className="itemSpace">
          {preClickBtn === false && postClickBtn === false && (
            <div>
              <div className="itemSpace">
                {!props.reportStudent ? (
                  <div className="pageHeading">
                    {assessClass
                      ? "Assess " + allData?.className
                      : "Assessment information of " + stuData[0]?.name}
                  </div>
                ) : (
                  <div className="chartHeading ml-2 mb0">Unit Progress</div>
                )}
              </div>
              {!props.reportStudent && (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/classes">My Classes</Link> {" > "}
                  <Link
                    to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}
                  >
                    Student List
                  </Link>{" "}
                  {" > "}
                  Assess
                </div>
              )}
            </div>
          )}
        </div>

        {preClickBtn === true &&
          (assessClass ? (
            <>
              <div className="itemSpace">
                <div className="pageHeading">Pre test of {preClickName}</div>
              </div>
              {props.reportStudent ? (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/myStudentsReports">
                    My Students
                  </Link>{" "}
                  {" > "}
                  Student Activity Report by Lesson
                </div>
              ) : (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/classes">My Classes</Link> {" > "}
                  <Link
                    to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}
                  >
                    Student List
                  </Link>
                  {" > "}Assess
                </div>
              )}
            </>
          ) : (
            <>
              <div className="itemSpace">
                <div className="pageHeading">
                  Pre test of {stuData[0]?.name}
                </div>
              </div>
              {props.reportStudent ? (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/myStudentsReports">
                    My Students
                  </Link>{" "}
                  {" > "}
                  Student Activity Report by Lesson
                </div>
              ) : (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/classes">My Classes</Link> {" > "}
                  <Link
                    to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}
                  >
                    Student List
                  </Link>
                  {" > "}Assess
                </div>
              )}
            </>
          ))}
        {postClickBtn === true &&
          (assessClass ? (
            <>
              <div className="itemSpace">
                <div className="pageHeading">Post test of {preClickName}</div>
              </div>
              {props.reportStudent ? (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/myStudentsReports">
                    My Students
                  </Link>{" "}
                  {" > "}
                  Student Activity Report by Lesson
                </div>
              ) : (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/classes">My Classes</Link> {" > "}
                  <Link
                    to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}
                  >
                    Student List
                  </Link>
                  {" > "}
                  Assess
                </div>
              )}
            </>
          ) : (
            <>
              <div className="itemSpace">
                <div className="pageHeading">
                  Post test of {stuData[0]?.name}
                </div>
              </div>
              {props.reportStudent ? (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/myStudentsReports">
                    My Students
                  </Link>{" "}
                  {" > "}
                  Student Activity Report by Lesson
                </div>
              ) : (
                <div className="pageBreadCrumb">
                  <Link to="/dashboard/home">Home</Link> {" > "}
                  <Link to="/dashboard/classes">My Classes</Link> {" > "}
                  <Link
                    to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}
                  >
                    Student List
                  </Link>
                  {" > "}Assess
                </div>
              )}
            </>
          ))}

        <div id="learnosity_assess"></div>
        {startBtnClick === false && (
          <>
            <div
              className={` ml-2 btnAlignResponsive formContents ${
                props.reportStudent ? "itemEnd p0" : "itemStart"
              }`}
            >
              <div className=" m1">
                <Button
                  className={` ${
                    allBtnClick
                      ? "btn gradientButtonPink btnWidthResponsive"
                      : "btn gradientButtonGray  btnWidthResponsive"
                  } ${props.reportStudent && "mt-0"}`}
                  onClick={handleAllClick}
                >
                  Show all assessment history
                </Button>
              </div>
              <div className=" m1">
                <Button
                  className={` ${
                    onlyBtnClick
                      ? "btn gradientButtonPink btnWidthResponsive"
                      : "btn gradientButtonGray  btnWidthResponsive"
                  } ${props.reportStudent && "mt-0"}`}
                  onClick={handleOnlyClick}
                >
                  only show the assessment for the current unit
                </Button>
              </div>
            </div>

            {assessClass === true && !props.reportStudent ? (
              <>
                <div className="MuiTable">
                  <MaterialTable
                    title={tableTitle}
                    className="myTable"
                    icons={tableIcons}
                    data={allBtnClick ? allClickData : onlyClickData}
                    columns={columns}
                    options={{
                      ...options,
                      pageSizeOptions:
                        data?.length > 0 ? [5, 10, 20, 50] : [10],
                    }}
                    onChangePage={handlePageChange}
                    // isLoading={loading}
                    localization={localization}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="MuiTable">
                  <MaterialTable
                    title={tableTitle}
                    className="myTable"
                    icons={tableIcons}
                    data={
                      allBtnClick ? studentAllClickData : studentOnlyClickData
                    }
                    columns={stucolumns}
                    options={{
                      ...options,
                      pageSizeOptions:
                        data?.length > 0 ? [5, 10, 20, 50] : [10],
                    }}
                    onChangePage={handlePageChange}
                    // isLoading={loading}
                    localization={localization}
                  />
                </div>
              </>
            )}
          </>
        )}

        <CustomToastify />
      </Card>
    </>
  );
};

export default AccessYourClass;
