import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import Icon from "@mui/material/Icon";
import "../../styles/main.css";
import "../../styles/components/home.css";
import CustomSnackBar from "../../utils/Snackbar";
import { tableIcons, options } from "../../utils/TableMui";
import "../../styles/components/myClasses.css";
import {
  FaPrint,
  FaEdit,
  FaEye,
  FaVolumeUp,
  FaVolumeOff,
} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getAllClassesData } from "../../redux/Classes/ClassesAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { updateSound } from "../../redux/Student/StudentAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const MyClasses = (props) => {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [muteButton, setMuteButton] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    if (userData?.school_id !== undefined) {
      dispatch(getAllClassesData(userData?.school_id, userData?.id)).then(
        async (res) => {
          if (res?.statusCode === 200) {
            setData(res?.classDetails);
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            dispatch(getLogout()).then(async (res) => {
              if(userData?.roster_name === "edlink") {
                dispatch(setSSOLogout(true))
              }
              navigate("/login", { replace: true });
              dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
            });
          }

          setAllData(res);
          setLoading(false);
        }
      );
    }
  }, [userData]);
  const tableTitle = (
    <div className="TableTitle ">
      No. of classes: {data?.length > 0 ? data?.length : 0}
    </div>
  );
  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.name}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },
    {
      title: "Grade",
      field: "grade",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.grade}</span>
        </div>
      ),
    },
    {
      title: "Students",
      field: "noOfStudents",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.noOfStudents}</span>
        </div>
      ),
      filtering: false,
      sorting: data?.length > 0,
    },
    {
      title: "Teachers",
      field: "noOfTeachers",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.noOfTeachers}</span>
        </div>
      ),
      filtering: false,
      sorting: data?.length > 0,
    },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          {userData?.userType === "teacher" && (
            <>
              {/* <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData.id}/mute?muted=true`}
              > */}
              <Tooltip title={"Episode sound off"}>
                <div
                  className="btn-action"
                  onClick={() => handleSound(true, rowData.id, rowData.name)}
                >
                  <FaVolumeOff className="btn-icon   btn-purple-wisteria" />
                </div>
              </Tooltip>
              {/* </Link> */}
              {/* <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData.id}/mute?muted=false`}
              > */}
              <Tooltip title={"Episode sound on"}>
                <div
                  className="btn-action"
                  onClick={() => handleSound(false, rowData.id, rowData.name)}
                >
                  <FaVolumeUp className="btn-icon btn-blue-java" />
                </div>
              </Tooltip>
              {/* </Link> */}
            </>
          )}

          <Tooltip title={"Print student list"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData.id}/printable/c`}
            >
              <div className="btn-action">
                <FaPrint className="btn-icon btn-orange-casablanca" />
              </div>
            </Link>
          </Tooltip>

          {userData?.userType === "school_admin" && (
            <Tooltip title={"Edit"}>
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData.id}/edit`}
              >
                <div className="btn-action">
                  <FaEdit className="btn-icon btn-purple-wisteria" />
                </div>
              </Link>
            </Tooltip>
          )}

          <Tooltip title={"View Class"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/classrooms/${rowData.id}`}
            >
              <div className="btn-action">
                <FaEye className="btn-icon .btn-blue-wisteria" />
              </div>
            </Link>
          </Tooltip>
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
      },
      width: 100,
      sorting: false,
    },
  ];

  const handleSound = (value, id, classN) => {
    // setIsLoading(true)
    var dataModel = {
      operationLevel: "class",
      soundMuted: value,
      operationId: id,
    };
    dispatch(updateSound(dataModel)).then(async (res) => {
      // setLoading(false);
      if (res?.statusCode === 200) {
        if (value === true) {
          dispatch(
            showToastMessage(
              res?.statusCode,
              "You have turned lesson sound OFF for all students in classroom " +
                classN
            )
          );
        } else {
          dispatch(
            showToastMessage(
              res?.statusCode,
              "You have turned lesson sound ON for all students in classroom " +
                classN
            )
          );
        }
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if(userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true))
          }
          navigate("/login", { replace: true });
          dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
        });
      } else {
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
  };

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div ref={tableRef}></div>
      <div className="itemSpace">
        <div className="pageHeading  lineAny" style={{ maxWidth: "60%" }}>{userData?.school_name}</div>
        {userData?.userType === "school_admin" && (
          <div className="itemEnd  ">
            <div className=" m1">
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/new`}
              >
                <Button className="btn gradientButtonPink ">Add class</Button>
              </Link>
            </div>
            <div className=" m1">
              <Link
                to={`/dashboard/schools/${userData?.school_id}/student_import/new`}
              >
                <Button
                  className="btn gradientButtonPink "
                  // onClick={handleAddTeacher}
                >
                  Import STUDENTS
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        My Classes
      </div>

      {/* <div className="ResponsiveBtnHeader">
      {userData?.userType === "school_admin" && (
          <div className="itemCenter ">
            <div className=" m1">
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/new`}
              >
                <Button className="btn gradientButtonPink btnResponsive">Add class</Button>
              </Link>
            </div>
            <div className=" m1">
            <Link
                to={`/dashboard/schools/${userData?.school_id}/student_import/new`}
              >
              <Button
                className="btn gradientButtonPink btnResponsive"
                // onClick={handleAddTeacher}
              >
                Import STUDENTS
              </Button>
              </Link>
            </div>
          </div>
        )}
      </div> */}

      <div className="mt-2 mt-md-0 d-md-block collapse" id="classroom-metrics">
        <dl className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div className="col mb-4">
            <div className="card">
              <dt className="card-body text-center h2 mb-0">
                {allData?.noOfClasses}
              </dt>
              <dd className="card-footer text-center mb-0">Classes</dd>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card">
              <dt className="card-body text-center h2 mb-0">
                {allData?.noOfStudents}
              </dt>
              <dd className="card-footer text-center mb-0">Students</dd>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card">
              <dt className="card-body text-center h2 mb-0">
                {allData?.noOfActiveStudents}
              </dt>
              <dd className="card-footer text-center mb-0">Active Students</dd>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card">
              <dt className="card-body text-center h2 mb-0">
                {allData?.noOfInactiveStudents}
              </dt>
              <dd className="card-footer text-center mb-0">Inactive Students</dd>
            </div>
          </div>
        </dl>
      </div>
      
      <div className="MuiTable">
        <MaterialTable
          title={tableTitle}
          className="myTable"
          icons={tableIcons}
          data={data}
          columns={columns}
          options={options}
          onChangePage={handlePageChange}
          // isLoading={loading}
        />
      </div>
      <CustomToastify />
    </Card>
  );
};

export default MyClasses;
