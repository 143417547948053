import {
  faHome,
  faBook,
  faChalkboardTeacher,
  faUsers,
  faChartBar,
  faQuestionCircle,
  faUser,
  faGamepad,
  faHiking, faBrain, faChartLine
} from "@fortawesome/free-solid-svg-icons";

const navConfig = [
  {
    title: "Home",
    path: "/dashboard/home",
    bgColor: "#69469e",
    icon: faHome,
    role: ["school_admin", "teacher"],
  },
  {
    title: "My Lessons & Resources",
    path: "/dashboard/myLessonsResources",
    bgColor: "#f8a84b",
    icon: faBook,
    role: ["school_admin", "teacher"],
  },
  {
    title: "Teachers",
    path: "/dashboard/teachers",
    bgColor: "#22b6ba",
    icon: faChalkboardTeacher,
    role: ["school_admin"],
  },
  {
    title: "My Classes",
    path: "/dashboard/classes",
    bgColor: "#338dc9",
    icon: faUsers,
    role: ["school_admin", "teacher"],
  },
  {
    title: "My Students",
    path: "/dashboard/myStudentsReports",
    bgColor: "#f04b93",
    icon: faChartBar,
    role: ["school_admin", "teacher"],
  },
  {
    title: "Help Hub",
    path: "/dashboard/helpHub",
    bgColor: "#69469e",
    icon: faQuestionCircle,
    role: ["school_admin", "teacher"],
  },
  // {
  //   title: "Free Time Activities",
  //   bgColor: "#aacb5f",
  //   icon: faGamepad,
  //   subMenu: [
  //     {
  //       title: "Brain Games",
  //       path: "/dashboard/brainGames",
  //       bgColor: "#9ec348",
  //       icon: faGamepad,
  //     },
  //     {
  //       title: "Adventures",
  //       path: "/dashboard/adventures",
  //       bgColor: "#8eb33a",
  //       icon: faHiking,
  //     },
  //   ],
  //   role: ["school_admin", "teacher"],
  // },
  {
    title: "Account",
    path: "/dashboard/schools",
    bgColor: "#f37b4f",
    icon: faUser,

    role: ["school_admin"],
  },
  {
    title: "Lessons",
    path: "/dashboard/lessons",
    bgColor: "#f8a84b",
    icon: faBook,
    role: ["student"],
  },
  // {
  //   title: "Adventures",
  //   path: "/dashboard/adventures",
  //   bgColor: "#f8a84b",
  //   icon: faHiking,
  //   role: ["student"],
  // },
  // {
  //   title: "Brain  Games",
  //   path: "/dashboard/brainGames",
  //   bgColor: "#22b6ba",
  //   icon: faBrain,
  //   role: ["student"],
  // },
  {
    title: "Progress",
    path: "/dashboard/progress",
    bgColor: "#f04b93",
    icon: faChartLine,
    role: ["student"],
  },
];

export default navConfig;
