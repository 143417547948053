import { GET_HELP_HUB_DATA, SET_CONTENT_FILTER } from "./HelpHubAction";

const initialState = {
  data: "",
  helpHubData: "",
  contentFilter: null
};

function helpHubReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HELP_HUB_DATA:
      return (state = {
        ...state,
        helpHubData: action.data,
      });
      
    case SET_CONTENT_FILTER:
      return (state = {
        ...state,
        contentFilter: action.data,
      });
    default:
      return state;
  }
}

export default helpHubReducer;
