import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import ForgotPassword from "./ForgotPassword";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getEducatorLogin,
  getEducatorData,
  getStudentLogin,
  getStudentData,
  setWelcomePage,
  setIsEducator,
  signInWithGoogle,
  getSSOLogin,
  setSSOLogout,
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getLogout } from "../../redux/Login/LoginAction";
import { GoogleLogin } from "@react-oauth/google";
//import { GoogleLogin, GoogleLogout } from 'react-google-login';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": {
          color: "red",
        },
      },
    },
  },
});

export default function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [SSOLoading, setSSOLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  // const [isEducator, setIsEducator] = React.useState(false);
  const [openForgotPassDialog, setOpenForgotPassDialog] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [gmailToken, setGmailToken] = useState("");
  const [SSoLogin, setSSoLogin] = useState(false);
  const [SSoError, setSSoError] = useState(false);
  const [codeSSO, setCodeSSO] = useState(null);
  const isEducator = useSelector((state) => state.loginReducer?.isEducator);
  const SSOLogout = useSelector((state) => state.loginReducer?.addLinkLogout);
  const userData = useSelector((state) => state.loginReducer?.userData);

  const handleChange = (value) => {
    dispatch(setIsEducator(value));
  };

  const handleStudentId = (event) => {
    var stuIdVal = event.target.value;
    const regex = /^[0-9\b]+$/;
    if (stuIdVal > 0 && regex.test(stuIdVal) && stuIdVal?.length < 5) {
      setStudentId(stuIdVal);
    } else if (stuIdVal == "" && studentId?.length == 1) {
      setStudentId("");
    } else {
      setStudentId(studentId);
    }

    /*// if (event.target.value > 0) {
      if (event.target.value > 0 && event.target.value.length < 5) {
        setStudentId(event.target.value);
      }
      else if (event.target.value > 0 && event.target.value.length === 1) {        
        setStudentId("");
      }  
    // } 
    else {
      setStudentId(studentId);
    }*/
    // else {
    //   setError(isNaN(event.target.value));
    // }

    // setStudentId(event.target.value);
    // setError(isNaN(event.target.value));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    // alert("123");
    if (isEducator) {
      // alert("123");
      if (validateEducatorForm()) return;
    } else {
      if (validateStudentForm()) return;
    }
    setIsLoading(true);
    //  setResponseCode("");
    //  setTimeout(() => {setResponseCode(""); }, 3000);
    isEducator
      ? emailError?.length === 0 &&
        passwordErrorText?.length == 0 &&
        dispatch(getEducatorLogin(email, password)).then(async (res) => {
          setIsLoading(false);
          dispatch(setSSOLogout(false));
          if (res?.statusCode === 200) {
            dispatch(showToastMessage(res?.statusCode, res?.message));
            dispatch(getEducatorData()).then((res) => {
              if (res?.statusCode === 200) {
                if (res?.visited_welcome_screen === "1") {
                  navigate("/dashboard/classes", { replace: true });
                } else {
                  navigate("/dashboard/home", { replace: true });
                  dispatch(setWelcomePage(res?.id));
                }
              }
            });
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          } else {
            setEmail("");
            setPassword("");
            setTouched(false);
            passwordErrorText = "";
            setErrorOpen(true);
            dispatch(showToastMessage(res?.statusCode, res?.message));
          }
        })
      : dispatch(getStudentLogin(schoolCode, studentId)).then(async (res) => {
          setIsLoading(false);
          dispatch(setSSOLogout(false));
          if (res?.statusCode === 200) {
            dispatch(showToastMessage(res?.statusCode, res?.message));
            // setTimeout(() => {setResponseCode(""); }, 3000);
            dispatch(getStudentData()).then((res) => {
              res?.statusCode === 200 &&
                navigate("/dashboard/lessons", { replace: true }); //Student nav menu
            });
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          } else {
            setSchoolCode("");
            setStudentId("");
            setErrorOpen(true);

            dispatch(
              showToastMessage(res?.statusCode, `Invalid Student Login.`)
            );
            // setResponseCode(res?.status);
            // setMessage(`Invalid Student Login.`);
          }
        });
    // setErrorOpen(true);
    // setMessage(`Data Updated Successfully!`);
    // dispatch(isLogin(true));
  };
  const handleContactSupportClick = () => {
    window.open(
      "https://kneoworld.zendesk.com/hc/en-us/requests/new",
      "_blank"
    );
  };
  const handleForgotPassword = () => {
    setOpenForgotPassDialog(true);
  };

  const handleCloseForgotPassword = () => {
    setOpenForgotPassDialog(false);
  };

  useEffect(() => {
    localStorage.removeItem("AuthToken");
    localStorage.removeItem("UserData");
    // localStorage.removeItem("persist:root");
    const url = window.location.href;
    // Using URLSearchParams to extract parameters
    const urlParams = new URLSearchParams(new URL(url).search);

    const code = urlParams.get("code");
    const appCode = new URL(url).pathname.split("/").pop();
    // setStateSSO(state);
    setCodeSSO(code);
    if (code && appCode) {
      setSSoLogin(true);
      setSSOLoading(true);
    }
    if (code !== null && code !== "" && appCode !== null && appCode !== "") {
      dispatch(setSSOLogout(false));
      dispatch(getSSOLogin({ code, appCode })).then(async (res) => {
        if (res?.statusCode === 200) {
          setSSoError(false);
          dispatch(showToastMessage(res?.statusCode, res?.message));
          if (res?.type === "user") {
            dispatch(getEducatorData()).then((res) => {
              setSSOLoading(false);
              if (res?.statusCode === 200) {
                if (res?.visited_welcome_screen === "1") {
                  navigate("/dashboard/classes", { replace: true });
                } else {
                  navigate("/dashboard/home", { replace: true });
                  dispatch(setWelcomePage(res?.id));
                }
                if (userData?.roster_name === "edlink") {
                  dispatch(setSSOLogout(true));
                }
              }
            });
          } else if (res?.type === "student") {
            dispatch(getStudentData()).then((res) => {
              setSSOLoading(false);
              if (res?.statusCode === 200) {
                navigate("/dashboard/lessons", { replace: true });
                if (userData?.roster_name === "edlink") {
                  dispatch(setSSOLogout(true));
                }
              }
            });
          }
        } else {
          setSSoError(true);
          setSSOLoading(false);
          // dispatch(showToastMessage(res?.statusCode, res?.message));
        }
      });
    } else {
      setSSoError(true);
    }
    dispatch(setIsEducator(null));
  }, []);

  const isPasswordValid = (password) => {
    // const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return password?.length > 7;
    //  && passwordRegex.test(password);
  };

  var passwordErrorText =
    touched && !isPasswordValid(password)
      ? "Password must be minimum 8 characters long."
      : "";
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // if (!validateEmail(e.target.value)) {
    //   setEmailError('Please enter a valid email address.');
    // } else {
    //   setEmailError('');
    // }
  };

  const validateEducatorForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [email, password];
    requireFields = ["email", "password"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field?.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      if (
        password?.length !== 0 &&
        password !== null &&
        !(password?.length > 7)
      ) {
        errors["password"] = "Password must be minimum 8 characters long.";
      }
      // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (email?.length !== 0 && email?.length > 50) {
        errors["email"] = "Email is too long (maximum is 50 characters)";
      } else if (
        email?.length !== 0 &&
        email !== null &&
        email !== "" &&
        // !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
      ) {
        errors["email"] = "Please enter a valid email address";
      }
    });

    setErrors(errors);

    return Object.keys(errors)?.length > 0 ? true : false;
  };

  const validateStudentForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [schoolCode, studentId];
    requireFields = ["schoolCode", "studentId"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field?.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
    });

    setErrors(errors);

    return Object.keys(errors)?.length > 0 ? true : false;
  };
  return (
    <>
      <div style={{ display: SSOLoading ? "-webkit-box" : "block" }}>
        {isLoading === true && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div
          className={isEducator ? "loginBg logoTeacher" : "loginBg logoStudent"}
        ></div>

        {SSOLoading ? (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        ) : (
          <div className="loginDialog">
            <img className="loginLogo" src={LogoImg} alt="KneoWorld - Logo" />
            {!SSoLogin && !SSOLogout && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="TeacherLoginButton">
                  <Button
                    className={
                      " btn TeacherLoginBtn loginBtnResponsive" +
                      (!isEducator && isEducator !== null ? " active" : "")
                    }
                    fullWidth
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => handleChange(false)}
                  >
                    Students
                  </Button>
                </div>
                <div className="TeacherLoginButton">
                  <Button
                    className={
                      " btn TeacherLoginBtn loginBtnResponsive" +
                      (isEducator ? " active" : "")
                    }
                    fullWidth
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => handleChange(true)}
                  >
                    Educators
                  </Button>
                </div>
              </div>
            )}
            {SSoLogin && SSoError && (
              <div className="error mt-3">
                Access Denied: You are not authorized to access this
                application. Please contact your system administrator for
                assistance.
              </div>
            )}
            {SSOLogout && !SSoLogin ? (
              <div className="itemCenter m2">You've successfully logout.</div>
            ) : (
              !SSoLogin && (
                <div className={isEducator !== null ? "loginDisplay" : "hide"}>
                  {isEducator && (
                    <>
                      <ThemeProvider theme={formLabelsTheme}>
                        <TextField
                          fullWidth
                          sx={{
                            m: 1,
                            "& input": {
                              fontFamily: "Raleway !important",
                            },
                          }}
                          className="mb-0"
                          required
                          id="outlined-required-1"
                          label="Email"
                          // <span className="labelReqField">*</span>
                          size="small"
                          value={email}
                          onChange={handleEmailChange}
                          error={emailError !== ""}
                          helperText={emailError}
                          // className={"mb-0"}
                        />
                      </ThemeProvider>
                      {errors.email && (
                        <div className="error mb-0">{errors.email}</div>
                      )}

                      <TextField
                        fullWidth
                        sx={{
                          m: 1,
                          "& input": {
                            fontFamily: "Raleway !important",
                          },
                        }}
                        className="mb-0"
                        required
                        id="outlined-required-2"
                        label="Password"
                        size="small"
                        value={password}
                        // className="mb-0"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        onBlur={() => setTouched(true)}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        // error={passwordErrorText !== ''}
                        // helperText={passwordErrorText}
                      />
                      {errors.password && (
                        <div className="error mb-0">{errors.password}</div>
                      )}
                    </>
                  )}

                  {!isEducator && (
                    <>
                      <TextField
                        fullWidth
                        sx={{
                          m: 1,
                          "& input": {
                            fontFamily: "Raleway !important",
                          },
                        }}
                        className="mb-0"
                        required
                        id="outlined-required-3"
                        label="School Code"
                        size="small"
                        value={schoolCode}
                        // className="mb-0"
                        onChange={(e) => {
                          setSchoolCode(e.target.value);
                        }}
                      />
                      {errors.schoolCode && (
                        <div className="error mb-0">{errors.schoolCode}</div>
                      )}

                      <TextField
                        fullWidth
                        sx={{
                          m: 1,
                          "& input": {
                            fontFamily: "Raleway !important",
                          },
                        }}
                        className="mb-0"
                        required
                        id="outlined-required-4"
                        label="Student ID"
                        size="small"
                        value={studentId}
                        error={error}
                        // className="mb-0"
                        helperText={error ? "Please enter a valid number" : ""}
                        // type="number"
                        // min="0"
                        // step="1"
                        onChange={handleStudentId}
                      />
                      {errors.studentId && (
                        <div className="error mb-0">{errors.studentId}</div>
                      )}
                    </>
                  )}
                  <div
                    className={
                      !isEducator ? "loginOptions itemEnd" : "loginOptions "
                    }
                  >
                    {isEducator && (
                      <>
                        <div className="loginLeft">
                          {/* <FormControlLabel
                    control={<Checkbox />}
                    label="Remember Me"
                  /> */}
                        </div>

                        <div className="loginRight">
                          <Link to={`/forgottenpassword`}>
                            Forgot Password?
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  {openForgotPassDialog && (
                    <ForgotPassword onClose={handleCloseForgotPassword} />
                  )}

                  <div className="LoginButton">
                    <Button
                      className="loginBtn"
                      fullWidth
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  </div>

                  <div className="GoogleButton">
                    <link
                      rel="stylesheet"
                      type="text/css"
                      href="//fonts.googleapis.com/css?family=Open+Sans"
                    />

                    <div className="googleBtn">
                      {/* <div className="googleIconWrapper">
                <img
                  className="googleIcon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                />
              </div>
              <p className="btnText">
                <b>Sign in with Google</b>
              </p> */}

                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          dispatch(
                            signInWithGoogle(credentialResponse.credential)
                          ).then(async (res) => {
                            dispatch(
                              showToastMessage(res?.statusCode, res?.message)
                            );
                            dispatch(setSSOLogout(false));
                            if (res?.statusCode === 200) {
                              res.type == "educator"
                                ? dispatch(getEducatorData()).then((res) => {
                                    if (res?.statusCode === 200) {
                                      if (res?.visited_welcome_screen === "1") {
                                        navigate("/dashboard/classes", {
                                          replace: true,
                                        });
                                      } else {
                                        navigate("/dashboard/home", {
                                          replace: true,
                                        });
                                        dispatch(setWelcomePage(res?.id));
                                      }
                                    }
                                  })
                                : dispatch(getStudentData()).then((res) => {
                                    res?.statusCode === 200 &&
                                      navigate("/dashboard/lessons", {
                                        replace: true,
                                      }); //Student nav menu
                                  });
                            }
                          });
                        }}
                        onError={() => {}}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
            <div
              className={
                (SSoLogin ? "contactDialog mt-3" : "contactDialog") +
                (isEducator !== null ? "" : " hide")
              }
            >
              <a
                href="https://kneoworld.zendesk.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={handleContactSupportClick}
              >
                <div className="contactTitle">
                  <FaRegQuestionCircle className="iconAlign" /> Contact Support
                </div>
              </a>
            </div>
            {/* <div className="itemCenter">Build Version - 5.4</div> */}
          </div>
        )}
      </div>
      <CustomToastify />
    </>
  );
}
