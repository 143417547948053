import React, { useState, useEffect, useRef } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "./../../styles/main.css";
import "../../styles/components/EditStudent.css";
import Card from "@material-ui/core/Card";
import StudentLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  getStudentDataById,
  saveUpdateStudentData,
  getLessonGroup,
} from "../../redux/Student/StudentAction";
import { getLessonAndResourcesData } from "../../redux/LessonsResources/LessonsResourcesAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const EditStudent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [googleEmail, setGoogleEmail] = useState("");
  const [studentId, setStudentId] = useState(0);
  const [lessonGroupId, setLessonGroupId] = useState("");
  const [currentLessonGroup, setCurrentLessonGroup] = useState("");
  const [errors, setErrors] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [stuId, setStuId] = useState(0);
  const [schoolId, setSchoolId] = useState(0);
  const [isEditChange, setIsEditChange] = useState(false);
  const [notInResult, setNotInResult] = useState(true);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [hoveredLesson, setHoveredLesson] = useState(null);
  const [clickStatus, setClickStatus] = useState(-1);
  const [lessonGroupList, setLessonGroupList] = useState([]);
  const [showLessonGroupList, setShowLessonGroupList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLessonData, setSelectedLessonData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const nameRef = useRef(null);
  const lessonRef = useRef(null);
  const emailRef = useRef(null);

  const userData = useSelector((state) => state.loginReducer?.userData);
  const lastVisited = useSelector((state) => state.headerReducer?.lastVisited);
  const classroomId = useSelector((state) => state.headerReducer?.classroomId);
  const secondLastVisited = useSelector(
    (state) => state.headerReducer?.secondLastVisited
  );

  useEffect(() => {
    scrollToError();
  }, [errors]);

  const scrollToError = (event) => {
    if (errors.studentName) {
      nameRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.googleEmail) {
      emailRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.selectedLesson) {
      lessonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!isEditChange) {
      dispatch(showToastMessage(201, "No fields have been changed."));
      return;
    }
    if (validateForm()) return;

    setIsLoading(true);
    // if (!validateForm()) {
    // }
    var dataModel = {
      studentName,
      selectedLesson,
      googleEmail,
    };
    dispatch(saveUpdateStudentData(dataModel, schoolId, studentId)).then(
      async (res) => {
        setIsLoading(false);
        if (res?.statusCode === 200) {
          const url =
            lastVisited === "StudentList"
              ? `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`
              : `/dashboard/myStudentsReports`;
          navigate(url);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }

        dispatch(showToastMessage(res?.statusCode, res?.message));
        setStudentName("");
        setGoogleEmail("");
        setCurrentLessonGroup("");
        setLessonGroupId("");
        setStuId("");
        // }
      }
    );

    //  window.location.href
    // Submit form logic
  };

  useEffect(() => {
    const containsEditStudent = window.location.href.includes("edit");
    containsEditStudent ? setIsEditMode(true) : setIsEditMode(false);
    const regex = containsEditStudent
      ? /\/schools\/(\d+)\/students\/(\d+)/
      : /\/schools\/(\d+)\/students\//;
    const match = window.location.href.match(regex);
    if (containsEditStudent) {
      setSchoolId(match[1]);
      setStudentId(match[2]);
    } else {
      setSchoolId(match[1]);
    }

    dispatch(getLessonAndResourcesData(userData?.school_id)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          let newArray = [];

          res.lessonAndResourceResponseList.forEach((course) => {
            const { courseName, course_id, resourcesDTOList } = course;
            let courseNameString = courseName.replace(/\s+/g, "");
            let lessonArray = [];
            resourcesDTOList.forEach((lesson) => {
              const { lessonGroup, lesson_id, description } = lesson;
              lessonArray.push({
                lesson:
                  (courseNameString !== null && courseNameString + ": ") +
                  lessonGroup,
                lessonName: lessonGroup,
                lessonId: lesson_id,
                lessonDescription: description,
              });
            });
            for (let i = 0; i < lessonArray.length; i++) {
              newArray.push({
                courseName: courseNameString,
                course_id,
                ...lessonArray[i],
              });
            }
          });

          setLessonGroupList(newArray);
          setShowLessonGroupList(newArray);
          dispatch(getStudentDataById(match[1], match[2])).then(
            async (res1) => {
              if (res1.roster_name === "edlink") {
                setIsDisabled(true);
              }
              setStudentName(res1.studentName);
              if (res1.studentEmail === null) {
                setGoogleEmail("");
              } else {
                setGoogleEmail(res1.studentEmail);
              }
              setCurrentLessonGroup(res1.lessonName);
              setStuId(res1.studentId);
              setSelectedLesson(res1.lessonId);

              const object = newArray?.find(
                (obj) => obj.lessonId === res1.lessonId
              );
              setSelectedLessonData(object);
              setIsLoading(false);
            }
          );
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
      }
    );
  }, []);

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [studentName, selectedLesson];
    requireFields = ["studentName", "selectedLesson"];
    // var i;

    requireValues.forEach((field, index) => {
      if (requireFields[index] == "studentName" && !field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (requireFields[index] == "studentName" && field.length > 50) {
        errors[requireFields[index]] =
          "Student name is too long (maximum is 50 characters)";
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
    });

    if (googleEmail?.length !== 0 && googleEmail?.length > 50) {
      errors["googleEmail"] = "Email is too long (maximum is 50 characters)";
    } else if (
      googleEmail?.length !== 0 &&
      googleEmail !== null &&
      googleEmail !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(googleEmail)
    ) {
      errors["googleEmail"] = "Please enter a valid email address";
    }

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleChange = (e) => {
    setIsEditChange(true);
    const { name, value } = e.target;
    var data =
      name === "studentName"
        ? setStudentName(value)
        : name === "googleEmail"
        ? setGoogleEmail(value)
        : "";
  };
  const handleCancel = () => {
    const url =
      secondLastVisited === "StudentReport"
        ? `/dashboard/schools/${userData?.school_id}/students/${studentId}`
        : lastVisited === "StudentList"
        ? `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`
        : `/dashboard/myStudentsReports`;
    navigate(url);
  };

  const handleLesson = (lesson, index) => {
    setIsEditChange(true);
    setSelectedLesson(lesson.lessonId);
    setSelectedLessonData(lesson);
    setClickStatus(index);
    setNotInResult(
      showLessonGroupList.some((e) => e.lessonId === lesson.lessonId)
    );
  };

  const handleClear = () => {
    setSearchQuery("");
    setNotInResult(lessonGroupList.some((e) => e.lessonId === selectedLesson));
    setShowLessonGroupList(lessonGroupList);
  };

  const handleSearch = (e) => {
    const query = e?.target?.value;
    setSearchQuery(query);

    const filteredResults = lessonGroupList.filter((item) =>
      item.lesson.toLowerCase().includes(query.toLowerCase())
    );
    setNotInResult(filteredResults.some((e) => e.lessonId === selectedLesson));
    setShowLessonGroupList(filteredResults);
  };
  return (
    <Card>
      {isLoading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="pageHeading">Edit Student</div>
      {/* <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/students">My Students & Reports</Link> {" > "}
        Edit Student
      </div> */}
      <div
        className="pageBreadCrumb"
        style={{ display: lastVisited === "StudentList" ? "block" : "none" }}
      >
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/classes">My Classes</Link> {" > "}
        <Link
          to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`}
        >
          Student List
        </Link>{" "}
        {" > "}
        <span
          style={{
            display: secondLastVisited === "StudentReport" ? "initial" : "none",
          }}
        >
          <Link
            to={`/dashboard/schools/${userData?.school_id}/students/${studentId}`}
          >
            Student Activity Report by Lesson
          </Link>{" "}
          {" > "}
        </span>
        Edit Student
      </div>
      <div
        className="pageBreadCrumb"
        style={{
          display: lastVisited === "StudentDashBoard" ? "block" : "none",
        }}
      >
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/myStudentsReports">My Students</Link> {" > "}
        <span
          style={{
            display: secondLastVisited === "StudentReport" ? "initial" : "none",
          }}
        >
          <Link
            to={`/dashboard/schools/${userData?.school_id}/students/${studentId}`}
          >
            Student Activity Report by Lesson
          </Link>{" "}
          {" > "}
        </span>
        Edit Student
      </div>

      <div className="editStudentContainer">
        <form className="form" onSubmit={handleFormSubmit}>
          <div className="formContents">
            <div className="teacherSection">
              <div>
                <img
                  src={StudentLogo}
                  alt="Student Logo"
                  className="teacherLogo"
                />
              </div>
            </div>

            <div style={{ width: "100%", marginLeft: "1rem" }}>
              <div className="detailSection f1">
                <div style={{ display: "" }}>
                  <div className="inputStudent f1" ref={nameRef}>
                    <label className="inputLabel">
                      Student Name<span className="labelReqField">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="studentName"
                      className={`input inputWidth ${
                        errors.studentName ? "errorInput" : ""
                      }`}
                      id="studentName"
                      placeholder="Student Name"
                      value={studentName}
                      onChange={handleChange}
                      disabled={isDisabled}
                    ></input>
                    {errors.studentName && (
                      <div className="error">{errors.studentName}</div>
                    )}
                  </div>
                  <div className="inputBox f1" ref={emailRef}>
                    <label className="inputLabel">Google Email</label>

                    <input
                      type="text"
                      name="googleEmail"
                      className={`input ${
                        errors.googleEmail ? "errorInput" : ""
                      }`}
                      id="googleEmail"
                      placeholder="Google Email"
                      value={googleEmail}
                      onChange={handleChange}
                      disabled={isDisabled}
                    ></input>
                    {errors.googleEmail && (
                      <div className="error">{errors.googleEmail}</div>
                    )}
                    <div className="gtxt">
                      This is not used for any communication with the user. It's
                      only purpose is to allow the student to login via Google.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="" ref={lessonRef}>
            <div className="lessonLabel">
              Lesson Group<span className="labelReqField">*</span>
            </div>
            <div className="lessonTxt">
              All students must be assigned to a lesson group based on their
              grade level.
              <br />
              You can change which lesson group is assigned to each child in{" "}
              <a href="/dashboard/myLessonsResources">
                My Lessons &amp; Resources
              </a>{" "}
              available from the menu on the left.
            </div>
            <div className="lessonGroup sideMargins">
              <div className="leftBox">
                <div className="boxBgStyle mb-1">Lesson Group</div>
                <div className="searchButton">
                  <TextField
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClear}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <ul>
                  {showLessonGroupList?.length > 0 ? (
                    showLessonGroupList?.map((lesson, index) => (
                      <li
                        key={lesson?.lessonId}
                        name="lessonGroup"
                        // className={
                        //   selectedLesson === lesson.lessonId ? "activeLesson" : ""
                        // }
                        className={`${
                          selectedLesson === lesson.lessonId
                            ? "activeLesson"
                            : ""
                        } ${
                          hoveredLesson === lesson && selectedLesson !== lesson
                            ? "hoveredLesson"
                            : ""
                        }`}
                        onClick={() => handleLesson(lesson, index)}
                        onMouseEnter={() => setHoveredLesson(lesson)}
                        onMouseLeave={() => setHoveredLesson(null)}
                      >
                        {lesson?.courseName !== null &&
                          lesson?.courseName + ": "}
                        {lesson?.lessonName}
                      </li>
                    ))
                  ) : (
                    <div className="itemCenter m2">No records to display</div>
                  )}
                </ul>
              </div>

              <div className="rightBox">
                <div className="boxBgStyle mb-1">Description</div>
                {!showLessonGroupList?.length > 0
                  ? ""
                  : hoveredLesson
                  ? hoveredLesson.lessonDescription
                  : !notInResult
                  ? "Select a lesson"
                  : selectedLessonData
                  ? selectedLessonData.lessonDescription
                  : "Select a lesson"}
              </div>
            </div>
            {errors.selectedLesson && (
              <div className="error">{errors.selectedLesson}</div>
            )}
          </div>

          <div className="itemEnd">
            <Button className="btn gradientButtonBlue mr-1" type="submit">
              Update Student
            </Button>
            <Button className="btn gradientButtonOrange" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
      <CustomToastify />
    </Card>
  );
};

export default EditStudent;
