import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { FaWindowClose } from "react-icons/fa";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CustomSnackBar from "../../utils/Snackbar";
import { useSelector, useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #c0c0c0",
  boxShadow: 24,
  p: 4,
};

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const { onClose } = props;

  const handleToCloseError = (event, reason) => {
    if ("clickaway" == reason) return;
    setErrorOpen(false);
  };
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, clickable: false }}
        className="modal"
      >
        <Fade in={open}>
          <Box sx={style} width="400px!important">
            <Button
              sx={{ position: "inherit", top: "15px", right: "15px" }}
              onClick={handleCloseModal}
            >
              <FaWindowClose color="gray" />{" "}
            </Button>
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h4"
              sx={{ padding: "5px", margin: "5px" }}
            >
              {"Forgot Password?"}
            </Typography>
            <TextField
              fullWidth
              sx={{ m: 1 }}
              required
              id="outlined-required"
              label="Email"
              size="small"
            />

            <div
              className="buttonsContainer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                className="Button btn gradientButtonBlue "
                onClick={() => {}}
                style={{ padding: "8px", marginRight: "8%"}}
              >
                Reset Password
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <CustomSnackBar
        open={errorOpen}
        onClose={() => handleToCloseError()}
        message={message}
      />
    </>
  );
};

export default ForgotPassword;
