import Router from "./routes";
import ThemeProvider from "./theme";
import "./App.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { setSSOLogout } from "./redux/Login/LoginAction";
import { useDispatch, useSelector } from "react-redux";
import { showToastMessage } from "./redux/Toaster/ToasterAction";
import { getLogout } from "./redux/Login/LoginAction";
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const userData = useSelector((state) => state.loginReducer?.userData);
  useEffect(() => {
    const loginUrl = window.location.href;    
    
    const regex = loginUrl
      ? /\/login/
      : userData?.userType !== "student" ? /\/lessons\/(\d+)\/scenes\// : "";

      
    const match = window.location.href.match(regex);
    
    if (match?.length > 0 && match[0] === "/login"){
      setIsLogin(true);
    }
    else{
      setIsLogin(false);
    }
    startSessionTimer();
    
  },[]);


  
  let logoutTimer;
  const sessionTimeout = 1 * 60 * 60 * 1000; // 1 hour in milliseconds = 1 * 60 * 60 * 1000; 10 sec for testing= 10 * 1000;

  function startSessionTimer() {
    if(isLogin){return}
    logoutTimer = setTimeout(logoutUser, sessionTimeout);
  }

  function resetSessionTimer() {
    // Clear both timers and restart them
    clearTimeout(logoutTimer);
    startSessionTimer();
  }

  function logoutUser() {
    if(userData?.roster_name === "edlink") {
      dispatch(setSSOLogout(true))
    } 
    // Perform the logout action, e.g., navigate to the login page
    dispatch(getLogout()).then(async (res) => {
      navigate("/login", { replace: true });
      if(res.statusCode === 200) {  
        dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) ); 
      }
    });
  }

  // Add event listeners to track user activity
  document.addEventListener("mousemove", resetSessionTimer);
  document.addEventListener("keydown", resetSessionTimer);

  // Start the session timer when the user logs in
  
  return (
    <div>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </div>
  );
}

export default App;
