import React, { useState } from "react";
import navConfig from "./config";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "../../styles/layouts/navbar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setNavbar } from "../../redux/Header/HeaderAction";
import Tooltip from "@material-ui/core/Tooltip";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { setContentFilter } from "../../redux/HelpHub/HelpHubAction";

const Navbar = () => {
  const dispatch = useDispatch();

  const [nav, setNav] = useState({});
  const navState = useSelector((state) => state.headerReducer?.setNavbarState);
  const userData = useSelector((state) => state.loginReducer?.userData);
  const openNavbar = () => {
    dispatch(setNavbar(!navState));
  };
  const handleChangeNavbar = (title) => {
    setNav({ [title]: !nav[title]})
  }
  return (
    <>
      <nav className="navMain" style={{ minWidth: navState == false ?"auto" : "15rem" }}>
        <ul className={`navList`}>
          <div className="invertedIcon">
            <Tooltip title={"Menu"}>
              <FontAwesomeIcon icon={faBars} onClick={openNavbar} />
            </Tooltip>
          </div>

          {navConfig.map((item, index) => {
            return (
              item.role.includes(userData?.userType) &&
              (!item.subMenu ? (
                <NavLink
                      key={index}
                      to={item.path}
                      activeclassname="active"
                    >
                <div
                  className="navRow"
                  style={{
                    backgroundColor: item.bgColor,
                  }}
                  onClick= {() => {item.title == "Help Hub" && 
                  dispatch(setContentFilter(null)) }}
                >
                  <div className="invertedIcon">
                    <Tooltip title={item.title}>
                      <FontAwesomeIcon icon={item.icon} />
                    </Tooltip>
                  </div>
                  {navState && (
                    
                      <li id={item.title} className={`navItem`}>
                        {item.title}
                      </li>
                    
                  )}
                </div>
                </NavLink>
              ) : (
                <React.Fragment key={index}>
                  <div
                    className="navRow"
                    style={{
                      backgroundColor: item.bgColor,
                    }}
                  >
                    <div className="invertedIcon">
                      <Tooltip title={item.title}>
                        <FontAwesomeIcon icon={item.icon} />
                      </Tooltip>
                    </div>
                    {navState && (
                      <li
                        style={{ backgroundColor: item.bgColor }}
                        className={
                          nav[item.title] ? "navItem active" : "navItem"
                        }
                        onClick={handleChangeNavbar(item?.title)}
                        
                      >
                        {item.title}
                        {nav[item.title] ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </li>
                    )}
                  </div>
                  {nav[item.title] &&
                    item?.subMenu?.map((subItem, subIndex) => {
                      return (
                        <NavLink
                        key={`${index}-${subIndex}`}
                        to={subItem.path}
                        activeclassname="active"
                      >
                        <div
                          className="navRow"
                          style={{
                            backgroundColor: item.bgColor,
                          }}
                        >
                          <div className="invertedIcon sub-item">
                            <Tooltip title={subItem.title}>
                              <FontAwesomeIcon icon={item.icon} />
                            </Tooltip>
                          </div>
                         
                            <li
                              id={subItem.title}
                              className={`navItem navSubItem`}
                            >
                              {subItem.title}
                            </li>
                          
                        </div>
                        </NavLink>
                      );
                    })}
                </React.Fragment>
              ))
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
