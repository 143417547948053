import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_HELP_HUB_DATA = "GET_HELP_HUB_DATA ";
export const SET_CONTENT_FILTER = "SET_CONTENT_FILTER ";


export const getHelpHubData = (schoolId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios(
            `${BASE_URL}/api/v1/users/schools/${schoolId}/getHelpHubDetails`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
                },
            }
        )
            .then((res) => {
                dispatch(getHelpHubDataApiSuccess(res.data));
                resolve(res.data);
                return res.data;
            })
            .catch((err) => {
                resolve(err.response.data);
                reject(err.response.data);
            });
    });
};


export const getHelpHubDataApiSuccess = (value) => {
    return {
        type: GET_HELP_HUB_DATA,
        data: value,
    };
};

export function setContentFilter(value) {
    return {
      type: SET_CONTENT_FILTER,
      data: value,
    };
  }