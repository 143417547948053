import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_LESSONS_RESOURCES_DATA = "GET_LESSONS_RESOURCES_DATA";
export const GET_LESSONS_DATA = "GET_LESSONS_DATA";
export const GET_LESSONS_CLASS_DATA = "GET_LESSONS_CLASS_DATA";
export const SET_COURSE_NAME = "SET_COURSE_NAME";
export const SET_LESSON_NAME = "SET_LESSON_NAME";
export const SET_LESSON_DESCRIPTION = "SET_LESSON_DESCRIPTION";
export const SET_COURSE_ID = "SET_COURSE_ID";
export const SET_LESSON_GROUP_ID = "SET_LESSON_GROUP_ID";
export const PUT_APPLY_CHANGES = "PUT_APPLY_CHANGES";
export const GET_TEACHERS_GUIDE = "GET_TEACHERS_GUIDE";
export const GET_LESSONS_RESOURCES_DATA_BY_ID = "GET_LESSONS_RESOURCES_DATA_BY_ID";
export const GET_GAMES_DATA = "GET_GAMES_DATA";
export const SET_STUDENT_SCENE = "SET_STUDENT_SCENE";
export const GET_SCENE_DETAILS = "GET_SCENE_DETAILS";
export const GET_POSITION_LIST = "GET_POSITION_LIST";
export const SET_GAME_SCORE = "SET_GAME_SCORE";
// export const SET_UPDATE_CLASS = "SET_UPDATE_CLASS";
// export const DELETE_CLASS_DATA = "DELETE_CLASS_DATA";

export const getLessonAndResourcesData = (schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/lessonAndResourceList`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getLessonAndResourcesDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getLessonsData = (schoolId, courseId, lessonId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/courses/${courseId}/lesson_groups/${lessonId}/episodesList`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getLessonsDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getLessonsClassData = (schoolId, userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/${userId}/ClassStatus`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getLessonsClassDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const putApplyChanges = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      classList: data.selectedClassData,
      lessonId: data.lessonId,
      studentList: data.selectedStudentData,
    };
    axios
      .put(
        `${BASE_URL}/api/v1/users/lesson_groups/bulk_lesson_group_assignments/new`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(putApplyChangesApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getTeachersGuide = (lessonId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/lesson_groups/${lessonId}/teachersGuide`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getTeachersGuideApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getGamesData = (gameId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/episodes/games/${gameId}/${studentId}/getGamesDetails`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getGamesDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};
export const getSceneDetails = (lessonId, sceneId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/episodes/lessons/${lessonId}/scenes/${sceneId}/${studentId}/getSceneDetails`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getSceneDetailsApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getPosition = (episodeId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/episodes/episode/${episodeId}/getPosition`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getPositionApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setStudentScene = (studentId, episodeId, sceneId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
    };
    axios
      .post(`${BASE_URL}/api/v1/students/students/${studentId}/episode/${episodeId}/scene/${sceneId}/updateStudentScene`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setStudentSceneApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setGameScore = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      studentId: data.studentId,
      score: parseInt(data.score),
      gameId: data.gameid,
      timeSpent: data.timeSpent,
      level: data.level,
      progress: data.progress,
      mistakes: data.mistakes,
    };
    axios
      .post(`${BASE_URL}/api/v1/episodes/saveStudentScoreAndProgressLines`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setGameScoreApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

// export const deleteClassData = (schoolId, classId) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/classroom/${classId}/deleteClass`, {
//       method: "DELETE",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
//       },
//     })
//       .then((res) => {
//         dispatch(deleteClassDataApiSuccess(res.data));
//         resolve(res.data);
//         return res.data;
//       })
//       .catch((err) => {
//         resolve(err.response.data);
//         reject(err.response.data);
//       });
//   });
// };



// export const getResourceByIdData = (schoolId) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     axios(
//       `${BASE_URL}/api/v1/users/schools/${schoolId}/lessonAndResourceList`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
//         },
//       }
//     )
//       .then((res) => {
//         dispatch(getResourceByIdDataApiSuccess(res.data));
//         resolve(res.data);
//         return res.data;
//       })
//       .catch((err) => {
//         resolve(err.response.data);
//         reject(err.response.data);
//       });
//   });
// };


// export const getResourceByIdDataApiSuccess = (value) => {
//   return {
//     type: GET_LESSONS_RESOURCES_DATA_BY_ID,
//     data: value,
//   };
// };

export const getLessonAndResourcesDataApiSuccess = (value) => {
  return {
    type: GET_LESSONS_RESOURCES_DATA,
    data: value,
  };
};

export const getLessonsDataApiSuccess = (value) => {
  return {
    type: GET_LESSONS_DATA,
    data: value,
  };
};

export const getLessonsClassDataApiSuccess = (value) => {
  return {
    type: GET_LESSONS_CLASS_DATA,
    data: value,
  };
};

export function setCourseName(value) {
  return {
    type: SET_COURSE_NAME,
    data: value,
  };
}

export function setLessonName(value) {
  return {
    type: SET_LESSON_NAME,
    data: value,
  };
}

export function setLessonDescription(value) {
  return {
    type: SET_LESSON_DESCRIPTION,
    data: value,
  };
}

export function setCourseId(value) {
  return {
    type: SET_COURSE_ID,
    data: value,
  };
}

export function setLessonGroupId(value) {
  return {
    type: SET_LESSON_GROUP_ID,
    data: value,
  };
}
export function putApplyChangesApiSuccess(value) {
  return {
    type: PUT_APPLY_CHANGES,
    data: value,
  };
}

export const getTeachersGuideApiSuccess = (value) => {
  return {
    type: GET_TEACHERS_GUIDE,
    data: value,
  };
};
export const getGamesDataApiSuccess = (value) => {
  return {
    type: GET_GAMES_DATA,
    data: value,
  };
};
export const getSceneDetailsApiSuccess = (value) => {
  return {
    type: GET_SCENE_DETAILS,
    data: value,
  };
};

export const getPositionApiSuccess = (value) => {
  return {
    type: GET_POSITION_LIST,
    data: value,
  };
};

export const setStudentSceneApiSuccess = (value) => {
  return {
    type: SET_STUDENT_SCENE,
    data: value,
  };
};

export const setGameScoreApiSuccess = (value) => {
  return {
    type: SET_GAME_SCORE,
    data: value,
  };
};

// export const deleteClassDataApiSuccess = (value) => {
//   return {
//     type: DELETE_CLASS_DATA,
//     data: value,
//   };
// };
