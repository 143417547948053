import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getSceneDetails,
  setStudentScene,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import { useDispatch, useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import Arrow from "../../assests/images/next-arrow.png";
import {
  faBars,
  faVolumeXmark as faVolumeOff,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import { getStudentReport } from "../../redux/Student/StudentAction";
import { updateSound } from "../../redux/Student/StudentAction";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const Slideshow = ({ onNextItem, sceneId, episode_id }) => {
  // const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [showMute, setShowMute] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [lessonId, setLessonId] = useState("");
  const [scale, setScale] = useState(1);
  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [imageURL, setImageUrl] = useState([]);
  const [imageCache, setImageCache] = useState([]);
  const [audioCache, setAudioCache] = useState([]);
  const [disableNext, setDisableNext] = useState(false);

  const userData = useSelector((state) => state.loginReducer?.userData);
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );
  const studentPlay = useSelector((state) => state.lessonReducer?.studentPlay);
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const currentData = data[currentIndex];
  const navigate = useNavigate();
  useEffect(() => {
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      pageTitle.textContent = "Kneoworld Episode Preview";
    }
    const regex = /\/lessons\/(\d+)\//;
    const match = window.location.href.match(regex);
    setLessonId(match[1]);
    dispatch(getSceneDetails(match[1], sceneId, userData?.id)).then(async (res) => {
      if (res?.statusCode === 200) {
        setData(res?.previewSceneDetailsDTO);
        // setLoading(false);
        userData?.userType === "student" && setShowMute(res?.muted);
        let newArray = [];
        let audioArray = [];
        res?.previewSceneDetailsDTO?.map((user, index) => {
          let { bgImageLocation, soundLocation } = user;

          newArray.push({
            bgImageLocation,
          });
          audioArray.push({ soundLocation });
        });

        setImageUrl(newArray);

        const loadImages = async () => {
          const loadedImages = [];

          for (const imageUrl of newArray) {
            try {
              const response = await fetch(imageUrl.bgImageLocation);
              if (response.ok) {
                const blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);
                loadedImages.push(objectURL);
              } else {
                // Handle image loading error
                console.error(
                  `Failed to load image: ${imageUrl.bgImageLocation}`
                );
              }
            } catch (error) {
              // Handle fetch or other errors
              console.error(`Error loading image: ${error.message}`);
            }
          }
          setLoading(false);
          setImageCache(loadedImages);
        };
        const loadAudios = async () => {
          const loadedAudios = [];

          for (const audioData of audioArray) {
            try {
              const response = await fetch(audioData.soundLocation);
              if (response.ok) {
                const audioBlob = await response.blob();
                const audioObjectURL = URL.createObjectURL(audioBlob);
                loadedAudios.push(new Audio(audioObjectURL));
              } else {
                // Handle audio loading error
                console.error(
                  `Failed to load audio: ${audioData.soundLocation}`
                );
              }
            } catch (error) {
              // Handle fetch or other errors
              console.error(`Error loading audio: ${error.message}`);
            }
          }

          setAudioCache(loadedAudios);
          // setLoading(false);
        };

        loadAudios();
        loadImages();
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        dispatch(getLogout()).then(async (res) => {
          if (userData?.roster_name === "edlink") {
            dispatch(setSSOLogout(true));
          }
          navigate("/login", { replace: true });
          dispatch(
            showToastMessage(403, `Session Expired or Something went wrong.`)
          );
        });
      }
    });

  }, []);

  useEffect(() => {
    updateScale(); // Call the function initially to set the initial scale.

    // Update scale whenever the window is resized.
    window.addEventListener("resize", updateScale);
    window.onresize = () => {
      updateScale();
    };

    // Clean up the event listener when the component unmounts.
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  const updateScale = () => {
    const episodeWidth = document.getElementById("episode")?.offsetWidth;
    const episodeHeight = document.getElementById("episode")?.offsetHeight;

    const episodeRatio = episodeWidth / episodeHeight;
    const windowRatio = window.innerWidth / window.innerHeight;

    const scaleFactor =
      episodeRatio < windowRatio
        ? window.innerHeight / episodeHeight
        : window.innerWidth / episodeWidth;
    setScale(scaleFactor);
  };

  useEffect(() => {
    const closeDropdown = () => {
      setShowDropdown(false);
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!showMute && !audioPlayed && currentData?.soundLocation) {
        const audioElement = audioCache[currentIndex];
        if (audioElement) {
          audioRef.current = audioElement;
          setAudioPlayed(true);
          // audioElement.muted = false;
          setShowButton(true);
          setBgImageLoaded(true);
          audioRef.current.play().catch((error) => {
            // Autoplay was blocked, handle the error (e.g., show play button)
            console.log("Autoplay blocked:", error);
            setAudioPlayed(false);
          });
        }
      } else {
        // const imageElement = new Image();
        // imageElement.src = currentData?.bgImageLocation;
        // imageElement.onload = () => {
        setShowButton(true);
        // currentIndex === 0 && setLoading(false);
        setBgImageLoaded(true);
        // };
      }
    }
  }, [currentIndex, data, audioPlayed, loading, audioCache]);
  const handleNext = () => {
    setBgImageLoaded(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (currentIndex == data.length - 2 && userData?.userType === "student") {
      setDisableNext(true);
      dispatch(setStudentScene(userData?.id, lessonId, sceneId)).then(
        async (res) => {
          setDisableNext(false);
        }
      );
    }
    if (currentIndex === data.length - 1) {
      onNextItem();
      setAudioPlayed(true);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      !showMute && setAudioPlayed(false);
    }
  };

  const handlePrevious = () => {
    setBgImageLoaded(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
    !showMute && setAudioPlayed(false);
  };

  const openOptions = () => {
    setShowDropdown(!showDropdown);
  };
  const handleReload = () => {
    !showMute && setAudioPlayed(false);
    setShowDropdown(false);
  };

  const handleMuteEpisode = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setAudioPlayed(true);
    setShowDropdown(false);
    setShowMute(!showMute);
    userData?.userType === "student" && handleSound(!showMute);
  };
  const handleReplayEpisode = () => {
    // handleMuteEpisode();
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    // setCurrentIndex(0);
    navigate(`/lessons/${episode_id}/scenes?currentIndex=0`);
    setShowDropdown(false);
    window.location.reload();
  };

  const handleMuteButton = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setAudioPlayed(true);
    setShowMute(!showMute);
    userData?.userType === "student" && handleSound(!showMute);
  };

  const handleBackToMenu = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    userData?.userType !== "student"
      ? navigate(
          `/dashboard/courses/${courseId}/lesson_groups/${lessonGroupId}`
        )
      : navigate(`/dashboard/lessons`);
  };

  const handleSound = (value) => {
    // setIsLoading(true)
    var dataModel = {
      operationLevel: "student",
      soundMuted: value,
      operationId: userData.id,
    };
    dispatch(updateSound(dataModel)).then(async (res) => {});
  };
  return (
    <>
      {loading && (
        <div className="loaderContainer" style={{ background: "black" }}>
          <div className="spinner" style={{ marginRight: "0rem" }}></div>
        </div>
      )}
      <div className="preview">
        <img
          className="orientation-warning"
          src="/assets/episodes/orientation_warning-ad228cd75d94911920abd8422f05ac235bbe3f1bd800e19b1622974df6d27439.png"
        />
        <div
          id="episode"
          className="episode episode-scale"
          style={{ transform: `scale(${scale})`, visibility: "visible" }}
        >
          {data.map((value, key) => {
            return (
              <div
                className="slide"
                data-index={key}
                id="episode-slide-23962"
                style={{
                  zIndex: key,
                  display: currentIndex === key ? "block" : "none",
                }}
              >
                <audio
                  className="ambient"
                  src={audioCache[currentIndex]}
                ></audio>
                <img className="background" src={imageCache[currentIndex]} />
                <div
                  className="component text"
                  style={{
                    left: value?.episodeComponents?.x,
                    top: value?.episodeComponents?.y,
                    zIndex: value?.episodeComponents?.z,
                    display: "block",
                  }}
                >
                  <div
                    className={`bubble tail-${value?.episodeComponents?.tail_location} text-size-${value?.episodeComponents?.text_size} text-width-${value?.episodeComponents?.text_width}`}
                  >
                    {value?.textData}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="episode-controls episode-scale"
          style={{ transform: `scale(${scale})`, visibility: "visible" }}
        >
          <div
            id="btn-start"
            style={{ display: currentIndex !== 0 ? "none" : "block" }}
            onClick={handleNext}
          >
            Start
          </div>
          <div
            className={
              currentIndex === 0
                ? "btn-episode-control episode-prev hidden"
                : "btn-episode-control episode-prev "
            }
          >
            <img src={Arrow} onClick={handlePrevious} />
          </div>
          <div
            className={
              currentIndex === 0
                ? "btn-episode-control episode-next hidden"
                : "btn-episode-control episode-next"
            }
            style={{ pointerEvents: disableNext ? "none" : "auto" }}
          >
            <img src={Arrow} onClick={handleNext} />
          </div>
          <div
            className="mute-icon"
            onClick={handleMuteButton}
            style={{ display: "block" }}
          >
            {showMute ? (
              <FontAwesomeIcon icon={faVolumeOff} />
            ) : (
              <FontAwesomeIcon icon={faVolumeUp} />
            )}
          </div>
          <div
            className={`episode-menu dropup ${showDropdown ? "show" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              aria-expanded="false"
              aria-haspopup="true"
              className=" btn-episode-menu"
              data-toggle="dropdown"
              onClick={openOptions}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div className={`dropdown-menu  ${showDropdown ? "show" : ""}`}>
              <a onClick={handleReplayEpisode}>Replay</a>
              <a onClick={handleBackToMenu}>Menu</a>
              <a className="mute-episodeon" onClick={handleMuteEpisode}>
                {!showMute ? "Mute   " : "Unmute   "}
                {!showMute ? (
                  <FontAwesomeIcon icon={faVolumeOff} />
                ) : (
                  <FontAwesomeIcon icon={faVolumeUp} />
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slideshow;
