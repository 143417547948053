import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/Resources.css";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import "../../styles/components/Access.css";
import {
  getClassWiseAssessReport,
  setLessonGroupID,
  getScoringKeyReport,
} from "../../redux/Classes/ClassesAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";

const LevelCol = ["Level", "Time Stamp"];

const PrePostReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [episodeId, setEpisodeId] = useState();
  const [scoreData, setScoreData] = useState([]);
  const [skillDataArray, setSkillData] = useState([]);
  const [avgScore, setAvgScore] = useState([]);
  const [classPreScore, setClassPreScore] = useState([]);
  const [classPostScore, setClassPostScore] = useState([]);
  const [classAvgPreScore, setAvgClassPreScore] = useState("");
  const [classAvgPostScore, setAvgClassPostScore] = useState("");
  const [studentAvgPreScore, setAvgStudentPreScore] = useState();
  const [studentAvgPostScore, setAvgStudentPostScore] = useState();
  const [totalScoreRange, setTotalScoreRange] = useState();

  const [schoolId, setSchoolId] = useState([]);
  const [classId, setClassId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [studentClassAveragesPre, setStudentClassAveragesPre] = useState([]);
  const [studentClassAveragesPost, setStudentClassAveragesPost] = useState([]);
  const [studentClassTotalPre, setStudentClassTotalPre] = useState([]);
  const [studentClassTotalPost, setStudentClassTotalPost] = useState([]);

  const [emergeStart, setEmergeStart] = useState(-1);
  const [emergeEnd, setEmergeEnd] = useState(-1);
  const [approachStart, setApproachStart] = useState(-1);
  const [approachEnd, setApproachEnd] = useState(-1);
  const [masteryStart, setMasteryStart] = useState(-1);
  const [masteryEnd, setMasteryEnd] = useState(-1);
  const [exceedsStart, setExceedsStart] = useState(-1);
  const [exceedsEnd, setExceedsEnd] = useState(-1);

  const [studentId, setStudentId] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const studentReport = useSelector(
    (state) => state.classesReducer?.studentReport
  );
  const loadingStudent = useSelector((state) => state.classesReducer?.loading);

  const assessReport = useSelector(
    (state) => state.studentReducer?.assessReport
  );

  const classReport = useSelector((state) => state.classesReducer?.classReport);

  const assessStudent = useSelector(
    (state) => state.studentReducer?.assessStudent
  );
  const reportUnitName = useSelector(
    (state) => state.classesReducer?.reportUnitName
  );
  const reportResponse = useSelector(
    (state) => state.classesReducer?.reportResponse
  );
  const scoreResponse = useSelector(
    (state) => state.classesReducer?.scoreResponse
  );
  const lessonGroupId = useSelector(
    (state) => state.classesReducer?.lessonGroupId
  );
  const classroomName = useSelector(
    (state) => state.studentReducer?.classroomName
  );
  const stuId = useSelector((state) => state.studentReducer?.stuId);
  useEffect(() => {
    const regex = assessStudent
      ? /\/schools\/(\d+)\/classrooms\/(\d+)\/students\/(\d+)\/assess_report/
      : /\/schools\/(\d+)\/classrooms\/(\d+)\/assess_report/;
    const match = window.location.href.match(regex);
    if (match?.length > 0) {
      if (assessStudent) {
        setSchoolId(match[1]);
        setClassId(match[2]);
        setStudentId(match[3]);
      } else {
        setSchoolId(match[1]);
        setClassId(match[2]);
      }
    }

    if (assessReport == "student" || assessReport == "report") {
      setLoading(false);
      setAllData(studentReport);
      // setSkillData(
      //   studentReport?.learnositySkillResponse?.length > 0 &&
      //     studentReport?.learnositySkillResponse != null
      //     ? studentReport?.learnositySkillResponse
      //     : studentReport?.existingSkillResponse
      // );
      setScoreData(studentReport?.learnosityScoresResponse?.responseList);
      let avgPreScore = 0;
      let avgPostScore = 0;

      studentReport?.learnosityScoresResponse?.responseList[0]?.preScores
        ?.length &&
        studentReport?.learnosityScoresResponse?.responseList[0]?.preScores?.map(
          (row, index) => (avgPreScore += row)
        );

      studentReport?.learnosityScoresResponse?.responseList[0]?.postScores
        ?.length > 0 &&
        studentReport?.learnosityScoresResponse?.responseList[0]?.postScores?.map(
          (row, index) => (avgPostScore += row)
        );
      setAvgStudentPreScore(
        studentReport?.learnosityScoresResponse?.responseList[0]?.preScores
          ?.length > 0
          ? avgPreScore
          : "N/A"
      );
      setAvgStudentPostScore(
        studentReport?.learnosityScoresResponse?.responseList[0]?.postScores
          ?.length > 0
          ? avgPostScore
          : "N/A"
      );
    } else {
      setLoading(false);
      setIsLoading(false);
      setAllData(reportResponse);
      setSkillData(reportResponse[6]);
      setScoreData(reportResponse[0]);
      setAvgScore(reportResponse[1]);
      setClassPreScore(reportResponse[2]);
      setClassPostScore(reportResponse[3]);
      setAvgClassPreScore(reportResponse[4]);
      setAvgClassPostScore(reportResponse[5]);
      setTotalScoreRange(reportResponse[7]);
    }
  }, [studentReport, assessReport, classReport, assessStudent]);
  useEffect(() => {
    const regex = assessStudent
      ? /\/schools\/(\d+)\/classrooms\/(\d+)\/students\/(\d+)\/assess_report/
      : /\/schools\/(\d+)\/classrooms\/(\d+)\/assess_report/;
    const match = window.location.href.match(regex);
    if (match?.length > 0) {
      if (assessStudent) {
        setSchoolId(match[1]);
        setClassId(match[2]);
        setStudentId(match[3]);
      } else {
        setSchoolId(match[1]);
        setClassId(match[2]);
      }
    }
    if (assessReport == "student" || assessReport == "report") {
      setIsLoading(true);
      dispatch(getClassWiseAssessReport(match[2], lessonGroupId)).then(
        async (res) => {
          if (res?.statusCode === 200 || res?.statusCode === 204) {
            // setAllData(res);
            // setClassAssessReport(res?.classReportObjectList);
            // setClassMapData(res?.classReportObjectList?.objectList);
            setReportData(res?.reportResponse);
            const classAverage = calculateClassAverage(res?.reportResponse);
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            dispatch(getLogout()).then(async (res) => {
              if (userData?.roster_name === "edlink") {
                dispatch(setSSOLogout(true));
              }
              navigate("/login", { replace: true });
              dispatch(
                showToastMessage(
                  403,
                  `Session Expired or Something went wrong.`
                )
              );
            });
          }
        }
      );
      dispatch(getScoringKeyReport(lessonGroupId)).then(async (res) => {
        if (res?.statusCode === 200) {
          res?.rangeList?.forEach((range, index) => {
            if (range?.level == "Emerging") {
              setEmergeStart(range?.min_vale);
              setEmergeEnd(range?.max_value);
            } else if (range?.level == "Approaching") {
              setApproachStart(range?.min_vale);
              setApproachEnd(range?.max_value);
            } else if (range?.level == "Mastery") {
              setMasteryStart(range?.min_vale);
              setMasteryEnd(range?.max_value);
            } else if (range?.level == "Exceeds Standards") {
              setExceedsStart(range?.min_vale);
              setExceedsEnd(range?.max_value);
            }
          });
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
      });
    }
  }, []);
  useEffect(() => {
    if (assessReport == "class") {
      scoreResponse?.rangeList?.forEach((range, index) => {
        if (range?.level == "Emerging") {
          setEmergeStart(range?.min_vale);
          setEmergeEnd(range?.max_value);
        } else if (range?.level == "Approaching") {
          setApproachStart(range?.min_vale);
          setApproachEnd(range?.max_value);
        } else if (range?.level == "Mastery") {
          setMasteryStart(range?.min_vale);
          setMasteryEnd(range?.max_value);
        } else if (range?.level == "Exceeds Standards") {
          setExceedsStart(range?.min_vale);
          setExceedsEnd(range?.max_value);
        }
      });
    }
  }, [scoreResponse, emergeStart]);

  function calculateClassAverage(reportResponse) {
    const preScoresArrays = reportResponse?.responseList.map(
      (student) => student.preScores
    );
    const postScoresArrays = reportResponse?.responseList.map(
      (student) => student.postScores
    );
    const classAveragesPre = [];
    const classAveragesPost = [];
    for (
      let i = 0;
      i <
      (preScoresArrays[0]?.length > 0
        ? preScoresArrays[0]?.length
        : postScoresArrays[0]?.length);
      i++
    ) {
      const preValuesAtIndex = preScoresArrays.map((preScores) => preScores[i]);
      const postValuesAtIndex = postScoresArrays.map(
        (postScores) => postScores[i]
      );

      const filteredPreValues = preValuesAtIndex.filter(
        (value) => value !== undefined
      );
      const filteredPostValues = postValuesAtIndex.filter(
        (value) => value !== undefined
      );

      const modePre = calculateMode(filteredPreValues);
      const modePost = calculateMode(filteredPostValues);

      if (filteredPreValues?.length === 0) {
        classAveragesPre.push("N/A");
      } else if (modePre === null || modePre.length > 1) {
        const sumPre = filteredPreValues.reduce((acc, value) => acc + value, 0);
        const firstDecimalPre =
          sumPre / filteredPreValues.length -
          Math.floor(sumPre / filteredPreValues.length);

        const meanPre =
          firstDecimalPre < 0.5
            ? Math.floor(sumPre / filteredPreValues.length)
            : Math.ceil(sumPre / filteredPreValues.length);
        classAveragesPre.push(meanPre);
      } else {
        classAveragesPre.push(modePre[0]);
      }

      if (filteredPostValues.length === 0) {
        classAveragesPost.push("N/A");
      } else if (modePost === null || modePost.length > 1) {
        const sumPost = filteredPostValues.reduce(
          (acc, value) => acc + value,
          0
        );
        const firstDecimalPost =
        sumPost / filteredPostValues.length -
          Math.floor(sumPost / filteredPostValues.length);
        
        const meanPost = firstDecimalPost < 0.5
        ? Math.floor(sumPost / filteredPostValues.length)
        : Math.ceil(sumPost / filteredPostValues.length);
        classAveragesPost.push(meanPost);
      } else {
        classAveragesPost.push(modePost[0]);
      }
    }

    let newArray = [];
    let secondArray = [];
    reportResponse?.responseList?.forEach((score, index) => {
      const {
        studentName,
        preScores,
        postScores,
        postScoreTime,
        preScoreTime,
      } = score;
      let avgPreScore = 0;
      let avgPostScore = 0;

      preScores?.map((row, index) => (avgPreScore += row));
      avgPreScore = preScores?.length <= 0 ? "N/A" : avgPreScore;

      postScores?.length > 0 &&
        postScores?.map((row, index) => (avgPostScore += row));
      avgPostScore = postScores?.length <= 0 ? "N/A" : avgPostScore;
      secondArray.push({
        postScoreTime:
          postScores?.length > 0
            ? postScoreTime !== null &&
              moment(postScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A")
            : "N/A",

        preScoreTime:
          preScores?.length > 0
            ? preScoreTime !== null &&
              moment(preScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A")
            : "N/A",
        avgPreScore: avgPreScore,
        avgPostScore: avgPostScore,
      });

      newArray.push({
        studentName,
        preScores,
        postScores,
        postScoreTime:
          postScoreTime !== null &&
          moment(postScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A"),
        preScoreTime:
          preScoreTime !== null &&
          moment(preScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A"),
        avgPreScore: avgPreScore,
        avgPostScore: avgPostScore,
      });
    });

    const avgPreScoresArrays = newArray.map((student) => student.avgPreScore);
    const avgPostScoresArrays = newArray.map((student) => student.avgPostScore);

    const filteredPreValues = avgPreScoresArrays.filter(
      (value) => value !== "N/A"
    );
    const filteredPostValues = avgPostScoresArrays.filter(
      (value) => value !== "N/A"
    );

    let classTotalPre = [];
    let classTotalPost = [];

    const modePre = calculateMode(filteredPreValues);
    const modePost = calculateMode(filteredPostValues);
    if (filteredPreValues?.length === 0) {
      classTotalPre.push("N/A");
    } else {
      const sumPre = filteredPreValues.reduce((acc, value) => acc + value, 0);
      const firstDecimalPre =
        sumPre / filteredPreValues.length -
        Math.floor(sumPre / filteredPreValues.length);

      const meanPre =
        firstDecimalPre < 0.5
          ? Math.floor(sumPre / filteredPreValues.length)
          : Math.ceil(sumPre / filteredPreValues.length);
      classTotalPre.push(meanPre);
    }

    if (filteredPostValues.length === 0) {
      classTotalPost.push("N/A");
    } else {
      const sumPost = filteredPostValues.reduce((acc, value) => acc + value, 0);

      const firstDecimalPost =
        sumPost / filteredPostValues?.length -
        Math.floor(sumPost / filteredPostValues?.length);

      const meanPost =
        firstDecimalPost < 0.5
          ? Math.floor(sumPost / filteredPostValues?.length)
          : Math.ceil(sumPost / filteredPostValues?.length);
      classTotalPost.push(meanPost);
    }

    let newArray2 = [];
    let counterPrime = 0;
    reportResponse?.questionsResponse[0]?.skillData?.forEach((score, index) => {
      const { questions, rationale, skill } = score;
      let quesArray = [];
      let counterArrayPre = "";
      let counterArrayPost = "";
      let pre = false;
      let post = false;
      questions?.forEach((que, index) => {
        const { ques, range, skill } = que;

        quesArray.push({
          ques,
          range,
          skill,
          classAvgPre: classAveragesPre[counterPrime],
          classAvgPost: classAveragesPost[counterPrime],
        });
        counterPrime++;
      });
      newArray2.push({
        questions: quesArray,
        rationale,
        skill,
      });
    });
    setSkillData(newArray2);
    setStudentClassAveragesPre(classAveragesPre);
    setStudentClassAveragesPost(classAveragesPost);
    setStudentClassTotalPre(classTotalPre.length > 0 ? classTotalPre[0] : 0);
    setStudentClassTotalPost(classTotalPost.length > 0 ? classTotalPost[0] : 0);
    setIsLoading(false);
  }
  function calculateMode(arr) {
    const frequencyMap = new Map();

    arr.forEach((value) => {
      if (value !== undefined) {
        frequencyMap.set(value, (frequencyMap.get(value) || 0) + 1);
      }
    });

    let maxFrequency = 0;
    let mode = [];

    frequencyMap.forEach((frequency, value) => {
      if (frequency > maxFrequency) {
        maxFrequency = frequency;
        mode = [value];
      } else if (frequency === maxFrequency) {
        mode.push(value);
      }
    });

    return mode.length === arr.length ? null : mode;
  }

  return (
    <Card>
      {isLoading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}

      <div className="itemSpace">
        <div className="pageHeading">
          {assessReport == "student" || assessReport == "report"
            ? "Unit Level Student Assessment Report"
            : "Individual Report - " + classroomName + " - " + reportUnitName}
        </div>
      </div>
      <div className="pageBreadCrumb">
        {userData.userType !== "student" && (
          <Link to="/dashboard/home">{"Home > "}</Link>
        )}
        {assessReport == "report" ? (
          userData.userType === "student" ? (
            <>
              <Link to="/dashboard/lessons">Lessons</Link> {" > "}
              <Link to="/dashboard/progress">Progress</Link> {" > "}
            </>
          ) : (
            <>
              <Link to="/dashboard/myStudentsReports">My Students</Link> {" > "}
              <Link
                to={`/dashboard/schools/${userData?.school_id}/students/${stuId}`}
              >
                Student Activity Report by Lesson
              </Link>{" "}
              {" > "}
            </>
          )
        ) : (
          <>
            <Link to="/dashboard/classes">My Classes</Link> {" > "}
            <Link to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}>
              Student List
            </Link>
            {" > "}
            {assessStudent ? (
              <Link
                to={`/dashboard/schools/${schoolId}/classrooms/${classId}/students/${studentId}/assesss_student`}
              >
                {"Assess > "}
              </Link>
            ) : (
              assessReport == "student" && (
                <Link
                  to={`/dashboard/schools/${schoolId}/classrooms/${classId}/assessyourclass`}
                >
                  {"Assess > "}
                </Link>
              )
            )}{" "}
            {assessReport == "class" && (
              <Link
                to={`/dashboard/schools/${schoolId}/classrooms/${classId}/class_assess_report`}
              >
                Class Assess Report
              </Link>
            )}{" "}
            {assessReport == "class" && " > "}
          </>
        )}
        {assessReport == "student" || assessReport == "report"
          ? "Unit Level Student Assessment Report"
          : "Individual Report "}
      </div>
      <div style={{ width: "fit-content" }}>
        <div style={{ display: "flex", margin: "0 2.5rem" }}>
          <div className="legendHeading legendWhite">
            <b>LEGEND</b>
          </div>
          <div className="legendHeading legendYellow">
            <span>
              {" "}
              If the <b>Class Average</b> score for a question{" "}
              <b>is lower than 3</b> or the{" "}
              <b>
                Class Average Post Test is lower than the Class Average Pre Test
                score,
              </b>{" "}
              then the question will be highlighted in yellow. The corresponding
              skill will be identified as a Learning Opportunity in the Overview
              tab for the whole class to continue to work on.
            </span>
          </div>
          <div className="legendHeading legendPurple">
            <span>
              {" "}
              The student's{" "}
              <b>Pre Test score Lower than the Class Average or 1</b>
              (whichever is higher)
            </span>
          </div>
          <div className="legendHeading legendGreen">
            <span>
              {" "}
              The student's{" "}
              <b>Post Test score is higher than their Pre Test score</b> for
              that question.
            </span>
          </div>
          <div className="legendHeading legendOrange">
            <span>
              {" "}
              The student's{" "}
              <b>Post Test score is lower than their Pre Test score</b> for that
              question.
            </span>
          </div>
        </div>
        <div
          style={{ display: "flex", margin: "0 2.5rem" }}
          className="itemCenter preFont legendDarkPurple"
        >
          <b>Pre & Post Results</b>
        </div>
        <TableContainer
          sx={{
            margin: "0 2.5rem 2.5rem 2.5rem",
            width: "auto",
          }}
          component={Paper}
        >
          <Table
            sx={{
              minWidth: 650,
            }}
            aria-label="simple table"
            className="accessReport"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(212 237 205)",
                  }}
                  colSpan={3}
                >
                  <div className="alignCentre skillFont">
                    <b>Skill</b>
                  </div>
                </TableCell>
                {skillDataArray?.length > 0 &&
                  skillDataArray?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableCell
                        align="center"
                        colSpan={row?.questions?.length * 2}
                        style={{
                          backgroundColor: "rgb(212 237 205)",
                        }}
                      >
                        <b>{row?.skill}</b>
                      </TableCell>
                    </React.Fragment>
                  ))}

                {LevelCol?.length > 0 &&
                  LevelCol?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "rgb(212 237 205)",
                        }}
                        rowSpan={3}
                        colSpan={2}
                      >
                        <b>{row}</b>
                      </TableCell>
                    </React.Fragment>
                  ))}
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(205 228 237)",
                  }}
                  colSpan={3}
                >
                  <div className="alignCentre skillFont">
                    <b>Question</b>
                  </div>
                </TableCell>
                {skillDataArray?.length > 0 &&
                  skillDataArray?.map((row, index) => (
                    <React.Fragment key={index}>
                      {row?.questions?.map((question, questionIndex) => (
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            backgroundColor:
                              question?.classAvgPre < 3 ||
                              question?.classAvgPost < 3 ||
                              question?.classAvgPost < question?.classAvgPre
                                ? "#ece817"
                                : "rgb(205 228 237)",
                          }}
                        >
                          <b>{question.ques}</b>
                        </TableCell>
                      ))}
                    </React.Fragment>
                  ))}
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(205 228 237)",
                    borderRightColor: "rgb(205 228 237)",
                  }}
                  colSpan={2}
                >
                  <div className="alignCentre skillFont">
                    <b>Score Range</b>
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(205 228 237)",
                  }}
                  colSpan={1}
                >
                  <div className="alignCentre">
                    {" "}
                    {assessReport == "class"
                      ? totalScoreRange
                      : allData?.scoreScale}
                  </div>
                </TableCell>
                {skillDataArray?.length > 0 &&
                  skillDataArray?.map((row, index) => (
                    <React.Fragment key={index}>
                      {row?.questions?.map((question, questionIndex) => (
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            backgroundColor: "rgb(205 228 237)",
                          }}
                        >
                          0 ~ {question.range}
                        </TableCell>
                      ))}
                    </React.Fragment>
                  ))}
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(122 68 161)",
                    borderRightColor: "rgb(122, 68, 161)",
                  }}
                  colSpan={1}
                >
                  <div className="alignCentre skillFont colorWhite">
                    <b>Assessment</b>
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(122 68 161)",
                    borderRightColor: "rgb(122, 68, 161)",
                  }}
                  colSpan={1}
                >
                  <div className="alignCentre skillFont colorWhite">
                    <b>Pre Total</b>
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(122 68 161)",
                  }}
                  colSpan={1}
                >
                  <div className="alignCentre skillFont colorWhite">
                    <b>Post Total</b>
                  </div>
                </TableCell>
                {skillDataArray?.length > 0 &&
                  skillDataArray?.map((row, index) => (
                    <React.Fragment key={index}>
                      {row?.questions?.map((question, questionIndex) => (
                        <>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "rgb(212 213 243)",
                            }}
                          >
                            <b>Pre</b>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "rgb(212 213 243)",
                            }}
                          >
                            <b>Post</b>
                          </TableCell>
                        </>
                      ))}
                    </React.Fragment>
                  ))}
                {LevelCol?.length > 0 &&
                  LevelCol?.map((row, index) => (
                    <React.Fragment key={index}>
                      <>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(212 213 243)",
                          }}
                        >
                          <b>Pre</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(212 213 243)",
                          }}
                        >
                          <b>Post</b>
                        </TableCell>
                      </>
                    </React.Fragment>
                  ))}
              </TableRow>

              {emergeStart !== -1 && (
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      borderRightColor: "white",
                    }}
                    colSpan={1}
                  >
                    <div className="alignCentre skillFont">
                      <b>Class Average</b>
                    </div>
                  </TableCell>
                  <>
                    <TableCell
                      align="center"
                      style={{
                        borderRightColor: "white",
                      }}
                      colSpan={1}
                    >
                      <div className="alignCentre">
                        {assessReport == "class"
                          ? classAvgPreScore
                          : studentClassTotalPre}
                      </div>
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      <div className="alignCentre">
                        {assessReport == "class"
                          ? classAvgPostScore
                          : studentClassTotalPost}
                      </div>
                    </TableCell>
                  </>
                  {assessReport == "class"
                    ? classPreScore?.length > 0
                      ? classPreScore?.map((preScore, index) => (
                          <React.Fragment key={index}>
                            <>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor:
                                    preScore < 3 ? "#ece817" : "white",
                                }}
                              >
                                {preScore}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor:
                                    classPostScore[index] < 3 ||
                                    classPostScore[index] < preScore
                                      ? "#ece817"
                                      : "white",
                                }}
                              >
                                {classPostScore[index]}
                              </TableCell>
                            </>
                          </React.Fragment>
                        ))
                      : classPostScore?.length > 0
                      ? classPostScore?.map((postScore, index) => (
                          <React.Fragment key={index}>
                            <>
                              <TableCell align="center">{"N/A"}</TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor:
                                    classPostScore[index] < 3
                                      ? "#ece817"
                                      : "white",
                                }}
                              >
                                {postScore}
                              </TableCell>
                            </>
                          </React.Fragment>
                        ))
                      : skillDataArray?.length > 0 &&
                        skillDataArray?.map((row, index) => (
                          <React.Fragment key={index}>
                            {row?.questions?.map((question) => (
                              <>
                                <TableCell align="center">{"N/A"}</TableCell>
                                <TableCell align="center">{"N/A"}</TableCell>
                              </>
                            ))}
                          </React.Fragment>
                        ))
                    : studentClassAveragesPre?.length > 0
                    ? studentClassAveragesPre?.map((preScore, index) => (
                        <React.Fragment key={index}>
                          <>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor:
                                  preScore < 3 ? "#ece817" : "white",
                              }}
                            >
                              {preScore}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor:
                                  studentClassAveragesPost[index] < 3 ||
                                  studentClassAveragesPost[index] < preScore
                                    ? "#ece817"
                                    : "white",
                              }}
                            >
                              {studentClassAveragesPost[index]}
                            </TableCell>
                          </>
                        </React.Fragment>
                      ))
                    : studentClassAveragesPost?.length > 0
                    ? studentClassAveragesPost?.map((postScore, index) => (
                        <React.Fragment key={index}>
                          <>
                            <TableCell align="center">{"N/A"}</TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor:
                                  studentClassAveragesPost[index] < 3
                                    ? "#ece817"
                                    : "white",
                              }}
                            >
                              {postScore}
                            </TableCell>
                          </>
                        </React.Fragment>
                      ))
                    : skillDataArray?.length > 0 &&
                      skillDataArray?.map((row, index) => (
                        <React.Fragment key={index}>
                          {row?.questions?.map((question) => (
                            <>
                              <TableCell align="center">{"N/A"}</TableCell>
                              <TableCell align="center">{"N/A"}</TableCell>
                            </>
                          ))}
                        </React.Fragment>
                      ))}
                  {LevelCol?.length > 0 &&
                    LevelCol?.map((row, index) => (
                      <React.Fragment key={index}>
                        <>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor:
                                index !== 1
                                  ? (assessReport == "class"
                                      ? classAvgPreScore
                                      : studentClassTotalPre) == "N/A"
                                    ? "white"
                                    : (assessReport == "class"
                                        ? classAvgPreScore
                                        : studentClassTotalPre) >=
                                        emergeStart &&
                                      (assessReport == "class"
                                        ? classAvgPreScore
                                        : studentClassTotalPre) <= emergeEnd
                                    ? "#E79B9B"
                                    : (assessReport == "class"
                                        ? classAvgPreScore
                                        : studentClassTotalPre) >=
                                        approachStart &&
                                      (assessReport == "class"
                                        ? classAvgPreScore
                                        : studentClassTotalPre) <= approachEnd
                                    ? "#f0e68c"
                                    : (assessReport == "class"
                                        ? classAvgPreScore
                                        : studentClassTotalPre) >=
                                        masteryStart &&
                                      (assessReport == "class"
                                        ? classAvgPreScore
                                        : studentClassTotalPre) <= masteryEnd
                                    ? "#88d8c0"
                                    : "#87ceeb"
                                  : "white",
                            }}
                          >
                            {index == 1
                              ? "-"
                              : (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) == "N/A"
                              ? "N/A"
                              : (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) >= emergeStart &&
                                (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) <= emergeEnd
                              ? "Emerging"
                              : (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) >= approachStart &&
                                (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) <= approachEnd
                              ? "Approaching"
                              : (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) >= masteryStart &&
                                (assessReport == "class"
                                  ? classAvgPreScore
                                  : studentClassTotalPre) <= masteryEnd
                              ? "Mastery"
                              : "Exceeds Standards"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor:
                                index !== 1
                                  ? (assessReport == "class"
                                      ? classAvgPostScore
                                      : studentClassTotalPost) == "N/A"
                                    ? "white"
                                    : (assessReport == "class"
                                        ? classAvgPostScore
                                        : studentClassTotalPost) >=
                                        emergeStart &&
                                      (assessReport == "class"
                                        ? classAvgPostScore
                                        : studentClassTotalPost) <= emergeEnd
                                    ? "#E79B9B"
                                    : (assessReport == "class"
                                        ? classAvgPostScore
                                        : studentClassTotalPost) >=
                                        approachStart &&
                                      (assessReport == "class"
                                        ? classAvgPostScore
                                        : studentClassTotalPost) <= approachEnd
                                    ? "#f0e68c"
                                    : (assessReport == "class"
                                        ? classAvgPostScore
                                        : studentClassTotalPost) >=
                                        masteryStart &&
                                      (assessReport == "class"
                                        ? classAvgPostScore
                                        : studentClassTotalPost) <= masteryEnd
                                    ? "#88d8c0"
                                    : "#87ceeb"
                                  : "white",
                            }}
                          >
                            {index == 1
                              ? "-"
                              : (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) == "N/A"
                              ? "N/A"
                              : (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) >= emergeStart &&
                                (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) <= emergeEnd
                              ? "Emerging"
                              : (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) >= approachStart &&
                                (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) <= approachEnd
                              ? "Approaching"
                              : (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) >= masteryStart &&
                                (assessReport == "class"
                                  ? classAvgPostScore
                                  : studentClassTotalPost) <= masteryEnd
                              ? "Mastery"
                              : "Exceeds Standards"}
                          </TableCell>
                        </>
                      </React.Fragment>
                    ))}
                </TableRow>
              )}

              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(122 68 161)",
                    borderRightColor: "rgb(122, 68, 161)",
                  }}
                  colSpan={1}
                >
                  <div
                    className="alignCentre skillFont colorWhite"
                    style={{ width: "8rem" }}
                  >
                    <b>Student Name</b>
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(122 68 161)",
                    borderRightColor: "rgb(122, 68, 161)",
                  }}
                  colSpan={1}
                >
                  <div className="alignCentre skillFont colorWhite">
                    <b>Pre Total</b>
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(122 68 161)",
                  }}
                  colSpan={1}
                >
                  <div className="alignCentre skillFont colorWhite">
                    <b>Post Total</b>
                  </div>
                </TableCell>
                {skillDataArray?.length > 0 &&
                  skillDataArray?.map((row, index) => (
                    <React.Fragment key={index}>
                      {row?.questions?.map((question, questionIndex) => (
                        <>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "rgb(212 213 243)",
                            }}
                          >
                            <b>Pre</b>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "rgb(212 213 243)",
                            }}
                          >
                            <b>Post</b>
                          </TableCell>
                        </>
                      ))}
                    </React.Fragment>
                  ))}

                {LevelCol?.length > 0 &&
                  LevelCol?.map((row, index) => (
                    <React.Fragment key={index}>
                      <>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(212 213 243)",
                          }}
                        >
                          <b>Pre</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(212 213 243)",
                          }}
                        >
                          <b>Post</b>
                        </TableCell>
                      </>
                    </React.Fragment>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colspan="16">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="spinner"></div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                scoreData?.length > 0 &&
                scoreData?.map((row, index) => (
                  <React.Fragment>
                    <TableRow>
                      <TableCell align="center">
                        <b> {row?.studentName}</b>
                      </TableCell>
                      <TableCell align="center">
                        {assessReport == "class"
                          ? avgScore[index]?.avgPreScore
                          : studentAvgPreScore}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor:
                            assessReport == "class"
                              ? avgScore?.length > 0
                                ? avgScore[index]?.avgPostScore >
                                  avgScore[index]?.avgPreScore
                                  ? "#57b257b5"
                                  : avgScore[index]?.avgPostScore <
                                    avgScore[index]?.avgPreScore
                                  ? "#f0bf87"
                                  : "white"
                                : "white"
                              : studentAvgPostScore > studentAvgPreScore
                              ? "#57b257b5"
                              : studentAvgPostScore < studentAvgPreScore
                              ? "#f0bf87"
                              : "white",
                        }}
                      >
                        {assessReport == "class"
                          ? avgScore[index]?.avgPostScore
                          : studentAvgPostScore}
                      </TableCell>

                      {row?.preScores?.length > 0
                        ? row?.preScores?.map((result, index) => (
                            <>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor:
                                    result < 1 ||
                                    (assessReport == "class"
                                      ? result < classPreScore[index]
                                      : result < studentClassAveragesPre[index])
                                      ? "#eba7e5db"
                                      : "white",
                                }}
                              >
                                {result}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor:
                                    row?.postScores?.length > 0
                                      ? row?.postScores[index] > result
                                        ? "#57b257b5"
                                        : row?.postScores[index] < result
                                        ? "#f0bf87"
                                        : // : row?.postScores[index] < 1 ||
                                          //   row?.postScores[index] <
                                          //     classPostScore[index]
                                          // ? "#eba7e5db"
                                          "white"
                                      : "white",
                                }}
                              >
                                {row?.postScores?.length > 0
                                  ? row?.postScores[index]
                                  : "N/A"}
                              </TableCell>
                            </>
                          ))
                        : row?.postScores?.length > 0
                        ? row?.postScores?.map((result, index) => (
                            <>
                              <TableCell align="center">{"N/A"}</TableCell>
                              <TableCell align="center">
                                {row?.postScores[index]}
                              </TableCell>
                            </>
                          ))
                        : skillDataArray?.length > 0 &&
                          skillDataArray?.map((row, index) => (
                            <React.Fragment key={index}>
                              {row?.questions?.map((question) => (
                                <>
                                  <TableCell align="center">{"N/A"}</TableCell>
                                  <TableCell align="center">{"N/A"}</TableCell>
                                </>
                              ))}
                            </React.Fragment>
                          ))}

                      {LevelCol?.length > 0 &&
                        LevelCol?.map((row, index1) => (
                          <React.Fragment key={index1}>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor:
                                  index1 === 0
                                    ? (assessReport == "class"
                                        ? avgScore[index]?.avgPreScore
                                        : studentAvgPreScore) == "N/A"
                                      ? "white"
                                      : (assessReport == "class"
                                          ? avgScore[index]?.avgPreScore
                                          : studentAvgPreScore) >=
                                          emergeStart &&
                                        (assessReport == "class"
                                          ? avgScore[index]?.avgPreScore
                                          : studentAvgPreScore) <= emergeEnd
                                      ? "#E79B9B"
                                      : (assessReport == "class"
                                          ? avgScore[index]?.avgPreScore
                                          : studentAvgPreScore) >=
                                          approachStart &&
                                        (assessReport == "class"
                                          ? avgScore[index]?.avgPreScore
                                          : studentAvgPreScore) <= approachEnd
                                      ? "#f0e68c"
                                      : (assessReport == "class"
                                          ? avgScore[index]?.avgPreScore
                                          : studentAvgPreScore) >=
                                          masteryStart &&
                                        (assessReport == "class"
                                          ? avgScore[index]?.avgPreScore
                                          : studentAvgPreScore) <= masteryEnd
                                      ? "#88d8c0"
                                      : "#87ceeb"
                                    : "white",
                              }}
                            >
                              {index1 === -1
                                ? avgScore[index].avgPreScore
                                : index1 === 1
                                ? assessReport == "class"
                                  ? avgScore[index]?.preScoreTime
                                  : scoreData[0]?.preScoreTime !== null
                                  ? moment(
                                      scoreData[0]?.preScoreTime.split("Z")[0]
                                    ).format("MM/DD/YYYY HH:mm A")
                                  : "N/A"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) == "N/A"
                                ? "N/A"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) >= emergeStart &&
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) <= emergeEnd
                                ? "Emerging"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) >= approachStart &&
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) <= approachEnd
                                ? "Approaching"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) >= masteryStart &&
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPreScore
                                    : studentAvgPreScore) <= masteryEnd
                                ? "Mastery"
                                : "Exceeds Standards"}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                //border: "groove",
                                backgroundColor:
                                  index1 === 0
                                    ? (assessReport == "class"
                                        ? avgScore[index]?.avgPostScore
                                        : studentAvgPostScore) == "N/A" ||
                                      (assessReport == "class"
                                        ? avgScore[index]?.avgPostScore
                                        : studentAvgPostScore) == "N/A"
                                      ? "white"
                                      : (assessReport == "class"
                                          ? avgScore[index]?.avgPostScore
                                          : studentAvgPostScore) >=
                                          emergeStart &&
                                        (assessReport == "class"
                                          ? avgScore[index]?.avgPostScore
                                          : studentAvgPostScore) <= emergeEnd
                                      ? "#E79B9B"
                                      : (assessReport == "class"
                                          ? avgScore[index]?.avgPostScore
                                          : studentAvgPostScore) >=
                                          approachStart &&
                                        (assessReport == "class"
                                          ? avgScore[index]?.avgPostScore
                                          : studentAvgPostScore) <= approachEnd
                                      ? "#f0e68c"
                                      : (assessReport == "class"
                                          ? avgScore[index]?.avgPostScore
                                          : studentAvgPostScore) >=
                                          masteryStart &&
                                        (assessReport == "class"
                                          ? avgScore[index]?.avgPostScore
                                          : studentAvgPostScore) <= masteryEnd
                                      ? "#88d8c0"
                                      : "#87ceeb"
                                    : "white",
                              }}
                            >
                              {index1 === -1
                                ? assessReport == "class"
                                  ? avgScore[index]?.avgPostScore
                                  : studentAvgPostScore
                                : index1 === 1
                                ? assessReport == "class"
                                  ? avgScore[index]?.postScoreTime
                                  : scoreData[0]?.postScoreTime !== null
                                  ? moment(
                                      scoreData[0]?.postScoreTime.split("Z")[0]
                                    ).format("MM/DD/YYYY HH:mm A")
                                  : "N/A"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) == "N/A" ||
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) == "N/A"
                                ? "N/A"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) >= emergeStart &&
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) <= emergeEnd
                                ? "Emerging"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) >= approachStart &&
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) <= approachEnd
                                ? "Approaching"
                                : (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) >= masteryStart &&
                                  (assessReport == "class"
                                    ? avgScore[index]?.avgPostScore
                                    : studentAvgPostScore) <= masteryEnd
                                ? "Mastery"
                                : "Exceeds Standards"}
                            </TableCell>
                          </React.Fragment>
                        ))}
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CustomToastify />
    </Card>
  );
};

export default PrePostReport;
