import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "../../styles/layouts/header.css";
import Logo from "../../assests/images/Logo_Kneoworld.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserPopup from "./UserPopup";
import { getUserDataApiSuccess } from "../../redux/Login/LoginAction";
import CustomDialog from "../../utils/CustomDialog";
import PopUpCustomDialog from "./PopUpCustomDialog";


const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [firstname, setFirstname]= useState("");
  

  const userData = useSelector((state) => state.loginReducer?.userData);
  const handleUserIconClick = () => {
    setPopupOpen(!isPopupOpen);
  };
 
  

  useEffect(() => {   
      const str = userData?.username;      
      const name = str.split(' ');
      setFirstname(name[0]); 
  }, []);

  const handleDialogClose = () => {
    setPopupOpen(false);
  };
  return (
    <header>
      <div className="headerLogo">
        <Link to="https://www.kneoworld.com/">
          <div>
            <img src={Logo} className="logoImage" alt="QDAP" />
          </div>
        </Link>
      </div>
      <div className="WelcomeMsg">
         Welcome  {firstname} 

      </div>
      <div className="user-icon" onClick={handleUserIconClick}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      
      {/* {isPopupOpen && <PopUpCustomDialog />} */}
      {/* {isPopupOpen && <UserPopup />} */}
      <PopUpCustomDialog
        open={isPopupOpen}
        onClose={handleDialogClose}
       
        />
    </header>
  );
};

export default Header;
