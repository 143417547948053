import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import ForgotPassword from "./ForgotPassword";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  sendEmailForgotPassword,
  setIsEducator,
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getLogout } from "../../redux/Login/LoginAction";
export default function ForgottenPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  // const [isEducator, setIsEducator] = React.useState(false);
  const [openForgotPassDialog, setOpenForgotPassDialog] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(false);
  const [touched, setTouched] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [responseCode, setResponseCode] = React.useState("");





  
  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [email];
    requireFields = ["email"];
    // var i;

    requireValues.forEach((field, index) => {

      if(!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";          
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


      if(email?.length !== 0 && email?.length >50) {
        errors["email"] = "Email is too long (maximum is 50 characters)";
      } else if (
        email?.length !== 0 &&
        email !== null &&
        email !== "" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        // !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
      ) {
        errors["email"] = "Please enter a valid email address";
      } 
  
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };


  const handleForgotPassword = (event) => {
    event.preventDefault();
    
    // if (email !== "" && !(validateEmail(email)) ) {
    //   dispatch(showToastMessage("", "Please enter a valid E-mail address"));
    //   setEmail("");
    // } 
    // else if (email === "" ){
    //   dispatch(showToastMessage("", "Please enter a valid E-mail address"));
    //   setEmail("");
    // }
    if(validateForm())
    return;

      // else {
      setIsLoading(true);
      setResponseCode("");
      // setTimeout(() => { setResponseCode(""); }, 3000);
      dispatch(sendEmailForgotPassword(email)).then(async (res) => {
        setIsLoading(false);

        if (res?.statusCode === 200) {
          navigate("/login");
        }else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            navigate("/login", { replace: true });
            dispatch(showToastMessage(403, `Session Expired or Something went wrong.`) );  
          });
        } else {
          setEmail("");
          setPassword("");
          setTouched(false);

          setErrorOpen(true);
          //  dispatch(showToastMessage(res?.statusCode, `Invalid Email or Password.`))
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));
        setEmail("");
      });
    // }
    // setErrorOpen(true);
    // setMessage(`Data Updated Successfully!`);
    // dispatch(isLogin(true));
  };

  useEffect(() => {
    dispatch(setIsEducator(true));
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
     setEmail(e.target.value);
    // if (!validateEmail(e.target.value)) {
    //   setEmailError("Please enter a valid email address.");
    // } else {
    //   setEmailError("");
    // }
  };

  return (
    <>
      <div>
        {isLoading === true && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div className="loginBg logoStudent"></div>

        <div className="loginDialog">
          <img className="loginLogo" src={LogoImg} alt="KneoWorld - Logo" />

          <div className="loginTitle">Forgot Password</div>

          <>
            <TextField
              fullWidth
              sx={{
                m: 1,
                "& input": {
                  fontFamily: 'Raleway !important'
                },
              }}
              required
              id="outlined-required"
              label="Email"
              size="small"
              value={email}
              onChange={handleEmailChange}
              className="mb-0"
              // error={emailError !== ""}
              // helperText={emailError}
            />
          </>
          {errors.email && <div className="error mb-0">{errors.email}</div>}

          <div className="itemSpace">
            <div className="mr-1">
              <Button
                className="btn gradientButtonBlue  mr-1 lh1"
                fullWidth
                sx={{ m: 1 }}
                variant="contained"
                onClick={handleForgotPassword}
              >
                RESET Password
              </Button>
            </div>
            <div>
              <Link to={`/login`}>
                <Button
                  className="btn gradientButtonPink lh1"
                  fullWidth
                  sx={{ m: 1 }}
                  variant="contained"
                >
                  {/* onClick={handleForgotPassword} */}
                  Back To Login
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CustomToastify />
    </>
  );
}
