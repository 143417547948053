import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getStudentLessonsData } from "../../redux/Lessons/LessonsAction";
import "../../styles/components/Adventures.css";
import "./../../styles/main.css";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
import { FaPlay, FaHeadSideVirus, FaRedo, FaLock } from "react-icons/fa";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { setStudentPlay } from "../../redux/Lessons/LessonsAction";

const StudentLesson = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [episodeData, setEpisodeData] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [licenseNumber, setLicenseNumber] = useState("");
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState({});
  const [lockLesson, setLockLesson] = React.useState(0);
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      pageTitle.textContent = "KneoWorld";
    }
    dispatch(getStudentLessonsData(userData?.school_id, userData?.id)).then(
      async (res) => {
        if (res?.episodesResponse?.statusCode === 200) {
          setEpisodeData(res?.episodesResponse?.episodesDataList);
          setProgressData(res?.sceneResponseList);
          setBarData(res?.progressResponseList);
          setAllData(res);
          setLoading(false);
          setLockLesson(res?.flag);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if(userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true))
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
      }
    );
  }, []);

  const handlePreview = (episodeId, resume) => {
    dispatch(setStudentPlay(true));
    const rowData = progressData.find((p) => p.episodeId === episodeId);
    if (resume && rowData?.noOfScenes - rowData?.noOfCompletedScenes !== 0) {
      navigate(
        `/lessons/${episodeId}/scenes?currentIndex=${rowData?.noOfCompletedScenes}`
      );
    } else {
      navigate(`/lessons/${episodeId}/scenes`);
    }
  };

  return (
    <Card>
      {loading ? (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      ) :
      <>
      <div className="itemSpace">
        <div className="pageHeading">Lessons</div>
      </div>
      <div className="pageBreadCrumb">Lessons</div>

      {lockLesson === 0 && (
        <div className="ml-3">
          Your pre test of this unit is not completed. Upon completion of the
          pre test by your teacher, the lessons will be available to you.
        </div>
      )}

      {episodeData?.length > 0 ? episodeData?.map((item, index) => {
        return (
          <>
            <div className="adventuresMain ml-3  mt-2">
              <div className="adventuresLeft">
                <img
                  src={item.url}
                  alt="Adventure Logo"
                  className="adventureLogo episodeCardImage"
                />
              </div>

              <div className="adventuresRight">
                <div className="adventuresHeading">
                  <FaHeadSideVirus className="brainIcon" /> {item.episodeName}
                </div>
                {lockLesson === 0 ? (
                  <div className="playBtn">
                    <div>
                      <FaLock className="btn-icon-play  yellowStartButton" />{" "}
                    </div>
                    <div className="barTopMargin"></div>
                  </div>
                ) : (
                  <div className="playBtn">
                    {(progressData.find((p) => p.episodeId === item.episodeId)
                      ?.noOfCompletedScenes /
                      progressData.find((p) => p.episodeId === item.episodeId)
                        ?.noOfScenes) *
                      100 ===
                      0 && (
                      <div
                        onClick={() => handlePreview(item?.episodeId, false)}
                      >
                        <FaPlay className="btn-icon-play  greenStartButton" />
                        <div className="btnLabel">Start</div>
                      </div>
                    )}
                    {(progressData.find((p) => p.episodeId === item.episodeId)
                      ?.noOfCompletedScenes /
                      progressData.find((p) => p.episodeId === item.episodeId)
                        ?.noOfScenes) *
                      100 >
                      0 &&
                      (progressData.find((p) => p.episodeId === item.episodeId)
                        ?.noOfCompletedScenes /
                        progressData.find((p) => p.episodeId === item.episodeId)
                          ?.noOfScenes) *
                        100 <
                        100 && (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ display: "block" }}
                            className="mr-1"
                            onClick={() => handlePreview(item?.episodeId, true)}
                          >
                            <FaPlay className="btn-icon-play  yellowStartButton " />
                            <div className="btnLabel">Start</div>
                          </div>
                          <div
                            style={{ display: "block" }}
                            onClick={() =>
                              handlePreview(item?.episodeId, false)
                            }
                          >
                            <FaRedo className="btn-icon-play restartButton" />
                            <div className="btnLabel">Restart</div>
                          </div>
                        </div>
                      )}
                    {(progressData.find((p) => p.episodeId === item.episodeId)
                      ?.noOfCompletedScenes /
                      progressData.find((p) => p.episodeId === item.episodeId)
                        ?.noOfScenes) *
                      100 ===
                      100 && (
                      <div
                        onClick={() => handlePreview(item?.episodeId, false)}
                      >
                        <FaRedo className="btn-icon-play  restartButton" />
                        <div className="btnLabel">Restart</div>
                      </div>
                    )}
                  </div>
                )}

                {barData?.map((row, index) => {
                  return (
                    item?.episodeId === row?.episodeId && (
                      <>
                        <div className="progressListDiv">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress
                                className="progressLine"
                                variant="determinate"
                                value={row.completePercent} // Calculate the progress percentage
                              />
                            </Box>
                            {/* <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2" color="text.secondary">
                            {`${Math.round((row.noOfCompletedScenes / row.noOfScenes) * 100)}%`}
                          </Typography>
                        </Box> */}
                          </Box>
                        </div>
                      </>
                    )
                  );
                })}
              </div>
            </div>
          </>
        );
      }):
      <div className="m2 noLessons">No lessons are assigned to this student.</div>}
      </>}
      <CustomToastify />
    </Card>
  );
};

export default StudentLesson;
