import React, { useState, useEffect, useRef } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "./../../styles/main.css";
import "../../styles/components/enrollStudent.css";
import Card from "@material-ui/core/Card";
import StudentLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import {
  getStudentNotInClassList,
  getLessonGroup,
  setSaveStudent,
  setSaveStudentInSchool,
} from "../../redux/Student/StudentAction";
import { getLessonAndResourcesData } from "../../redux/LessonsResources/LessonsResourcesAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getClassesData } from "../../redux/Classes/ClassesAction";
import { getLogout, setSSOLogout } from "../../redux/Login/LoginAction";
const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#ededed",
    },
  })
);
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const EnrollStudent = (props) => {
  const [age, setAge] = React.useState("");

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [email, setEmail] = useState("");
  const [currentLessonGroup, setCurrentLessonGroup] = useState("");
  const [errors, setErrors] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [studentId, setStudentId] = React.useState("");
  const [lessonGroupList, setLessonGroupList] = useState([]);
  const [notInResult, setNotInResult] = useState(true);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedLessonData, setSelectedLessonData] = useState(null);
  const [hoveredLesson, setHoveredLesson] = useState(null);
  const [classroomId, setClassroomId] = useState(0);
  const [studentNotInClass, setStudentListNotInClass] = useState([]);
  const [studentOfClass, setStudentOfClass] = useState(false);
  const [classList, setClassList] = useState([]);
  const [showLessonGroupList, setShowLessonGroupList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = React.useState(classList);
  const [right, setRight] = React.useState([]);
  const [clickStatus, setClickStatus] = useState(-1);
  const nameRef = useRef(null);
  const lessonRef = useRef(null);
  const emailRef = useRef(null);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const userData = useSelector((state) => state.loginReducer?.userData);
  const classRoomName = useSelector(
    (state) => state.studentReducer?.classroomName
  );
  const lastVisited = useSelector((state) => state.headerReducer?.lastVisited);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const CardSelector = (title, items, side) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card
        style={{ display: "flex", flexDirection: "column", height: "25rem" }}
        component={Paper}
      >
        <CardHeader
          className={"classes.cardHeader stickyHeader"}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
          action={
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        />
        <Collapse in={expanded} className={classes.collapse}>
          <List className={classes.list} dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Collapse>
      </Card>
    );
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    var data =
      name === "studentList"
        ? setStudentId(value)
        : name === "studentName"
        ? setStudentName(value)
        : name === "email"
        ? setEmail(value)
        : "";
  };
  const handleLesson = (lesson, index) => {
    setSelectedLesson(lesson.lessonId);
    setSelectedLessonData(lesson);
    setClickStatus(index);
    setNotInResult(
      showLessonGroupList.some((e) => e.lessonId === lesson.lessonId)
    );
  };

  useEffect(() => {
    const url = window.location.pathname;
    setStudentOfClass(url.endsWith("/new"));
    let match = "";
    if (url.endsWith("/new")) {
      const regex = /\/classrooms\/(\d+)\//;
      match = window.location.href.match(regex);
      setClassroomId(match[1]);
    }

    if (userData?.school_id !== undefined) {
      if (url.endsWith("/new")) {
        dispatch(getStudentNotInClassList(userData?.school_id, match[1])).then(
          async (res) => {
            if (res?.statusCode === 200) {
              setStudentListNotInClass(res?.studentsPrintableDetails);
              setIsLoading(false);
            } else if (res?.statusCode === 403 || res?.statusCode === 401) {
              dispatch(getLogout()).then(async (res) => {
                if (userData?.roster_name === "edlink") {
                  dispatch(setSSOLogout(true));
                }
                navigate("/login", { replace: true });
                dispatch(
                  showToastMessage(
                    403,
                    `Session Expired or Something went wrong.`
                  )
                );
              });
            }
          }
        );
      }

      dispatch(getLessonAndResourcesData(userData?.school_id)).then(
        async (res) => {
          if (res?.statusCode === 200) {
            let newArray = [];

            res.lessonAndResourceResponseList.forEach((course) => {
              const { courseName, course_id, resourcesDTOList } = course;
              let courseNameString = courseName.replace(/\s+/g, "");
              let lessonArray = [];
              resourcesDTOList.forEach((lesson) => {
                const { lessonGroup, lesson_id, description } = lesson;
                lessonArray.push({
                  lesson:
                    (courseNameString !== null && courseNameString + ": ") +
                    lessonGroup,
                  lessonName: lessonGroup,
                  lessonId: lesson_id,
                  lessonDescription: description,
                });
              });
              for (let i = 0; i < lessonArray.length; i++) {
                newArray.push({
                  courseName: courseNameString,
                  course_id,
                  ...lessonArray[i],
                });
              }
            });
            setLessonGroupList(newArray);
            setShowLessonGroupList(newArray);
            setIsLoading(false);
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            dispatch(getLogout()).then(async (res) => {
              if (userData?.roster_name === "edlink") {
                dispatch(setSSOLogout(true));
              }
              navigate("/login", { replace: true });
              dispatch(
                showToastMessage(
                  403,
                  `Session Expired or Something went wrong.`
                )
              );
            });
          }
        }
      );
      userData?.userType === "school_admin" &&
        dispatch(getClassesData(userData?.school_id)).then(async (res) => {
          var classOptions = [];
          res?.classRoomList?.map((value) => {
            classOptions.push(value.name);
          });
          setClassList(classOptions);
          setLeft(classOptions);
        });
    }
  }, [userData]);

  useEffect(() => {
    scrollToError();
  }, [errors]);

  const scrollToError = (event) => {
    if (errors.studentName) {
      nameRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.email) {
      emailRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.selectedLesson) {
      lessonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSaveStudent = (event) => {
    event.preventDefault();

    let errors = {};
    if (!(studentId !== null && studentId !== "" && studentId.length !== 0)) {
      errors["studentId"] = "This field is required";
      setErrors(errors);
      return Object.keys(errors).length > 0 ? true : false;
    }
    setIsLoading(true);

    var dataModel = {
      id: studentId,
      studentName: null,
      email: null,
      lessonId: null,
    };
    saveStudent(dataModel);
    setStudentId("");
  };
  const handleSaveNewStudent = (event) => {
    event.preventDefault();

    if (validateForm()) return;

    setIsLoading(true);
    var dataModel = studentOfClass
      ? {
          id: 0,
          studentName,
          email: email === "" ? null : email,
          lessonId: selectedLesson,
        }
      : {
          id: 0,
          studentName,
          email: email === "" ? null : email,
          lessonId: selectedLesson,
          right,
        };

    studentOfClass ? saveStudent(dataModel) : saveStudentInSchool(dataModel);
    setStudentName("");
    setEmail("");
    setSelectedLesson(null);
    setSelectedLessonData(null);
    setClickStatus(-1);
    setRight([]);
    setLeft(classList);
  };

  const saveStudent = (dataModel) => {
    dispatch(setSaveStudent(dataModel, userData?.school_id, classroomId)).then(
      async (res) => {
        dispatch(showToastMessage(res?.statusCode, res?.message));
        if (res?.statusCode === 200) {
          navigate(
            `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`
          );
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
        setIsLoading(false);
      }
    );
  };
  const saveStudentInSchool = (dataModel) => {
    dispatch(setSaveStudentInSchool(dataModel, userData?.school_id)).then(
      async (res) => {
        dispatch(showToastMessage(res?.statusCode, res?.message));
        if (res?.statusCode === 200) {
          navigate(`/dashboard/myStudentsReports`);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          dispatch(getLogout()).then(async (res) => {
            if (userData?.roster_name === "edlink") {
              dispatch(setSSOLogout(true));
            }
            navigate("/login", { replace: true });
            dispatch(
              showToastMessage(403, `Session Expired or Something went wrong.`)
            );
          });
        }
        setIsLoading(false);
      }
    );
  };

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [studentName, selectedLesson];
    requireFields = ["studentName", "selectedLesson"];
    // var i;

    requireValues.forEach((field, index) => {
      if (requireFields[index] == "studentName" && !field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }

      if (requireFields[index] == "studentName" && field.length > 50) {
        errors[requireFields[index]] =
          "Student name is too long (maximum is 50 characters)";
      }

      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
    });

    if (email?.length !== 0 && email?.length > 50) {
      errors["email"] = "Email is too long (maximum is 50 characters)";
    } else if (
      email?.length !== 0 &&
      email !== null &&
      email !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      errors["email"] = "Please enter a valid email address";
    }

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleCancel = () => {
    const url =
      lastVisited === "StudentList"
        ? `/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`
        : `/dashboard/myStudentsReports`;
    navigate(url);
  };
  const handleClear = () => {
    setSearchQuery("");
    setNotInResult(lessonGroupList.some((e) => e.lessonId === selectedLesson));
    setShowLessonGroupList(lessonGroupList);
  };

  const handleSearch = (e) => {
    const query = e?.target?.value;
    setSearchQuery(query);

    const filteredResults = lessonGroupList.filter((item) =>
      item.lesson.toLowerCase().includes(query.toLowerCase())
    );
    setNotInResult(filteredResults.some((e) => e.lessonId === selectedLesson));
    setShowLessonGroupList(filteredResults);
  };

  return (
    <>
      <Card>
        {isLoading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}
        <div className="pageHeading">
          {studentOfClass
            ? `Enroll Student in ${classRoomName}`
            : "New Student"}
        </div>
        <div
          className="pageBreadCrumb"
          style={{ display: lastVisited === "StudentList" ? "block" : "none" }}
        >
          <Link to="/dashboard/home">Home</Link> {" > "}
          <Link to="/dashboard/classes">My Classes</Link> {" > "}
          <Link
            to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`}
          >
            Student List
          </Link>{" "}
          {" > "}
          Enroll Student
        </div>
        <div
          className="pageBreadCrumb"
          style={{
            display: lastVisited === "StudentDashBoard" ? "block" : "none",
          }}
        >
          <Link to="/dashboard/home">Home</Link> {" > "}
          <Link to="/dashboard/myStudentsReports">My Students</Link> {" > "}
          Enroll Student
        </div>

        <div className="enrollStudentContainer">
          <div
            className="existingStudent"
            style={{ display: !studentOfClass ? "none" : "block" }}
          >
            <div className="m1">
              <div className="headerText">Existing Student</div>
              <p className="subHeaderText">
                If you wish to change this students assigned lessons please go
                to Lessons & Plans and locate the lessons you want to allocate
                to this student. When you have located the lessons, you can
                assign these lessons to the whole class or specify students
                within the class.
              </p>
            </div>

            <div className="studentMain">
              <div className="studentMainLeft">
                {/* <FormControl variant="filled" sx={{ m: 1, minWidth: 600 }}> */}
                <div className="select-container">
                  <InputLabel className="drpLabel">Student</InputLabel>

                  <select
                    className="input mb0 select-container"
                    name="studentList"
                    id="studentId"
                    value={studentId}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Student
                    </option>

                    {studentNotInClass?.map((student) => (
                      <option
                        className="drpOptions"
                        key={student.studentId}
                        value={student.studentId}
                      >
                        {student.studentName}
                      </option>
                    ))}
                  </select>
                  {errors.studentId && (
                    <div className="error mb0">{errors.studentId}</div>
                  )}
                </div>
              </div>
              <div className="studentMainRight">
                <Button
                  className="btn gradientButtonBlue"
                  type="submit"
                  onClick={handleSaveStudent}
                >
                  Add Student
                </Button>
              </div>
            </div>
          </div>

          <div className="newStudent">
            <form className="form m1">
              <div
                className="newStudHeading"
                style={{ display: !studentOfClass ? "none" : "flex" }}
              >
                New Student
              </div>

              <div
                className="subText"
                style={{ display: !studentOfClass ? "none" : "flex" }}
              >
                To assign lessons to this new student please choose from the
                list below and click on the 'Add new student' button.
              </div>
              <div className="formContents stdEnrollForm">
                <div className="teacherSection mt-1">
                  <div>
                    <img
                      src={StudentLogo}
                      alt="Student Logo"
                      className="teacherLogo"
                    />
                  </div>
                </div>

                <div style={{ width: "100%", marginLeft: "1rem" }}>
                  <div className="detailSection f1">
                    <div style={{ display: "" }}>
                      <div className="inputStudent f1 " ref={nameRef}>
                        <label className="inputLabel">
                          Student Name<span className="labelReqField">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          name="studentName"
                          className={`input inputWidth ${
                            errors.studentName ? "errorInput" : "mb1"
                          }`}
                          id="studentName"
                          placeholder="Student Name"
                          value={studentName}
                          onChange={handleChange}
                        ></input>
                        {errors.studentName && (
                          <div className="error">{errors.studentName}</div>
                        )}
                      </div>
                      <div className="inputBox f1" ref={emailRef}>
                        <label className="inputLabel">Google Email</label>

                        <input
                          type="email"
                          name="email"
                          className={`input ${
                            errors.email ? "errorInput" : ""
                          }`}
                          id="email"
                          placeholder="user@mail.com"
                          value={email}
                          onChange={handleChange}
                        ></input>
                        {errors.email && (
                          <div className="error">{errors.email}</div>
                        )}
                        <div className="gmailTxt">
                          This is not used for any communication with the user.
                          It's only purpose is to allow the student to login via
                          Google.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={lessonRef}>
                <div className="lessonLabel">
                  Lesson Group<span className="labelReqField">*</span>
                </div>
                <div className="lessonTxt">
                  All students must be assigned to a lesson group based on their
                  grade level.
                  <br />
                  You can change which lesson group is assigned to each child in{" "}
                  <a href="/dashboard/myLessonsResources">
                    My Lessons &amp; Resources
                  </a>{" "}
                  available from the menu on the left.
                </div>
                <div className="lessonGroup sideMargins">
                  <div className="leftBox">
                    <div className="boxBgStyle mb-1">Lesson Group</div>
                    <div className="searchButton">
                      <TextField
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: searchQuery && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClear}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <ul>
                      {showLessonGroupList?.length > 0 ? (
                        showLessonGroupList?.map((lesson, index) => (
                          <li
                            key={lesson?.lessonId}
                            name="lessonGroup"
                            className={`${
                              selectedLesson === lesson.lessonId
                                ? "activeLesson"
                                : ""
                            } ${
                              hoveredLesson === lesson &&
                              selectedLesson !== lesson.lessonId
                                ? "hoveredLesson"
                                : ""
                            }`}
                            onClick={() => handleLesson(lesson, index)}
                            onMouseEnter={() => setHoveredLesson(lesson)}
                            onMouseLeave={() => setHoveredLesson(null)}
                          >
                            {lesson?.courseName !== null &&
                              lesson?.courseName + ": "}
                            {lesson?.lessonName}
                          </li>
                        ))
                      ) : (
                        <div className="itemCenter m2">
                          No records to display
                        </div>
                      )}
                    </ul>
                  </div>

                  <div className="rightBox">
                    <div className="boxBgStyle mb-1">Description</div>
                    <div>
                      {!showLessonGroupList?.length > 0
                        ? ""
                        : hoveredLesson !== null
                        ? hoveredLesson.lessonDescription
                        : !notInResult
                        ? "Select a lesson"
                        : selectedLessonData !== null
                        ? selectedLessonData.lessonDescription
                        : "Select a lesson"}
                    </div>
                  </div>
                </div>
                {errors.selectedLesson && (
                  <div className="error">{errors.selectedLesson}</div>
                )}
              </div>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                style={{ display: studentOfClass ? "none" : "flex" }}
              >
                <Grid item className="leftGridCard">
                  <Paper elevation={3} className="classesList">
                    {CardSelector("Classes Available", left, "left")}
                  </Paper>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    className="arrowButton"
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item className="rightGridCard">
                  <Paper elevation={3} className="classesList">
                    {CardSelector("Selected Classes", right, "right")}
                  </Paper>
                </Grid>
              </Grid>

              <div className="itemEnd">
                <Button
                  className="btn gradientButtonBlue mr-1"
                  type="submit"
                  onClick={handleSaveNewStudent}
                >
                  {!studentOfClass ? "Add Student" : "Add new Student"}
                </Button>
                {/* <Link to={`/dashboard/myStudentsReports`}> */}
                <Button
                  className="btn gradientButtonOrange"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {/* </Link> */}
              </div>
            </form>
          </div>
        </div>
        <CustomToastify />
      </Card>
    </>
  );
};

export default EnrollStudent;
